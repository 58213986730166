const data =
  process.env.REACT_APP_ENV != "production"
    ? {
        chains: [
          "juicy-low-small-testnet", // Europa
          "giant-half-dual-testnet", // Calypso
          "lanky-ill-funny-testnet", // Nebula
          "aware-fake-trim-testnet", // Titan
        ],
        tokens: {
          eth: {
            symbol: "ETH",
          },
          usdc: {
            decimals: "6",
            symbol: "USDC",
            name: "USD Coin",
          },
        },
        connections: {
          "giant-half-dual-testnet": {
            // Calypso connections
            erc20: {
              usdc: {
                address: "0x2aebcdc4f9f9149a50422fff86198cb0939ea165",
                chains: {
                  "juicy-low-small-testnet": {
                    clone: true,
                  },
                  "aware-fake-trim-testnet": {
                    clone: true,
                  },
                  mainnet: {
                    hub: "juicy-low-small-testnet",
                    clone: true,
                  },
                },
              },
            },
          },
          "lanky-ill-funny-testnet": {
            // nebula connections
            erc20: {
              usdc: {
                address: "0x5eaf4e5a908ba87abf3de768cb0da517db45db48",
                chains: {
                  "juicy-low-small-testnet": {
                    clone: true,
                  },
                  "aware-fake-trim-testnet": {
                    clone: true,
                  },
                  mainnet: {
                    hub: "juicy-low-small-testnet",
                    clone: true,
                  },
                },
              },
            },
          },
          "aware-fake-trim-testnet": {
            // titan connections
            erc20: {
              usdc: {
                address: "0x10a30e73ab2da5328fc09b06443dde3e656e82f4",
                chains: {
                  "juicy-low-small-testnet": {
                    clone: true,
                  },
                  "lanky-ill-funny-testnet": {
                    clone: true,
                  },
                  mainnet: {
                    hub: "juicy-low-small-testnet",
                    clone: true,
                  },
                },
              },
            },
          },
          "juicy-low-small-testnet": {
            // Europa connections
            erc20: {
              usdc: {
                address: "0x6CE77Fc7970F6984eF3E8748A3826972Ec409Fb9",
                chains: {
                  mainnet: {
                    clone: true,
                  },
                  "giant-half-dual-testnet": {
                    wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
                  },
                  "lanky-ill-funny-testnet": {
                    wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
                  },
                  "aware-fake-trim-testnet": {
                    wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
                  },
                },
              },
            },
          },
        },
      }
    : {
        chains: [
          "mainnet",
          "elated-tan-skat", // europa hub
          "honorable-steel-rasalhague", // calypso hub
          "green-giddy-denebola", // nebula hub
        ],
        tokens: {
          eth: {
            symbol: "ETH",
          },
          usdc: {
            decimals: "6",
            symbol: "USDC",
            name: "USD Coin",
          },
        },
        connections: {
          mainnet: {
            eth: {
              eth: {
                chains: {
                  "elated-tan-skat": {},
                  "adorable-quaint-bellatrix": {},
                  "honorable-steel-rasalhague": {
                    hub: "elated-tan-skat",
                  },
                  "green-giddy-denebola": {
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
            erc20: {
              usdc: {
                address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                chains: {
                  "elated-tan-skat": {},
                  "honorable-steel-rasalhague": {
                    hub: "elated-tan-skat",
                  },
                  "green-giddy-denebola": {
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
          },
          "elated-tan-skat": {
            // europa connections
            eth: {
              eth: {
                address: "0xD2Aaa00700000000000000000000000000000000",
                chains: {
                  mainnet: {
                    clone: true,
                  },
                  "honorable-steel-rasalhague": {
                    wrapper: "0xa5274efA35EbeFF47C1510529D9a8812F95F5735",
                  },
                  "green-giddy-denebola": {
                    wrapper: "0xa5274efA35EbeFF47C1510529D9a8812F95F5735",
                  },
                },
              },
            },
            erc20: {
              usdc: {
                address: "0x5F795bb52dAC3085f578f4877D450e2929D2F13d",
                chains: {
                  mainnet: {
                    clone: true,
                  },
                  "honorable-steel-rasalhague": {
                    wrapper: "0x1c566a47e1baC535Ca616373146e3BE024F88Aa4",
                  },
                  "green-giddy-denebola": {
                    wrapper: "0x1c566a47e1baC535Ca616373146e3BE024F88Aa4",
                  },
                },
              },
            },
          },
          "honorable-steel-rasalhague": {
            // calypso connections
            eth: {
              eth: {
                address: "0x59ab97Ee239e02112652587F9Ef86CB6F762983b",
                chains: {
                  "elated-tan-skat": {
                    clone: true,
                  },
                  mainnet: {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                  "green-giddy-denebola": {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
            erc20: {
              usdc: {
                address: "0x7Cf76E740Cb23b99337b21F392F22c47Ad910c67",
                chains: {
                  "elated-tan-skat": {
                    clone: true,
                  },
                  mainnet: {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                  "green-giddy-denebola": {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
          },
          "green-giddy-denebola": {
            // nebula connections
            eth: {
              eth: {
                address: "0xaB01BAd2C86e24D371A13eD6367bdCa819589C5D",
                chains: {
                  "elated-tan-skat": {
                    clone: true,
                  },
                  mainnet: {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                  "honorable-steel-rasalhague": {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
            erc20: {
              usdc: {
                address: "0xCC205196288B7A26f6D43bBD68AaA98dde97276d",
                chains: {
                  "elated-tan-skat": {
                    clone: true,
                  },
                  mainnet: {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                  "honorable-steel-rasalhague": {
                    clone: true,
                    hub: "elated-tan-skat",
                  },
                },
              },
            },
          },
        },
      };

const config = {
  ...data,
  openOnLoad: true,
  openButton: true,
  skaleNetwork: process.env.REACT_APP_ENV != "production" ? "testnet" : "mainnet",
  theme: {
    mode: "dark",
    vibrant: true,
  },
  // mainnetEndpoint: "??",
  projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
};

export default config;
