import Countdown from "react-countdown";
import styled from "styled-components";

const CountdownCube = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.12) 100%);
  backdrop-filter: blur(20px);
  width: 44px;
  min-height: 44px;
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    line-height: 20px;
    text-transform: uppercase;
    &:nth-child(2) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  /* @media ((max-width: 1366px) and (min-width: 768px)) or (max-width: 500px) {
    width: 44px;
    min-height: 44px;
    border-radius: var(--border-radius-md);

    span {
      font-size: 12px;
      line-height: 18px;
      &:nth-child(2) {
        font-size: 8px;
        line-height: 12px;
      }
    }
  } */
`;
const ContentBottom = styled.div`
  display: flex;
  gap: 6px;
`;

const CountdownFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LaunchpadInfoCountdownCubes = ({ startDate, endDate, isSoldOut }) => {
  const startDateRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Countdown date={new Date(endDate).valueOf()} renderer={endDateRenderer} />;
    } else {
      // Render a countdown
      return (
        <CountdownFlexWrapper>
          <span className="mint-option-live">STARTS IN</span>
          <ContentBottom>
            <CountdownCube>
              <span>{days}</span>
              <span>Days</span>
            </CountdownCube>
            <CountdownCube>
              <span>{hours}</span>
              <span>Hrs</span>
            </CountdownCube>
            <CountdownCube>
              <span>{minutes}</span>
              <span>Mins</span>
            </CountdownCube>
            <CountdownCube>
              <span>{seconds}</span>
              <span>Secs</span>
            </CountdownCube>
          </ContentBottom>
        </CountdownFlexWrapper>
      );
    }
  };

  const endDateRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (isSoldOut) {
      return <span className="mint-option-inactive">SOLD OUT</span>;
    } else {
      if (completed) {
        // Render a completed state
        return <span className="mint-option-inactive">ENDED</span>;
      } else {
        // Render a countdown
        return (
          <CountdownFlexWrapper>
            <span className="mint-option-inactive">ENDS IN</span>
            <ContentBottom>
              <CountdownCube>
                <span>{days}</span>
                <span>Days</span>
              </CountdownCube>
              <CountdownCube>
                <span>{hours}</span>
                <span>Hrs</span>
              </CountdownCube>
              <CountdownCube>
                <span>{minutes}</span>
                <span>Mins</span>
              </CountdownCube>
              <CountdownCube>
                <span>{seconds}</span>
                <span>Secs</span>
              </CountdownCube>
            </ContentBottom>
          </CountdownFlexWrapper>
        );
      }
    }
  };

  return <Countdown date={new Date(startDate).valueOf()} renderer={startDateRenderer} />;
};

export default LaunchpadInfoCountdownCubes;
