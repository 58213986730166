import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { useStore } from "./redux";

import "./App.css";

function ReduxWrapper() {
  const store = useStore();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

export default ReduxWrapper;
