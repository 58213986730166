import { Link } from "react-router-dom";
import { Explorer, IS_TESTNET } from "utils/environments";
import { ellipsifyString } from "utils/formats";

const CreatedBy = ({ creator, nolink, children }) => {
  if (nolink) return children;

  return creator?.display_name ? (
    <Link to={`/creators/${creator?._id}`}>{children ?? creator?.display_name}</Link>
  ) : (
    <a
      href={`${Explorer}/address/${creator?.account_address}`}
      target="_blank"
      rel="noreferrer"
    >
      {children ?? ellipsifyString(creator?.account_address, 8)}
    </a>
  );
};

export default CreatedBy;
