// The goal of this hook is to handle fetching the data needed for App.jsx
// Data will be fetched on first build, but may also be fetched in other situations
// such as when the user logs out and the user store changes

import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { loadedFullScreen, loadingFullScreen } from "redux/state/loading";
import { fetchSettings } from "redux/state/settings";
import { getBearerToken } from "utils/api";

const getEssentialAppData = async (dispatch) => {
  // check if there is a token available
  // if there is not a token then our work here is done
  // if there is a token then attempt to get current user data
  await dispatch(fetchSettings());
  let bearerToken = getBearerToken();
  if (!bearerToken) return;
  dispatch(loadingFullScreen());
  dispatch(loadedFullScreen());
};

const useAppDataFetcher = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // this is only run on first load and if the user object changes

    getEssentialAppData(dispatch);
  }, [dispatch]);
};

export default useAppDataFetcher;
