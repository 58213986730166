import bg4 from "assets/images/backgroup-secsion/img_bg_page_title_dark.jpg";
import bg3 from "assets/images/backgroup-secsion/img_bg_page_title_inner.jpg";
import bg1 from "assets/images/item-background/bg-authors.jpg";
import bg2 from "assets/images/item-background/bg-authors2.jpg";
import styled from "styled-components";

import LazyLoadImage from "components/utils/LazyLoadImage";

const BannerWrapper = styled.div`
  padding-top: 84px;
  position: relative;
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const BannerShadow = styled.div`
  background-image: linear-gradient(to bottom, #181a20aa, transparent);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ProfileImageWrapper = styled.div`
  height: 200px;
  width: 200px;
  @media only screen and (max-width: 991px) {
    height: 140px;
    width: 140px;
  }
`;

const ProfileBanner = ({ user }) => {
  const banners = [bg1, bg2, bg3, bg4];
  const { _id, avatar_url, banner } = user;
  const fallback = `https://gravatar.com/avatar/${_id}?f=y&d=identicon&size=200`;

  return (
    <BannerWrapper>
      <BannerShadow />
      <BannerImage src={banners[banner ?? 0] ?? banner} />
    </BannerWrapper>
  );
};

export default ProfileBanner;
