import React from "react";
import { Link } from "react-router-dom";

const AddNewCollectionCard = () => {
  return (
    <Link to="/create-collection">
      <div className="sc-card-collection collection-card">
        <div className="card-bottom" style={{ height: "320px" }}>
          <div className="author game-details fullWidth justify-content-center align-items-center">
            <h3 className="mb-3 capitalize">Add New Collection</h3>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AddNewCollectionCard;
