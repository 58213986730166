import React from "react";
import * as ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { A11y, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "swiper/scss";

const BannerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #181a2088 30%, transparent 60%);
  z-index: 8;
`;

const PaginationTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  height: fit-content;
  background-color: var(--primary-color);
  color: white;
  border-radius: 0;
  border: 2px solid var(--primary-color7);
  margin: 0 !important;
  padding: 1rem !important;
  gap: 1rem;

  &.swiper-pagination-bullet-active {
    border: 2px solid var(--primary-color3);
  }
`;

const PaginationTabText = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const PaginationTabSubText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const PaginationTabImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: var(--border-radius-md);
`;

const SliderVideo = styled.video`
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
`;

const SliderImage = styled.img`
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
`;

const SliderContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  overflow: hidden;
`;

const GamesSlider = ({ games }) => {
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return ReactDOMServer.renderToStaticMarkup(
        <PaginationTab className={className}>
          <PaginationTabImage src={games[index]?.logo_image} />
          <FlexColumn>
            <PaginationTabText>{games[index]?.name || "~"}</PaginationTabText>
            <PaginationTabSubText>
              {games[index]?.description || "~"}
            </PaginationTabSubText>
          </FlexColumn>
        </PaginationTab>
      );
    },
    el: ".swiper-pagination",
  };
  return (
    <section className="featured-games">
      <div className="mainslider flat-title-page style3">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <Swiper
            modules={[Autoplay, Pagination, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={pagination}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={400}
          >
            {games.map((item) => (
              <SwiperSlide key={item._id} className={item.class}>
                <SliderItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

const SliderItem = ({ item }) => {
  const { _id, name, description, banner_image } = item;
  return (
    <SliderContentWrapper>
      {banner_image && banner_image?.split(".").pop() === "mp4" ? (
        <SliderVideo autoPlay muted loop>
          <source src={banner_image} type="video/mp4" />
        </SliderVideo>
      ) : (
        <SliderImage src={banner_image} />
      )}

      <BannerOverlay />
      <div className="wrap-heading flat-slider flex">
        <h2 className="heading">{name}</h2>
        <p className="sub-heading">{description}</p>
        <div className="flat-bt-slider flex style2">
          <Link
            to={`/games/${_id}`}
            className="sc-button primary header-slider style style-1 fl-button "
          >
            <span>Explore</span>
          </Link>
        </div>
      </div>
    </SliderContentWrapper>
  );
};
export default GamesSlider;
