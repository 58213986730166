import React from "react";
import styled from "styled-components";

import MyProfileProjects from "components/layouts/profile/MyProfileProjects";
import { useProfileMenu } from "components/utils/context/ProfileMenuContext";

const DisplayName = styled.h2`
  font-size: 38px;
  margin-right: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ProfileProjects = () => {
  const { setShowMenu } = useProfileMenu();
  const menuToggle = () => {
    setShowMenu(true);
  };

  return (
    <ContentWrapper className={"col-box-83"}>
      <FlexWrapper>
        <DisplayName>Projects</DisplayName>{" "}
        <div className="mobile-button" onClick={menuToggle}>
          <span />
        </div>
      </FlexWrapper>
      <MyProfileProjects />
    </ContentWrapper>
  );
};

export default ProfileProjects;
