import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";
import { findIndexByValue } from "utils/formats";

import GameActivityPanel from "components/layouts/games/Panels/GameActivityPanel";
import GameCollectionsPanel from "components/layouts/games/Panels/GameCollectionsPanel";
import GameLeaderboardPanel from "components/layouts/games/Panels/GameLeaderboardPanel";
import GameOverviewPanel from "components/layouts/games/Panels/GameOverviewPanel";
import { useTitle } from "components/utils/TitleProvider";

const GameTabs = ({ game }) => {
  const dataTabs = [
    {
      title: "Overview",
      link: "overview",
      panel: <GameOverviewPanel title="Overview" game={game} />,
    },
    {
      title: "Collections",
      link: "collections",
      panel: <GameCollectionsPanel title="Collections" game={game} />,
    },
    {
      title: "Leaderboard",
      link: "leaderboard",
      panel: <GameLeaderboardPanel title="Leaderboard" game={game} />,
    },
    {
      title: "Activity",
      link: "activity",
      panel: <GameActivityPanel title="Activity" game={game} />,
      disabled: true,
    },
  ];

  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link, id } = useParams();
  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
    }
  }, [link]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/games/${id}/${link}`);
  };

  return (
    <div className="flat-tabs items">
      <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
        <TabList>
          {dataTabs.map((tab, index) => (
            <Tab key={index} disabled={tab.disabled}>
              {tab?.disabled ? (
                <>
                  <ReactTooltip
                    place="bottom"
                    id="comingSoonLink"
                    type="info"
                    className="tooltip-nowrap"
                  >
                    <span>Coming Soon!</span>
                  </ReactTooltip>
                  <span data-tip data-for="comingSoonLink" style={{ color: "#343444" }}>
                    {tab.title}
                  </span>
                </>
              ) : (
                <span>{tab.title}</span>
              )}
            </Tab>
          ))}
        </TabList>
        {dataTabs.map(({ panel }, index) => (
          <TabPanel key={index}>{panel}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default GameTabs;
