import { useSelector } from "react-redux";

const Fees = ({ price, buying = false, collection }) => {
  let { symbol } = useSelector((state) => state.settings.payment);
  let { fee_bps } = useSelector((state) => state.settings.market);
  let marketPercentage = fee_bps / 10000;
  let marketCut = price * marketPercentage;

  let royaltyPercentage = (collection.royaltyFee || 0) / 100;
  let royaltyCut = (price * royaltyPercentage) / 100;

  const total = price - (marketCut + royaltyCut);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p>Marketplace Fee: {marketPercentage * 100}%</p>
        <p className="text-right price color-popup">
          ~{Math.round(marketCut * 10000) / 10000} {symbol}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p>Royalty Fee: {royaltyPercentage}%</p>
        <p className="text-right price color-popup">
          ~{Math.round(royaltyCut * 10000) / 10000} {symbol}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <p>{buying ? "Seller recieves" : "You recieve:"}</p>
        <p className="text-right price color-popup">{total}</p>
      </div>
    </div>
  );
};

export default Fees;
