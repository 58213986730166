import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserCoins } from "redux/state/web3";
import { reduxForm } from "redux-form";
import { getCollection, updateListing } from "utils/api";
import { ipfsConvert } from "utils/formats";
import { sleep } from "utils/time";
import { approveFor, buyFromMarket, fetchTokenSale, getAllowance } from "utils/web3/evm";

import LoadingButton from "components/button/LoadingButton";
import RequireAmountButton from "components/button/RequireAmountButton";
import Royalties from "components/layouts/collections/Royalties";
import ToastPopup from "components/utils/ToastPopup";

const formName = "buyModal";

const BuyModal = ({ item, onHide, onBuy, setModalShow, beforeSetListing }) => {
  let dispatch = useDispatch();
  let user = useSelector((state) => state.user);
  let settings = useSelector((state) => state.settings);
  let payment = useSelector((state) => state.settings.payment);
  const [total, setTotal] = useState(0);
  const [buyLoading, setBuyLoading] = useState(false);
  const [collection, setNftCollection] = useState({});
  const allowances = useSelector((state) => state.web3.allowances);

  useEffect(() => {
    let totalPrice = item.sale_price;
    if (item.seller_kiosk) {
      totalPrice += Math.ceil(Math.ceil(collection.royaltyFee * item.sale_price) / 10000);
    } else {
      collection.royalties?.forEach((royalty) => {
        totalPrice += Math.ceil(Math.ceil(royalty.amount * item.sale_price) / 10000);
      });
    }
    setTotal(Math.ceil(totalPrice * 10000) / 10000);
  }, [collection]);

  const onLoad = async () => {
    if (item && !buyLoading) {
      let collection = item.collection;
      if (typeof item.collection !== "object") {
        setBuyLoading(true);
        const collectionData = await getCollection(item.collection);
        collection = collectionData.data.collection;
        setBuyLoading(false);
      }
      setNftCollection(collection);
    }
  };

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  if (!item) return null;

  const salePrice = item.sale_price;
  const buyText = "Buy";
  const disabled = buyLoading || !item.active;

  const handleOnClick = async () => {
    // TODO: call fetchTokenSale now that we added marketIndex to events

    // try {
    //   setBuyLoading(true);
    //   const itemExistsRes = item.seller_kiosk
    //     ? true
    //     : await getObjectInfo(item.listing_object_id);
    // check if listing exists on blockchain, then if it doesn't, request listing update.
    // if (itemExistsRes) {
    try {
      setBuyLoading(true);
      let allowance = BigInt(allowances[item.market.marketAddress] || 0);
      // Double checking, should have checked this earlier.
      if (allowance <= BigInt(item.bn_sale_price)) {
        allowance = await getAllowance(
          payment.address,
          item.market.marketAddress,
          user.account_address
        );
      }
      if (allowance <= BigInt(item.bn_sale_price)) {
        await approveFor(
          payment.address,
          item.market.marketAddress,
          user.account_address
        );
        await sleep();
      }
      let promise = await buyFromMarket(item, user.account_address);
      await sleep();
      if (promise.hash) {
        // TODO: remove this once we have proper working indexers telling us whats going on
        await updateListing(promise.hash);
        dispatch(getUserCoins(settings));
        ToastPopup("Item bought successfully!");
        if (onHide) {
          onHide();
        }
        if (onBuy) {
          onBuy(item);
        }
      } else {
        ToastPopup("Something went wrong; Transaction failed.", "error");
      }
      setBuyLoading(false);
    } catch (e) {
      console.log(e);
      ToastPopup("Something went wrong; Transaction failed.", "error");
      setBuyLoading(false);
    }
    // } else if (item.active) {
    //   ToastPopup("Item has already been purchased.", "error");
    //   if (beforeSetListing) {
    //     beforeSetListing({ ...item, ...{ active: false } });
    //   }
    //   updateListing(item._id);
    //   setBuyLoading(false);
    //   onHide();
    // } else {
    //   ToastPopup("Item has already been purchased.", "error");
    //   if (beforeSetListing) {
    //     beforeSetListing({ ...item, ...{ active: false } });
    //   }
    //   setBuyLoading(false);
    //   onHide();
    //   return;
    // }
    // } catch (e) {
    //   console.log(e);
    //   ToastPopup(
    //     "An error occurred while trying to buy this NFT, please try again later.",
    //     "error"
    //   );
    //   setBuyLoading(false);
    // }
  };

  return (
    <Modal size="sm" show={!!item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-10 pd-30">
        <h3>Buy {item.nft?.name}</h3>
        <div className="flex justify-content-center">
          <img src={ipfsConvert(item.nft.image)} style={{ width: "50%" }} />
        </div>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p>Current Price</p>
          <p className="text-right price color-popup">
            {salePrice} {payment.symbol}
          </p>
        </div>
        <Royalties price={salePrice} collection={collection} item={item} />
        <br />
        <RequireAmountButton
          amount={Math.max(total, item.bn_sale_price)}
          text={buyText}
          className="btn btn-primary"
          disabled={disabled}
        >
          <LoadingButton
            onClick={() => handleOnClick()}
            disabled={disabled}
            loading={buyLoading}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {buyText}
          </LoadingButton>
        </RequireAmountButton>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: formName })(BuyModal);
