import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getCollection, updateListing } from "utils/api";
import { escapeRegExp, renderFormV2 } from "utils/form";
import { countDecimals, ipfsConvert } from "utils/formats";
import { sleep } from "utils/time";

import LoadingButton from "components/button/LoadingButton";
import RequireAmountButton from "components/button/RequireAmountButton";
import Royalties from "components/layouts/collections/Royalties";
import ToastPopup from "components/utils/ToastPopup";

const formName = "bidModal";

const BidModal = ({ item, onHide, onBid, setModalShow, beforeSetListing }) => {
  const form = useSelector((state) => state.form[formName]);
  const settings = useSelector((state) => state.settings);
  const min_bid_increment = useSelector((state) => state.settings.min_bid_increment);
  const [bidLoading, setBidLoading] = useState(false);
  const [maxDecimals, setMaxDecimals] = useState(false);
  const [bidInput, setBidInput] = useState("");
  const [nftCollection, setNftCollection] = useState({});
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d{0,${maxDecimals}}$`);

  const alertList = [
    { title: "Auction Alerts", fieldname: "auction-alerts-toggle" },
    { title: "Additional Alerts", fieldname: "additional-alerts-toggle" },
  ];

  const onLoad = async () => {
    if (item && !bidLoading) {
      // if (item.collection) {
      //   setNftCollection(item.collection);
      // } else {
      //   const nftCollection = await getCollection(item.nft_collection);
      //   setNftCollection(nftCollection.data.collection);
      // }
      // const itemExistsRes = await getObjectInfo(item.listing_object_id);
      // if (itemExistsRes) {
      //   const currentPrice = parseInt(itemExistsRes.data.bid);
      //   if (currentPrice > item.sale_price) {
      //     await updateListing(item._id);
      //     beforeSetListing({ ...item, ...{ sale_price: currentPrice } });
      //     if (setModalShow) {
      //       setModalShow({ ...item, ...{ sale_price: currentPrice } });
      //     }
      //   }
      // } else {
      //   ToastPopup("Item has already been purchased.", "error");
      //   if (beforeSetListing) {
      //     beforeSetListing({ ...item, ...{ active: false } });
      //   }
      //   updateListing(item._id);
      //   setBidLoading(false);
      //   onHide();
      // }
    }
  };

  useEffect(() => {
    if (min_bid_increment) {
      setMaxDecimals(countDecimals(min_bid_increment));
    }
  }, [min_bid_increment]);

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  const salePrice = parseFloat(item.sale_price);
  const minBid =
    item.sale_price === 0
      ? item.auction?.min_bid
      : salePrice + item.auction?.min_bid_increment;
  const inputPrice = form?.values?.price;
  const currentBid = inputPrice ? inputPrice : minBid;
  const totalPrice = minBid;
  const buttonText = "Bid";
  const disabled = bidLoading || !item.active || currentBid < minBid;

  useEffect(() => {
    setBidInput(totalPrice.toString());
  }, [totalPrice]);

  // const handleOnClick = async () => {
  //   setBidLoading(true);
  //   const itemExistsRes = await getObjectInfo(item.listing_object_id);
  //   // check if listing exists on blockchain, then if it doesn't, request listing update.
  //   if (itemExistsRes) {
  //     const currentPrice = parseInt(itemExistsRes.data.bid);
  //     // update auction price, if it's changed.
  //     if (currentPrice > item.sale_price && currentPrice > currentBid) {
  //       ToastPopup("A new, higher bid has come in.", "error");
  //       setBidLoading(false);
  //       if (setModalShow) {
  //         setModalShow({ ...item, ...{ sale_price: currentPrice } });
  //       }
  //       if (beforeSetListing) {
  //         beforeSetListing({ ...item, ...{ sale_price: currentPrice } });
  //       }
  //       await updateListing(item._id);
  //     } else {
  //       let tx = false;
  //       try {
  //         let priceToBid = inputPrice || minBid;
  //         tx = bidNFTAuction(
  //           { ...item, ...{ nft_collection: nftCollection } },
  //           priceToBid
  //         );
  //         const res = await tx;
  //         await sleep();
  //         if (res?.status === "success") {
  //           await updateListing(item._id);
  //           ToastPopup(`Item bid on successfully!`);

  //           if (beforeSetListing) {
  //             item.sale_price = priceToBid;
  //             if (item.auction.bids) {
  //               item.auction.bids.push({
  //                 _id: new Date(),
  //                 bid: priceToBid,
  //                 bidder: user,
  //                 listing: item._id,
  //                 updatedAt: "Just now",
  //               });
  //             }
  //             beforeSetListing(item);
  //           }

  //           if (onBid) {
  //             onBid(item);
  //             onHide();
  //           }
  //         } else {
  //           ToastPopup("Something went wrong; Transaction failed.", "error");
  //         }
  //         setBidLoading(false);
  //       } catch (e) {
  //         console.log(e);
  //         ToastPopup("Something went wrong; Transaction failed.", "error");
  //         setBidLoading(false);
  //       }
  //     }
  //   } else if (item.active) {
  //     ToastPopup("Item has already been purchased.", "error");
  //     if (beforeSetListing) {
  //       beforeSetListing({ ...item, ...{ active: false } });
  //     }
  //     updateListing(item._id);
  //     setBidLoading(false);
  //     onHide();
  //   } else {
  //     ToastPopup("Item has already been purchased.", "error");
  //     if (beforeSetListing) {
  //       beforeSetListing({ ...item, ...{ active: false } });
  //     }
  //     setBidLoading(false);
  //     onHide();
  //     return;
  //   }
  // };

  const onBlurBid = (value) => {
    if (value === "" || isNaN(value)) {
      setBidInput("");
    } else {
      setBidInput(Number(value).toString());
    }
  };

  const parseBid = (value) => {
    if (value === "" || inputRegex.test(escapeRegExp(value))) {
      setBidInput(value);
    }
  };

  return (
    <Modal size="sm" show={!!item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-10 pd-30">
        <h3>Bid on {item.nft?.name}</h3>
        <div className="flex justify-content-center">
          <img src={ipfsConvert(item.nft?.image)} style={{ width: "50%" }} />
        </div>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p>Highest Bid</p>
          <p className="text-right price color-popup">
            {salePrice} {settings.payment.symbol}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p>Minimum Bid</p>
          <p className="text-right price color-popup">
            {minBid} {settings.payment.symbol}
          </p>
        </div>
        <Royalties price={totalPrice} collection={nftCollection} item={item} />
        {/*
        <div className="d-flex justify-content-between">
          <p> Royalties (10%):</p>
          <p className="text-right price color-popup"> {itemRoyalties} {settings.payment.symbol} </p>
        </div>
        */}

        <Field
          type="text"
          inputMode="decimal"
          name="price"
          className="bidInput"
          placeholder="Bid amount"
          props={{
            value: bidInput.toString(),
          }}
          pattern={`^[0-9]*[.,]?[0-9]{0,${maxDecimals}}$`}
          component={renderFormV2}
          onBlur={() => {
            onBlurBid(bidInput);
          }}
          onChange={(event) => {
            if (event.currentTarget.validity.valid) {
              parseBid(event.target.value.replace(/,/g, "."));
            }
          }}
          hidename="true"
        />
        <RequireAmountButton
          amount={Math.max(minBid, form?.values?.price || 0)}
          text={buttonText}
          className="btn btn-primary"
          disabled={disabled}
        >
          <LoadingButton
            // onClick={handleOnClick}
            disabled={disabled}
            loading={bidLoading}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {buttonText}
          </LoadingButton>
        </RequireAmountButton>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: formName })(BidModal);
