export const PROMPT_CONNECTION = "PROMPT_CONNECTION";
export const CONNECTING = "CONNECTING";
export const CONNECTED = "CONNECTED";
export const DISCONNECTED = "DISCONNECTED";
export const CONTENTS = "CONTENTS";
export const COINS = "COINS";
export const WEB3_BALANCE = "WEB3_BALANCE";
export const KIOSK_CONTENTS = "KIOSK_CONTENTS";
export const LOADING = "LOADING";
export const BALANCES = "BALANCES";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FAVORITE_NFT = "FAVORITE_NFT";
export const FAVORITE_COLLECTION = "FAVORITE_COLLECTION";
export const SEARCH_FAVORITE_NFT = "SEARCH_FAVORITE_NFT";
export const SEARCH_FAVORITE_AUCTION = "SEARCH_FAVORITE_AUCTION";
export const SEARCH_FAVORITE_COLLECTION = "SEARCH_FAVORITE_COLLECTION";
export const WON_AUCTIONS = "WON_AUCTIONS";
export const WIN_LISTING = "WIN_LISTING";
export const LISTINGS_SEARCH_RESULTS = "LISTINGS_SEARCH_RESULTS";
export const LENDINGS_SEARCH_RESULTS = "LENDINGS_SEARCH_RESULTS";
export const REMOVE_LISTINGS_SEARCH_RESULTS = "REMOVE_LISTINGS_SEARCH_RESULTS";
export const COLLECTIONS_SEARCH_RESULTS = "COLLECTIONS_SEARCH_RESULTS";
export const AUCTIONS_SEARCH_RESULTS = "AUCTIONS_SEARCH_RESULTS";
export const CREATORS_SEARCH_RESULTS = "CREATORS_SEARCH_RESULTS";
export const GAMES_SEARCH_RESULTS = "GAMES_SEARCH_RESULTS";
export const LAUNCHPADS_SEARCH_RESULTS = "LAUNCHPADS_SEARCH_RESULTS";
export const LAUNCHPADS_MODULE_RESULTS = "LAUNCHPADS_MODULE_RESULTS";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SET_CREATORS = "SET_CREATORS";
export const FOLLOW = "FOLLOW";
export const REFRESH_CREATOR = "REFRESH_CREATOR";
export const HIDE_NFT = "HIDE_NFT";
export const BAGS_SEARCH_RESULTS = "BAGS_SEARCH_RESULTS";

export const WEB3_METAPORT_MODAL = "WEB3_METAPORT_MODAL";
export const WEB3_NETWORK_MODAL = "WEB3_NETWORK_MODAL";
export const WEB3_CONNECTING = "WEB3_CONNECTING";
export const WEB3_GAS_PRICE = "WEB3_GAS_PRICE";
export const WEB3_ETH_BALANCE = "WEB3_ETH_BALANCE";
export const WEB3_RECENT_DEPOSITS = "WEB3_RECENT_DEPOSITS";
export const WEB3_RECENT_WITHDRAWALS = "WEB3_RECENT_WITHDRAWALS";
export const WEB3_RECENT_TRANSACTIONS = "WEB3_RECENT_TRANSACTIONS";
export const WEB3_WALLET_COUNT = "WEB3_WALLET_COUNT";
export const WEB3_WALLET_GASCOST = "WEB3_WALLET_GASCOST";
export const WEB3_WALLET_CREATE = "WEB3_WALLET_CREATE";
export const WEB3_SAVE_ADDRESS = "WEB3_SAVE_ADDRESS";
export const WEB3_HAS_WALLET = "WEB3_HAS_WALLET";
export const WEB3_WALLET_ERROR = "WEB3_WALLET_ERROR";
export const WEB3_CLEAR_ERRORS = "WEB3_CLEAR_ERRORS";
export const WEB3_WALLET_SEND_INFO = "WEB3_WALLET_SEND_INFO";
export const WEB3_WALLET_ALLOW_INFO = "WEB3_WALLET_ALLOW_INFO";
export const WEB3_CONNECTED = "WEB3_CONNECTED";
export const WEB3_BURN_AMOUNT = "WEB3_BURN_AMOUNT";
export const WEB3_GXP_REQUIREMENTS = "WEB3_GXP_REQUIREMENTS";
export const WEB3_NEEDS_GXP = "WEB3_NEEDS_GXP";
export const WEB3_CONVERSION_ALLOWANCE = "WEB3_CONVERSION_ALLOWANCE";
export const WEB3_TOTAL_STAKED = "WEB3_TOTAL_STAKED";
