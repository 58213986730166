import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  searchMyAuctions,
  searchMyLendings,
  searchMyListings,
  searchMyRentals,
} from "redux/state/search";
import { findIndexByValue } from "utils/formats";

import MyAuctionsPanel from "components/layouts/profile/Panels/MyAuctionsPanel";
import MyListingsPanel from "components/layouts/profile/Panels/MyListingsPanel";
import MyLoanedListingsPanel from "components/layouts/profile/Panels/MyLoanedListingsPanel";
import MyRentalListingsPanel from "components/layouts/profile/Panels/MyRentalListingsPanel";
import { useProfileMenu } from "components/utils/context/ProfileMenuContext";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useTitle } from "components/utils/TitleProvider";

const MyProfileListings = React.memo(function MyProfileListings() {
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const { menuIndex, prevMenuIndex, menuData } = useProfileMenu();
  const parentLink = "profile";

  const dataTabs = [
    {
      title: "My Listings",
      link: "listings",
      panel: <MyListingsPanel title="Listings" />,
      searchFunction: () => searchMyListings,
    },
    // {
    //   title: "My Auctions",
    //   link: "auctions",
    //   panel: <MyAuctionsPanel title="Auctions" />,
    //   searchFunction: () => searchMyAuctions,
    // },
    // {
    //   title: "Rented NFTs",
    //   link: "rented",
    //   panel: <MyRentalListingsPanel title="Rented NFTs" />,
    //   searchFunction: () => searchMyRentals,
    // },
    // {
    //   title: "Loaned NFTs",
    //   link: "loaned",
    //   panel: <MyLoanedListingsPanel title="Loaned NFTs" />,
    //   searchFunction: () => searchMyLendings,
    // },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    if (menuIndex !== prevMenuIndex && prevMenuIndex !== null) {
      navigate(`/${parentLink}/${menuData[menuIndex].section}/${dataTabs[0].link}`);
    }
  }, [menuIndex]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/${parentLink}/${menuData[menuIndex].section}/${link}`);
  };

  return (
    <section className="tf-section tf-new-profile">
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </section>
  );
});

export default MyProfileListings;
