import React from "react";
import { Link } from "react-router-dom";
import {
  FlexRow,
  GridRow,
  NameImageWrapper,
  RowImage,
  RowName,
  RowText,
  TextOverflow,
} from "utils/styled";

import LazyLoadImage from "components/utils/LazyLoadImage";

const ListGameCard = ({ item }) => {
  const { _id, favorites, name, banner_image: image } = item;
  const to = `/games/${_id}`;

  const renderColumnFields = () => {
    const orderedFields = [favorites];

    return orderedFields.map((field, index) => (
      <FlexRow key={"orderedFields" + index}>
        <RowText>{field}</RowText>
      </FlexRow>
    ));
  };

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "~"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields()}
        {/* <button>test</button> */}
      </GridRow>
    </Link>
  );
};

export default ListGameCard;
