import styled from "styled-components";
import LazyLoadImage from "components/utils/LazyLoadImage";

const BannerWrapper = styled.div`
  padding-top: 230px;
  position: relative;
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const BannerVideo = styled.video`
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const BannerShadow = styled.div`
  background-image: linear-gradient(to bottom, #181a20aa, transparent);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ProfileImageWrapper = styled.div`
  height: 200px;
  width: 200px;
  @media only screen and (max-width: 991px) {
    height: 140px;
    width: 140px;
  }
`;

const GameBanner = ({ game }) => {
  const { banner_image } = game;
  return (
    <BannerWrapper>
      <BannerShadow />
      {banner_image && banner_image?.split(".").pop() === "mp4" ? (
        <BannerVideo autoPlay muted loop>
          <source src={banner_image} type="video/mp4" />
        </BannerVideo>
      ) : (
        <BannerImage src={banner_image} />
      )}
      <div className="themesflat-container">
        <ProfileImageWrapper>
          {banner_image && banner_image?.split(".").pop() === "mp4" ? (
            <video className="game-image" style={{ zIndex: 1 }} autoPlay muted loop>
              <source src={banner_image} type="video/mp4" />
            </video>
          ) : (
            <LazyLoadImage className="game-image" src={banner_image} />
          )}
        </ProfileImageWrapper>
      </div>
    </BannerWrapper>
  );
};

export default GameBanner;
