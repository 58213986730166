import React from "react";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
// import HorizontalSlider from "components/inputs/HorizontalSlider";
import PopularCollection from "components/layouts/collections/PopularCollection";
import TopCollections from "components/layouts/collections/TopCollections";
// import CreateAndSell from "components/layouts/CreateAndSell";
import NewestNFTs from "components/layouts/NewestNFTs";
import TripleVerticalSlider from "components/slider/TripleVerticalSlider";

const Home = () => {
  return (
    <div className="home-1">
      <Header />
      <TripleVerticalSlider />
      {/* <HorizontalSlider /> */}
      <NewestNFTs />
      <TopCollections />
      <PopularCollection />
      {/* <CreateAndSell /> */}
      <Footer />
    </div>
  );
};

export default Home;
