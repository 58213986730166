import React, { useContext, useState } from "react";
import { useWindowSize } from "utils/hooks";

const SidebarContext = React.createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarProvider({ children }) {
  const screenWidth = useWindowSize().width;
  const [sidebarData, setSidebarData] = useState([]);
  const [showFilter, setShowFilter] = useState(screenWidth > 767 ? true : false);

  return (
    <SidebarContext.Provider
      value={{ sidebarData, setSidebarData, showFilter, setShowFilter }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
