import { cloneElement, isValidElement, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as QuestionMark } from "assets/images/icon/QuestionMark.svg";
import styled from "styled-components";

const Background = styled.div`
  background-color: var(--primary-color5);
`;

const LazyLoadImage = ({ src, fallback, isIcon, className, ...props }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => {
          if (fallback) {
            setImgSrc(fallback);
            setImgLoaded(true);
          } else {
            setImgSrc(false);
            setImgLoaded(true);
          }
          reject(err);
        };
      });
    };

    loadImage(src)
      .then(() => setImgLoaded(true))
      .catch((err) => {});
  }, []);

  if (!imgSrc) {
    if (isValidElement(props.children)) {
      return cloneElement(props.children, { className, ...props });
    } else if (isIcon) {
      return <QuestionMark className={className} {...props} />;
    } else {
      return <Background className={className} {...props} />;
    }
  }

  return (
    <>
      {!imgLoaded ? (
        <Skeleton height="100%" containerClassName={className} {...props} />
      ) : (
        <img src={imgSrc} className={className} {...props} />
      )}
    </>
  );
};

export default LazyLoadImage;
