import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import creatorBackground from "assets/images/item-background/bg-authors2.jpg";
import { Explorer } from "utils/environments";
import { imageOnErrorHandler, statusToEffect } from "utils/formats";

import SocialButtons from "components/button/SocialButtons";
import LazyLoadImage from "components/utils/LazyLoadImage";
import CreatedBy from "components/utils/CreatedBy";
import VerifiedBadge from "components/utils/VerifiedBadge";

const CollectionInfo = ({ data }) => {
  const settings = useSelector((state) => state.settings);
  const user = useSelector((state) => state.user);
  const {
    creator,
    name,
    description,
    _id,
    address,
    volume,
    total_sales,
    nft_count,
    owners,
    listed,
    floor,
    review_status,
    twitter,
    discord,
    instagram,
    whitepaper,
    website,
    featured_image,
    logo_image,
    verified,
  } = data;
  const socials = { twitter, discord, instagram };
  const isCreator = useMemo(() => {
    return user && creator ? creator._id === user._id : false;
  }, [user, creator]);
  return (
    <section className="tf-section collection-info">
      <div className="themesflat-container">
        <div className="tab-collections">
          <div className="collection-banner">
            <div className="dark-overlay" />
            {featured_image && featured_image?.split(".").pop() === "mp4" ? (
              <video
                className="collection-banner-bg"
                style={{ zIndex: 1 }}
                autoPlay
                muted
                loop
              >
                <source src={featured_image} type="video/mp4" />
              </video>
            ) : (
              <LazyLoadImage
                className="collection-banner-bg"
                src={featured_image}
                fallback={creatorBackground}
              />
            )}
            <div className="collection-banner-contents flex">
              <div style={{ position: "relative" }}>
                <img
                  src={logo_image}
                  onError={imageOnErrorHandler}
                  className="featured-image"
                />

                {isCreator && (
                  <div className="ps-abs-bmdl">
                    <span
                      className={`sc-status ${statusToEffect(review_status)} capitalize`}
                    >
                      {review_status}
                    </span>
                  </div>
                )}
              </div>
              <div className="info-profile">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "2rem",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <h2 className="title mb-0">{name ?? "Unnamed Collection"}</h2>
                      <VerifiedBadge verified={verified} inline={true} />
                    </div>

                    <div className="collection-author">
                      <div className="author">
                        <div className="content">
                          <div className="info">
                            {creator && (
                              <>
                                <span>Created by</span>
                                <span className="name">
                                  <CreatedBy creator={creator} />
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="content">{description ?? ""}</p>
                <br />
                <a
                  className="heading"
                  href={`${Explorer}/address/${address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5 className="tf-text fill">View on Explorer</h5>
                </a>

                {isCreator && <Link to={`/edit-collection/${_id}`}>Edit Collection</Link>}
              </div>
              <div className="collection-stats">
                <SocialButtons socials={socials} />
                <div className="grid-container">
                  <GridCard
                    title="FLOOR"
                    value={`${floor.toFixed(2)} ${settings.payment.symbol}`}
                  />
                  <GridCard title="LISTED" value={listed} />
                  <GridCard
                    title="TOTAL VOL"
                    value={`${volume.toFixed(2)} ${settings.payment.symbol}`}
                  />
                  <GridCard title="OWNERS" value={owners} />
                  <GridCard
                    title="AVG. SALE"
                    value={`${(volume / (total_sales || 1)).toFixed(2)} ${
                      settings.payment.symbol
                    }`}
                  />
                  <GridCard title="ACTIVE SUPPLY" value={nft_count} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GridCard = ({ title, value }) => {
  return (
    <div className="grid-item">
      <div className="grid-item">
        <span className="title">{title}</span>
      </div>
      <div className="grid-item">
        <span className="value">{value}</span>
      </div>
    </div>
  );
};

export default CollectionInfo;
