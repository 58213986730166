import { PROFILE_SHOW_HIDDEN_NFTS } from "./types";

const initialState = {
  showHiddenNFTs: false,
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_SHOW_HIDDEN_NFTS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
