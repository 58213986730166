import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useWagmiSwitchNetwork } from "@skalenetwork/metaport";
import domains from "assets/data/domains";
import useWeb3NetworkData from "redux/hooks/useWeb3NetworkData";
import { showNetworkModal } from "redux/state/web3";
import { NetworkLogo } from "utils/environments";
import { addChain } from "utils/web3/web3Utils";

const NetworkModal = () => {
  useWeb3NetworkData();
  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.web3);

  const { switchNetwork } = useWagmiSwitchNetwork();

  return (
    <Modal
      show={web3.showNetworkModal}
      onHide={() => {
        dispatch(showNetworkModal(false));
      }}
      className="network-modal"
    >
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Welcome to Keepsake Beta!</h3>
        <h5 className="text-center">Choose which network you&apos;d like to use</h5>
        {window.location.origin.includes(domains.calypso) ? (
          <button
            onClick={async () => {
              if (web3.bad_network) {
                try {
                  await addChain();
                  switchNetwork(process.env.REACT_APP_NETWORK_ID);
                } catch {
                  switchNetwork(process.env.REACT_APP_NETWORK_ID);
                }
              }
              dispatch(showNetworkModal(false));
            }}
            className="btn btn-primary btn-icon"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            <NetworkLogo network="Calypso" />
            <b>Calypso NFT Hub</b>
          </button>
        ) : (
          <a href={`${domains.calypso}`}>
            <button
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#popup_bid_success"
              data-dismiss="modal"
              aria-label="Close"
            >
              <NetworkLogo network="Calypso" />
              <b>Calypso NFT Hub</b>
            </button>
          </a>
        )}
        {window.location.origin.includes(domains.nebula) ? (
          <button
            onClick={async () => {
              if (web3.bad_network) {
                try {
                  await addChain();
                  switchNetwork(process.env.REACT_APP_NETWORK_ID);
                } catch {
                  switchNetwork(process.env.REACT_APP_NETWORK_ID);
                }
              }
              dispatch(showNetworkModal(false));
            }}
            className="btn btn-primary btn-icon"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            <NetworkLogo network="Nebula" />
            <b>Nebula Gaming Hub</b>
          </button>
        ) : (
          <a href={`${domains.nebula}`}>
            <button
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#popup_bid_success"
              data-dismiss="modal"
              aria-label="Close"
            >
              <NetworkLogo network="Nebula" />
              <b>Nebula Gaming Hub</b>
            </button>
          </a>
        )}
        {window.location.origin.includes(domains.sui) ? (
          <button
            disabled
            className="btn btn-primary btn-icon"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            <NetworkLogo network="Sui" />
            <b>Sui</b>
          </button>
        ) : (
          <a href={`${domains.sui}`}>
            <button
              className="btn btn-primary btn-icon"
              data-toggle="modal"
              data-target="#popup_bid_success"
              data-dismiss="modal"
              aria-label="Close"
            >
              <NetworkLogo network="Sui" />
              <b>Sui</b>
            </button>
          </a>
        )}
      </div>
    </Modal>
  );
};

export default NetworkModal;
