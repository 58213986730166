import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getCollection, updateLoanListing } from "utils/api";
import { escapeRegExp, renderFormV2 } from "utils/form";
import { ipfsConvert, renderDuration } from "utils/formats";
import { sleep } from "utils/time";

import LoadingButton from "components/button/LoadingButton";
import RequireAmountButton from "components/button/RequireAmountButton";
import Royalties from "components/layouts/collections/Royalties";
import ToastPopup from "components/utils/ToastPopup";

const formName = "rent-modal";

const validate = (values) => {
  const errors = {};
  if (!values.hours) {
    errors.hours = "Required";
  }
  return errors;
};

const RentModal = ({
  item,
  onHide,
  onBuy,
  beforeSetListing,
  pristine,
  submitting,
  invalid,
}) => {
  let dispatch = useDispatch();
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const [buyLoading, setBuyLoading] = useState(false);
  const [nftType, setNftType] = useState(false);
  const [nftCollection, setNftCollection] = useState({});

  const onLoad = async () => {
    if (item && !buyLoading) {
      if (item.collection) {
        setNftCollection(item.collection);
      } else {
        setBuyLoading(true);
        const nftCollection = await getCollection(item.nft_collection);
        setNftCollection(nftCollection.data.collection);
        setBuyLoading(false);
      }
      // const itemExistsRes = await getObjectInfo(item.listing_object_id);
      // if (!itemExistsRes) {
      //   ToastPopup("Listing no longer exists.", "error");
      //   if (beforeSetListing) {
      //     beforeSetListing({ ...item, ...{ active: false } });
      //   }
      //   updateLoanListing(item._id);
      //   setBuyLoading(false);
      //   onHide();
      // } else {
      //   const nftInfo = await getObjectInfo(item.nft_object_id);
      //   setNftType(nftInfo.type);
      // }
    }
  };

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  const max = useMemo(() => {
    let ends = new Date(item.end_date);
    let now = new Date();
    let diff = Math.round(Math.abs(ends - now) / 3600000);
    return Math.min(diff, item?.duration?.max);
  }, [item]);

  if (!item) return null;

  const salePrice =
    parseFloat(item.ask_per_day * (parseInt(form?.values?.hours) || 0)) / 24;
  const buyText = "Rent";
  const disabled = buyLoading || !item.active || pristine || invalid;

  const handleOnClick = async () => {
    /*
    setBuyLoading(true);
    const itemExistsRes = await getObjectInfo(item.listing_object_id);
    // check if listing exists on blockchain, then if it doesn't, request listing update.
    if (itemExistsRes) {
      try {
        if (itemExistsRes.data.loan_id) {
          // TODO: pass in current kiosk
          // let returnRes = await returnNFT(item);
        }

        let res = await borrowNFT(
          { ...item, ...{ nft_collection: nftCollection } },
          form.values.hours
        );

        if (res?.status === "success") {
          await sleep();
          await updateLoanListing(item._id);
          ToastPopup("Item rented successfully!");
          if (onBuy) {
            onBuy(item);
          }
          if (onHide) {
            onHide();
          }
        } else {
          ToastPopup("Something went wrong; Transaction failed.", "error");
        }
      } catch (e) {
        console.log(e);
        ToastPopup("Something went wrong; Transaction failed.", "error");
      }
    } else {
      ToastPopup("Item has already been rented.", "error");
      if (beforeSetListing) {
        beforeSetListing({ ...item, ...{ active: false } });
      }
      setBuyLoading(false);
    }
    */
  };

  const parseHours = (val) => {
    const inputRegex = RegExp(`^[0-9]*$`);
    if (inputRegex.test(escapeRegExp(val))) {
      return Math.min(val, max);
    }
  };

  return (
    <Modal show={!!item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-10 pd-40">
        <h2>Rent {item.nft?.name}</h2>
        <div className="flex justify-content-center">
          <img src={ipfsConvert(item.nft.image)} style={{ width: "50%" }} />
        </div>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p>Price per day</p>
          <p className="text-right price color-popup">
            {item.ask_per_day} {settings.payment.symbol}
          </p>
        </div>
        <Field
          type="text"
          name="hours"
          className="hoursInput"
          placeholder={`Hours to rent for (${item?.duration?.min || 1}-${max})`}
          props={{ step: 1, max }}
          component={renderFormV2}
          parse={(val, name) => (val ? parseHours(val, name) : "")}
          hidename="true"
          pattern={`^[0-9]*$`}
        />
        {/* <div className="d-flex justify-content-between">
          <p>Fees ({100 * itemFees}%):</p>
          <p className="text-right price color-popup"> {feePrice} {settings.payment.symbol} </p>
        </div> */}
        <div className="d-flex justify-content-between">
          <p>Min Rent Duration</p>
          <p className="text-right price color-popup">
            {renderDuration(item.duration?.min)}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p>Max Rent Duration</p>
          <p className="text-right price color-popup">{renderDuration(max)}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p>Rent Deadline</p>
          <p className="text-right price color-popup">
            {new Date(item.end_date).toLocaleString()}
          </p>
        </div>
        <Royalties item={item} price={salePrice} collection={nftCollection} />

        <RequireAmountButton
          amount={salePrice}
          text={buyText}
          className="btn btn-primary"
          disabled={disabled}
        >
          <LoadingButton
            onClick={() => handleOnClick()}
            disabled={disabled}
            loading={buyLoading}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {buyText}
          </LoadingButton>
        </RequireAmountButton>
      </div>
    </Modal>
  );
};

export default reduxForm({ form: formName, validate })(RentModal);
