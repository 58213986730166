import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { WIN_LISTING } from "redux/types";
import { getCollection, updateListing } from "utils/api";
import { ipfsConvert } from "utils/formats";

import LoadingButton from "components/button/LoadingButton";

const WinModal = ({ item, onHide, fetchListing }) => {
  if (!item) {
    return false;
  }

  let dispatch = useDispatch();

  const [buyLoading, setBuyLoading] = useState(false);
  let payment = useSelector((state) => state.settings.payment);
  const [nftCollection, setNftCollection] = useState({});

  const handleSubmit = async () => {
    // setBuyLoading(true);
    // let res = false;
    // if (nftCollection.kiosk_enabled) {
    //   res = await winNFTAuction({ ...item, nft_collection: nftCollection });
    // } else {
    //   res = await winNFTAuction({ ...item, nft_collection: nftCollection });
    // }
    // if (res?.status === "success") {
    //   await updateListing(item._id);
    //   dispatch({ type: WIN_LISTING, _id: item._id });
    //   if (fetchListing) {
    //     fetchListing();
    //   }
    //   onHide();
    // }
  };
  const onLoad = async () => {
    if (item && !buyLoading) {
      if (item.collection) {
        setNftCollection(item.collection);
      } else {
        const nftCollection = await getCollection(item.nft_collection);
        setNftCollection(nftCollection.data.collection);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [item?._id]);

  return (
    <Modal show={item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Claim this listing?</h3>
        <h5 className="text-center">{item.nft.name}</h5>
        <img src={ipfsConvert(item.nft?.image)} />
        <div className={"field-container"}>
          <input
            name="price"
            type="text"
            disabled
            value={`${item.sale_price} ${payment.symbol}`}
          />
        </div>
        <LoadingButton
          loading={buyLoading}
          disabled={buyLoading}
          onClick={() => handleSubmit(item)}
        >
          Claim listing
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default WinModal;
