import { Navigate } from "react-router-dom";

import FullScreenLoading from "components/utils/FullScreenLoading";
import ToastPopup from "components/utils/ToastPopup";

const PrivateRoute = ({ user, element }) => {
  const wallet = {};

  if (wallet.status === "loading") {
    return <FullScreenLoading />;
  }

  if (!user._id && wallet.status === "NoConnection") {
    // not logged in so redirect to login page with the return url
    ToastPopup("Please connect your wallet to continue", "warning");
    return <Navigate to="/" replace />;
  }

  // authorized so return child components
  return element;
};

export default PrivateRoute;
