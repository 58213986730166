import { useSelector } from "react-redux";
import styled from "styled-components";

const Total = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const TotalPrice = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: var(--primary-color2);
`;

const Royalties = ({ price, collection }) => {
  let { symbol } = useSelector((state) => state.settings.payment);
  let { fee_bps } = useSelector((state) => state.settings.market);

  const renderRoyalty = (royalty, price) => {
    let percentage = royalty / 100;
    let feeDisplay = `Royalty Fee: ${percentage}%`;

    let cut = (price * percentage) / 100;
    return (
      <div className="d-flex justify-content-between">
        <p>{feeDisplay}</p>
        <p className="text-right price color-popup">
          {cut} {symbol}
        </p>
      </div>
    );
  };
  let marketFee = fee_bps / 10000;

  return (
    <div>
      {collection.royaltyFee && renderRoyalty(collection.royaltyFee, price)}
      <div className="d-flex justify-content-between">
        <p>Market Fee: {marketFee * 100}%</p>
        <p className="text-right price color-popup">
          {marketFee * price} {symbol}
        </p>
      </div>
      <br />
      <div className="d-flex justify-content-between dashed-line">
        <Total>Total:</Total>
        <TotalPrice>
          {price} {symbol}
        </TotalPrice>
      </div>
    </div>
  );
};
export default Royalties;
