import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import img4 from "assets/images/box-item/img_slide4.png";
import img5 from "assets/images/box-item/img_slide5.png";
import img6 from "assets/images/box-item/img_slide6.png";
import img7 from "assets/images/box-item/img_slide7.png";
import img10 from "assets/images/box-item/img_slide10.png";
import img14 from "assets/images/box-item/img_slide14.png";
import img16 from "assets/images/box-item/img_slide16.png";
import img17 from "assets/images/box-item/img_slide17.png";
import img18 from "assets/images/box-item/img_slide18.png";
import img20 from "assets/images/box-item/img_slide20.png";
import img21 from "assets/images/box-item/img_slide21.png";
import img22 from "assets/images/box-item/img_slide22.png";
import img23 from "assets/images/box-item/img_slide23.png";
import img24 from "assets/images/box-item/img_slide24.jpg";
import img25 from "assets/images/box-item/img_slide25.jpg";
import img26 from "assets/images/box-item/img_slide26.jpg";
import img27 from "assets/images/box-item/img_slide27.jpg";
import img28 from "assets/images/box-item/img_slide28.jpg";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getFeature } from "utils/api";

import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "swiper/scss";

const backupImages = [
  img4,
  img5,
  img6,
  img7,
  img10,
  img14,
  img16,
  img17,
  img18,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
];

const TripleVerticalSlider = () => {
  let skeleton = <Skeleton width="227px" height="227px" />;
  const [featured, setFeatured] = useState(Array(18).fill(null));

  const getFeatured = async () => {
    let data = await getFeature("swiper-nfts");
    setFeatured(
      data.data.concat(
        backupImages.map((a) => ({
          image: a,
        }))
      )
    );
  };

  useEffect(() => {
    getFeatured();
  }, []);

  const renderImage = (nft, index) => {
    if (typeof nft?.image == "string") {
      return (
        <SwiperSlide key={nft.image}>
          <img src={nft.thumb || nft.image} />
        </SwiperSlide>
      );
    } else {
      return <SwiperSlide key={index}>{skeleton}</SwiperSlide>;
    }
  };

  return (
    <div className="home-7">
      <section className="flat-title-page style3 mainslider">
        <div className="overlay"></div>
        <div className="themesflat-container ">
          <div className="wrap-heading flat-slider flex">
            <div className="content">
              <h2 className="heading mt-15">A Reimagined</h2>
              <h1 className="heading mb-style">
                <span className="tf-text s1">NFT Gaming</span>
              </h1>
              <h1 className="heading">Marketplace</h1>
              <p className="sub-heading mt-19 mb-40">
                The Marketplace For Utility Based Collections And All Gaming Digital
                Collectibles
              </p>
              <div className="flat-bt-slider flex style2">
                <Link
                  to="/explore"
                  className="sc-button primary header-slider style style-1 fl-button"
                >
                  <span>Explore</span>
                </Link>
                {/* <Link to="/mintpad" className="sc-button secondary header-slider style">
                  <span>Mintpads</span>
                </Link> */}
              </div>
            </div>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={4200}
            >
              {featured.slice(0, 6).map((nft, index) => renderImage(nft, index))}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={3600}
            >
              {featured.slice(6, 12).map((nft, index) => renderImage(nft, index))}
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={25}
              slidesPerView={"auto"}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={4800}
            >
              {featured.slice(12, 18).map((nft, index) => renderImage(nft, index))}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TripleVerticalSlider;
