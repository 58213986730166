import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatAttributeLink } from "utils/formats";

const AttributesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #353840;
  border-radius: var(--border-radius-md);
  padding: 1rem 1.5rem;
  align-items: center;
  width: auto;
  height: 100%;
  gap: 1rem;
  word-break: break-word;
  cursor: pointer;
  transition: all 0.15s;

  :hover,
  :focus {
    background: var(--hover-color);
    border: 2px solid var(--primary-color3);
  }
`;

const AttributeName = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: Capitalize;
  color: var(--primary-color9);
`;

const AttributeValue = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const AttributesGrid = ({ collectionID, nftFields, saleType }) => {
  const navigate = useNavigate();
  const attributeHandler = (attributeName, attributeValue) => {
    const attributeLink = formatAttributeLink(
      collectionID,
      saleType,
      attributeName,
      attributeValue
    );
    navigate(encodeURI(attributeLink));
  };

  return (
    <AttributesWrapper>
      {Object.keys(nftFields || {})?.map((name) => (
        <AttributeContainer
          key={name}
          onClick={() => attributeHandler(name, nftFields[name].toString())}
        >
          <AttributeName>{name}</AttributeName>
          <AttributeValue>{nftFields[name].toString()}</AttributeValue>
        </AttributeContainer>
      ))}
    </AttributesWrapper>
  );
};

export default AttributesGrid;
