import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { REMOVE_LISTINGS_SEARCH_RESULTS } from "redux/types";
import { getSearchFields } from "utils/formats";
import { sortListingsDropdown } from "utils/sortDropdowns";

import LayoutButtons from "components/button/LayoutButtons";
import RefreshButton from "components/button/RefreshButton";
import ListNFTCard from "components/cards/listings/ListNFTCard";
import NFTCard from "components/cards/listings/NFTCard";
import SearchBar from "components/inputs/SearchBar";
import Sorting from "components/inputs/Sorting";
import CardList from "components/layouts/explore/Lists/CardList";
import ListNFTSkeleton from "components/cards/nfts/ListNFTSkeleton";
import BuyModal from "components/layouts/modal/BuyModal";
import NFTCongratulationsModal from "components/layouts/modal/NFTCongratulationsModal";
import UnlistModal from "components/layouts/modal/UnlistModal";
import NFTSkeleton from "components/cards/nfts/NFTSkeleton";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSidebar } from "components/utils/SidebarProvider";

const CollectionListingsPanel = ({ title, collection, attributes }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.search.loading);
  const settings = useSelector((state) => state.settings);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const { setSidebarData } = useSidebar();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [congratulationsModal, showCongratulationsModal] = useState(false);
  const [searchParams, _] = useSearchParams();
  const isOwner = user._id === modalShow.seller;
  const reduxState = useSelector((state) => state.search.listings);

  const orderedHeaders = ["Name", "Current Price", "Favorites"];
  let newSidebarData = [
    {
      title: "Status",
      name: "collection.status",
      show: true,
      content: {
        type: "select",
        values: ["Active", "Sold"],
      },
    },
  ];

  useEffect(() => {
    setSidebarData([
      ...new Set([
        ...newSidebarData,
        ...getSearchFields({ ...collection, attributes, searchParams }),
      ]),
    ]);
  }, [collection, attributes]);

  const onBuy = (item) => {
    if (item?.sale_type === "sale") {
      dispatch({ type: REMOVE_LISTINGS_SEARCH_RESULTS, id: item._id });
    }
    showCongratulationsModal(item);
  };

  const handleHideModal = () => {
    showCongratulationsModal(false);
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortListingsDropdown} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={NFTSkeleton}
          CardComponent={NFTCard}
          ListSkeletonComponent={ListNFTSkeleton}
          ListCardComponent={ListNFTCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
        />
      </PaginationWrapper>
      {isOwner ? (
        <UnlistModal item={modalShow} onHide={() => setModalShow(false)} />
      ) : (
        <BuyModal
          onBuy={onBuy}
          item={modalShow}
          setModalShow={setModalShow}
          beforeSetListing={refreshSearch}
          onHide={() => {
            // showCongratulationsModal(modalShow);
            setModalShow(false);
          }}
        />
      )}
      <NFTCongratulationsModal
        item={congratulationsModal}
        onHide={() => handleHideModal()}
      />
    </div>
  );
};

export default CollectionListingsPanel;
