import { Children } from "react";
import styled from "styled-components";

export const GridRow = styled.div`
  padding: 0 2rem;
  display: grid;
  align-items: center;
  height: 88px;
  width: max-content;
  min-width: 100%;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  transition: all 0.4s ease;
  grid-template-columns: minmax(0px, 3fr) ${(props) =>
      `repeat(${Children.toArray(props.children).length - 1}, minmax(100px, 1fr))`};
  &:hover {
    background: var(--hover-color);
  }
`;

export const NameImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

export const RowImage = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50px;
  overflow: hidden;
`;

export const RowName = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: -0.5px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

export const RowText = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const ListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1.5rem;
`;

export const NoResultsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  gap: 3rem;
`;

export const PrimaryText = styled.h2``;

export const SecondaryText = styled.h4`
  color: var(--primary-color9);
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GridHeader = styled.div`
  display: grid;
  align-items: center;
  width: max-content;
  min-width: 100%;
  grid-template-columns: minmax(0px, 3fr) ${(props) =>
      `repeat(${Children.toArray(props.children).length - 1}, minmax(100px, 1fr))`};
  padding: 0 2rem;
  margin-bottom: 1rem;
`;

export const HeaderText = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 10px;
  text-transform: uppercase;
  color: rgb(138, 147, 155);

  @media screen and (min-width: 575px) {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const StatsInner = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #707070;
  border-radius: var(--border-radius-md);
  padding: 2rem;
  gap: 2rem;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const NFTContainerWrapper = styled(StatsInner)`
  padding: 0;
  overflow: hidden;
  width: ${({ width }) => (typeof width == "number" ? `${width}px` : width)};
  height: ${({ height }) => (typeof height == "number" ? `${height}px` : height)};
  cursor: pointer;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  background: var(--bg-section3);
  transition: all 0.3s;

  &:hover {
    background-color: var(--primary-color7);
  }
`;

export const InlineNFTContainerWrapper = styled(NFTContainerWrapper)`
  display: inline-block;
  margin-right: 1rem;
`;

export const NFTSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1rem;
  width: 100%;
`;

export const InlineNFTSelectWrapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  white-space: nowrap;
  overflow-x: scroll;
  display: inline-block;
`;

export const NFTOptionContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s all;
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
  aspect-ratio: 1;
  border: ${({ selected }) => (selected ? `4px solid var(--primary-color3)` : `none`)};

  img,
  video {
    border-radius: 0px;
    height: 250px;
    width: 250px;
  }

  span {
    font-size: 28px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    z-index: 1;
    transition: 0.2s all;
  }

  ::after {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.2s all;
  }

  &:hover {
    ::after {
      box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0.3);
    }

    span {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

export const NFTCount = styled.div`
  position: absolute;
  display: flex;
  border-radius: 12px;
  height: 42px;
  width: 42px;
  background: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.12) 100%);
  backdrop-filter: blur(20px);
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 24px;
`;
