import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import usdc from "assets/images/icon/usdc.png";
import { logout as logoutUser } from "redux/state/user";
import { showMetaport, showNetworkModal } from "redux/state/web3";
import { numberShortFormat } from "utils/formats";
import { getDisplayVal } from "utils/web3/web3Utils";

import Avatar from "components/layouts/Avatar";

const HeaderProfile = ({ toggleProfile, profile }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const balance = useSelector((state) => state.web3.balance);
  const payment = useSelector((state) => state.settings.payment);

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="header_profile" onClick={() => toggleProfile(!profile)}>
      {/* <div className="price">
        <span>{numberShortFormat(getDisplayVal(web3.gasBalance, 18))} sFUEL</span>
      </div> */}
      <div className="header_avatar">
        <div>
          <div className="price">
            <img src={usdc} />
            <span>
              {numberShortFormat(getDisplayVal(balance || 0, payment.decimals))}{" "}
              <strong>{payment?.symbol}</strong>
            </span>
          </div>
        </div>
        <Avatar creator={user} size={50} nolink />
      </div>
      <div className={`avatar_popup ${profile ? "visible" : ""}`}>
        <div className="links">
          {user.role_id == 3 && (
            <Link to="/review">
              <i className="fas fa-pencil-alt"></i> <span> Review Content</span>
            </Link>
          )}
          <Link>
            <i className="fas fa-exchange"></i>
            <span onClick={() => dispatch(showMetaport(!web3.metaport))}>
              {" "}
              Metaport Bridge
            </span>
          </Link>
          {process.env.REACT_APP_NETWORK_SWITCHER && (
            <Link>
              <i className="fas fa-link"></i>
              <span onClick={() => dispatch(showNetworkModal(true))}> Switch chain</span>
            </Link>
          )}
          <Link to="/profile">
            <i className="fas fa-chart-bar"></i> <span> My Profile</span>
          </Link>
          {/* <Link to="/rewards">
            <i className="fas fa-gift"></i> <span> Rewards</span>
          </Link> */}
          <Link to="/settings">
            <i className="fas fa-cog"></i> <span> Settings</span>
          </Link>
          {/* <Link to="/swap">
            <i className="fas fa-exchange"></i> <span> P2P Swap</span>
          </Link> */}
          {/* <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdvE9r7iOjhSxTF7aivHdr2xyha8Epj7albYwusPl-rcy3oHQ/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-external-link"></i> <span> Apply for Mintpad</span>
          </a> */}
          <a className="pointer" id="logout" onClick={logout}>
            <i className="fal fa-sign-out"></i> <span> Logout</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
