import { useMemo, useState } from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getNFT } from "utils/api";
import { Explorer } from "utils/environments";
import { ipfsConvert } from "utils/formats";

import WishlistButton from "components/button/WishlistButton";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import AttributesGrid from "components/layouts/AttributesGrid";
import Avatar from "components/layouts/Avatar";
import PageHeader from "components/layouts/PageHeader";
import CreatedBy from "components/utils/CreatedBy";
import LazyLoadImage from "components/utils/LazyLoadImage";

import {
  ContentRightWrapper,
  Description,
  DetailsFlex,
  DetailsName,
  DetailsValue,
  InnerDetails,
  ItemName,
  NameWrapper,
  SmallButtonsWrapper,
  Views,
} from "./Styled";

import "react-tabs/style/react-tabs.css";

const ItemDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  if (!params.id) navigate("/explore");

  const [nft, setNFT] = useState({});
  const user = useSelector((state) => state.user);
  let payment = useSelector((state) => state.settings.payment);
  const favorited = useMemo(() => user.favorite_nfts?.includes(nft?._id), [user, nft]);
  const { nft_collection: collection } = nft;

  // hacky fix for the favorites button not incrementing immediately.
  const favouriteHandler = () => {
    if (favorited) {
      nft.favorites = nft.favorites - 1;
    } else {
      nft.favorites = nft.favorites + 1;
    }
  };

  const fetchListing = () => {
    getNFT(params.id)
      .then(({ data }) => {
        setNFT(data);
      })
      .catch(() => {
        navigate("/explore");
      });
  };

  useEffect(() => {
    fetchListing();
  }, []);

  const renderHistoryText = (sale) => {
    switch (sale.result) {
      case "sold":
        return `Sold it for ${sale.sale_price} ${payment.symbol}`;
      case "cancelled":
        return `Cancelled the listing`;
      default:
        break;
    }
  };

  const ListingImage = ({ image }) =>
    ipfsConvert(image).split(".").pop() === "mp4" ? (
      <video
        style={{ zIndex: 1, borderRadius: "var(--border-radius-md)" }}
        autoPlay
        muted
        loop
      >
        <source src={ipfsConvert(image)} type="video/mp4" />
      </video>
    ) : (
      <LazyLoadImage src={ipfsConvert(image)} />
    );

  return (
    <div className="item-details">
      <Header />
      <PageHeader />
      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <ListingImage image={nft.image} />
                </div>
                {nft?.name && nft?.fields && (
                  <div>
                    <Accordion title={`Attributes - ${nft.name}`} show>
                      <AttributesGrid
                        collectionID={collection?._id}
                        nftFields={nft?.fields}
                      />
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <ContentRightWrapper>
                  <div className="flex-space-between">
                    <Link to={`/collection-details/${collection?._id}`}>
                      <h4>{collection?.name || "Collection"}</h4>
                    </Link>
                    <a
                      className="heading"
                      href={`${Explorer}/token/${collection?.address}/instance/${nft.token_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h5 className="tf-text fill">View on Explorer</h5>
                    </a>
                  </div>
                  <NameWrapper>
                    <ItemName>{nft?.name || "NFT Name"}</ItemName>
                    <SmallButtonsWrapper>
                      <div className="meta-item">
                        <Views>
                          <span className="far fa-eye" />
                          <span>{nft?.views ?? "0"}</span>
                        </Views>
                      </div>
                      <WishlistButton
                        style={{ position: "relative" }}
                        nft={nft}
                        onClick={() => favouriteHandler()}
                      />
                    </SmallButtonsWrapper>
                  </NameWrapper>
                  {/* <div className="client-info">
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      <span style={{ color: "var(--primary-color9)" }}>Sold By</span>{" "}
                      <CreatedBy creator={listing?.seller} />
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      <span style={{ color: "var(--primary-color9)" }}>Created By</span>{" "}
                      <CreatedBy creator={listing?.creator} />
                    </span>
                  </div> */}
                  <div className="flat-tabs themesflat-tabs">
                    {nft?.description && (
                      <Accordion title="Details" show>
                        <InnerDetails>
                          <Description>{nft.description}</Description>
                        </InnerDetails>
                      </Accordion>
                    )}
                    <Accordion title="Sale History" show>
                      <ul className="bid-history-list">
                        {Object.assign([], nft.history)
                          .reverse()
                          .map((sale) => (
                            <li key={sale._id}>
                              <div className="content">
                                <div className="client">
                                  <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                      <Avatar creator={sale.seller} size={50} />
                                      <div className="badge"></div>
                                    </div>
                                    <div className="author-info">
                                      <div className="name">
                                        <CreatedBy creator={sale.seller} />
                                        <span>{renderHistoryText(sale)}</span>
                                      </div>
                                      <span className="time">
                                        {new Date(sale.updatedAt).toLocaleString("en-US")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Accordion>
                    {collection && (
                      <Accordion title="Market Info">
                        <InnerDetails>
                          <DetailsFlex key="object_id">
                            <DetailsName>NFT Token ID</DetailsName>
                            <DetailsValue>{nft.token_id}</DetailsValue>
                          </DetailsFlex>
                          <DetailsFlex key="collection_id">
                            <DetailsName>Collection Address</DetailsName>
                            <DetailsValue>{collection.address}</DetailsValue>
                          </DetailsFlex>
                          <DetailsFlex key="seller_fees">
                            <DetailsName>Seller Fees</DetailsName>
                            <DetailsValue>5%</DetailsValue>
                          </DetailsFlex>
                          {/* <DetailsFlex key="royalties">
                            <DetailsName>Royalties</DetailsName>
                            <DetailsValue>2.5%</DetailsValue>
                          </DetailsFlex> */}
                        </InnerDetails>
                      </Accordion>
                    )}
                  </div>
                </ContentRightWrapper>
              </div>
            </div>
          </div>
        </div>
        {/* <LiveAuction data={liveAuctionData} /> */}
        <Footer />
      </div>
    </div>
  );
};

export default ItemDetails;
