import React, { useEffect, useState } from "react";
import GameCollectionsLeaderboard from "pages/games/GameCollectionsLeaderboard";

const GameLeaderboardPanel = ({ title, game }) => {
  return (
    <div>
      <GameCollectionsLeaderboard title="Collections Leaderboard" game={game} />
    </div>
  );
};

export default GameLeaderboardPanel;
