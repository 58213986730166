import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
// import { getContents } from "redux/state/sui";
import { hideNFT } from "redux/state/user";
import { ListGrid } from "utils/styled";

import OwnedNFTCard from "components/cards/OwnedNFTCard";
import NFTSkeleton from "components/cards/nfts/NFTSkeleton";

const MyOwnedNFTsList = ({
  setCount,
  setPages,
  setSellModalShow,
  setLoanModalShow,
  setNftDetailsModalShow,
  showHiddenNFTs,
}) => {
  const loading = useSelector((state) => state.search.loading);
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const hiddenNFTs = user.hidden_nfts;
  const contents = showHiddenNFTs
    ? web3?.nft_contents?.data
    : web3?.nft_contents?.data.filter((item) => !hiddenNFTs?.includes(item.id));

  // const pageSize = web3?.nft_contents?.perPage;
  // const page = params.get("p") || 1;

  useEffect(() => {
    setCount(`${web3?.nft_contents?.total}`);
    setPages(web3?.nft_contents?.total / web3?.nft_contents?.perPage);
  }, [contents]);

  const sellHandler = (nft) => {
    setSellModalShow(nft);
  };

  const loanHandler = (nft) => {
    setLoanModalShow(nft);
  };

  const nftDetailsHandler = (nft) => {
    setNftDetailsModalShow(nft);
  };

  const hideNFTHandler = (nft) => {
    dispatch(hideNFT(nft.id, user.hidden_nfts?.includes(nft.id)));
  };

  return (
    <>
      <ListGrid>
        {loading
          ? Array.from({ length: 15 }, (_, index) => {
              return <NFTSkeleton key={index} />;
            })
          : contents?.map((item) => (
              <OwnedNFTCard
                key={item._id}
                item={item}
                sellHandler={sellHandler}
                loanHandler={loanHandler}
                nftDetailsHandler={nftDetailsHandler}
                hideNFTHandler={hideNFTHandler}
              />
            ))}
      </ListGrid>
    </>
  );
};

export default MyOwnedNFTsList;
