import React, { useState } from "react";

import Header from "components/header/Header";
import ExploreTabs from "components/layouts/explore/ExploreTabs";
import SideBar from "components/layouts/SideBar";
import { SearchProvider } from "components/utils/SearchProvider";
import { SidebarProvider } from "components/utils/SidebarProvider";

const Explore = () => {
  return (
    <div className="home-8">
      <Header />
      <section className="tf-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <SidebarProvider>
              <SearchProvider>
                <SideBar />
                <ExploreTabs />
              </SearchProvider>
            </SidebarProvider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Explore;
