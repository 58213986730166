import { getSettings } from "utils/api";

export const SETTINGS = "SETTINGS";

export const fetchSettings = () => async (dispatch) => {
  let newSettings = await getSettings().then((res) => res.data);

  dispatch({
    type: SETTINGS,
    data: newSettings,
  });
  return newSettings;
};

const INIT_STATE = {
  market: {},
  payment: {},
  tags: [],
  ready: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTINGS:
      state.ready = true;
      return { ...state, ...action.data };
    default:
      return state;
  }
};
export default reducer;
