import { useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { MetaportProvider } from "@skalenetwork/metaport";
import config from "assets/data/tokenInfo";
import Routing from "pages/Routing";
import useAppDataFetcher from "redux/hooks/useAppDataFetcher";
import { PersistGate } from "redux-persist/integration/react";
import { getBearerToken } from "utils/api";

import MetaportModal from "components/layouts/modal/MetaportModal";
import NetworkModal from "components/layouts/modal/NetworkModal";
import { BannerProvider } from "components/utils/BannerProvider";
import FullScreenLoading from "components/utils/FullScreenLoading";
import { TitleProvider } from "components/utils/TitleProvider";

import { persistor } from "./redux";
import ScrollToTop from "./ScrollToTop";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.loading);

  useAppDataFetcher();

  useEffect(() => {
    if (!loading.fullScreen) {
      // handleLogin();
    }
  }, [loading.fullScreen]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer theme="colored" />
      <TitleProvider>
        <BannerProvider>
          <MetaportProvider config={config}>
            <SkeletonTheme
              baseColor="var(--primary-color11)"
              highlightColor="var(--primary-color11d)"
            >
              <ScrollToTop />
              {!user._id && getBearerToken() ? null : <Routing />}
              <FullScreenLoading />
            </SkeletonTheme>
            <MetaportModal />
            <NetworkModal />
            <div id="metaport"></div>
          </MetaportProvider>
        </BannerProvider>
      </TitleProvider>
    </PersistGate>
  );
}

export default App;
