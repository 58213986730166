import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ipfsConvert } from "utils/formats.js";

import WishlistButton from "components/button/WishlistButton";
import LazyLoadImage from "components/utils/LazyLoadImage";

const NFTCard = ({ item, setModalShow, alwaysShowButton }) => {
  const { _id, owner, collection } = item;
  const user = useSelector((state) => state.user);
  const isOwner = user.account_address === owner;

  const handleModalShow = (item) => {
    setModalShow(item);
  };

  const img = item.thumb || ipfsConvert(item.image);
  const to = `/nft-details/${_id}`;

  return (
    <div className="sc-card-product">
      <div className={`card-media${alwaysShowButton ? ` active` : ``}`}>
        <Link to={to}>
          {img && img?.split(".").pop() === "mp4" ? (
            <video className="image-skeleton" style={{ zIndex: 1 }} autoPlay muted loop>
              <source src={ipfsConvert(item.image)} type="video/mp4" />
            </video>
          ) : (
            <LazyLoadImage src={img} className="image-skeleton" />
          )}
        </Link>

        <WishlistButton nft={item} />
      </div>
      <div className="card-details">
        <div className="card-title flex justify-content-between">
          <div className="flex flex-column" /*style={{ maxWidth: "60%" }}*/>
            <Link to={to}>
              <h3 className="nft-text text-truncate">{item?.name}</h3>
            </Link>
            {/* <Link to={`/collection-details/${collection?._id}`}>
              <h5 className="nft-text text-truncate">{collection?.name}</h5>
            </Link> */}
          </div>
        </div>
        {/* {setModalShow && isOwner && (
          <div className="button-place-bid">
            <button
              onClick={() => handleModalShow(item)}
              data-toggle="modal"
              data-target="#popup_bid"
              className="sc-button style-place-bid fl-button pri-3 fullWidth"
            >
              <span>Sell</span>
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default NFTCard;
