import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateHiddenNFTsVisibility } from "redux/state/Profile/actions";

export const useHiddenNFTsVisibility = () => {
  const showHiddenNFTs = useSelector((state) => state.Profile.showHiddenNFTs);
  return showHiddenNFTs;
};

export const useNFTsVisibilityManager = () => {
  const dispatch = useDispatch();
  const showHiddenNFTs = useHiddenNFTsVisibility();

  const setHiddenNFTsVisibility = useCallback(
    (showHiddenNFTs) => {
      dispatch(updateHiddenNFTsVisibility({ showHiddenNFTs }));
    },
    [dispatch]
  );

  return [showHiddenNFTs, setHiddenNFTsVisibility];
};
