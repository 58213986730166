import { useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { useDispatch, useSelector } from "react-redux";
import img1 from "assets/images/box-item/card-item-8.jpg";
import { Field, formValueSelector, getFormValues } from "redux-form";
import styled from "styled-components";
import { handleCheckbox, renderFormV2 } from "utils/form";

const FieldSelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  border: ${(props) =>
    props.checked
      ? "2px solid var(--primary-color3)"
      : "2px solid var(--primary-color7)"};

  :hover {
    background: var(--hover-color);
  }
`;

const OptionsFilter = styled.input`
  background-color: #1f1f2c;
  border-color: #1f1f2c;
  margin-top: 1.5rem;
  height: 36px;
  border-radius: 20px !important;
`;

const AccordionContent = styled.div`
  padding: 0;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
`;

const FlexRowBetween = styled(FlexRow)`
  justify-content: space-between;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media screen and (min-width: 992px) {
    grid-template-columns: ${(props) =>
      props.fullwidth ? "100%" : "repeat(3, minmax(0, 1fr))"};
  }
`;

const LogoImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: var(--border-radius-md);
`;

const MultiSelectWithSearch = ({ item, formName, onChangeSearch, image, fullwidth }) => {
  const [optionsFilter, setOptionsFilter] = useState("");
  const { title, show, name, Tooltip, values } = item;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const selector = formValueSelector(formName);
  const formValues = useSelector((state) => getFormValues(formName)(state));
  const selectedOptions = formValues?.[name] || [];

  const regex = new RegExp(`^.*${optionsFilter}.*$`, "i");
  let hideMatchingValues = values?.filter((val) => {
    return !selectedOptions.find((a) => {
      if (!a) {
        return false;
      }
      if (a._id) {
        return a._id == val._id;
      }
      return a.name == val.name;
    });
  });
  const filteredValues = selectedOptions
    .concat(hideMatchingValues || [])
    .filter((val) => {
      const value = typeof val === "string" ? val : val?.name;
      return regex.test(value);
    });
  const accordionTitle = Tooltip ? (
    <>
      {title} <Tooltip />
    </>
  ) : (
    title
  );

  return (
    <div className="gameselect multiselect widget widget-category default field-container">
      <Accordion title={accordionTitle} show={show}>
        <AccordionContent>
          <OptionsFilter
            type="text"
            placeholder="Search"
            onChange={(e) =>
              onChangeSearch
                ? onChangeSearch(e.target.value)
                : setOptionsFilter(e.target.value)
            }
          />
          <OptionsWrapper fullwidth={fullwidth}>
            {filteredValues?.map((val, index) => {
              if (!val) {
                return false;
              }
              let label;
              let inputval = val;
              let img = false;
              if (typeof val === "object") {
                label = val.address ? `${val.name} (${val.address})` : val.name;
                inputval = val._id || val.name;
                img = val.image || val.logo_image;
              }
              if (image && !img) {
                img = img1;
              }
              return (
                <FieldSelectBox
                  key={inputval}
                  onClick={() => {
                    if (index < selectedOptions.length) {
                      handleCheckbox(
                        item,
                        val,
                        index,
                        state,
                        selector,
                        formName,
                        dispatch
                      );
                    } else {
                      handleCheckbox(
                        item,
                        val,
                        selectedOptions.length,
                        state,
                        selector,
                        formName,
                        dispatch
                      );
                    }
                  }}
                  checked={selector(state, `${name}.${index}`)}
                >
                  <FlexRowBetween>
                    <FlexRow>
                      {image && <LogoImage src={img} />}
                      <label>
                        <span className="capitalize">{label || inputval}</span>
                      </label>
                    </FlexRow>
                    <span className="pst-re">
                      <Field
                        key={inputval}
                        id={`${name}.${inputval}`}
                        name={`${name}.${index}`}
                        type="checkbox"
                        containername="hidden"
                        component={renderFormV2}
                      />
                      <span
                        className={`${
                          selector(state, `${name}.${index}`) ? "checked " : ""
                        }btn-checkbox`}
                      />
                    </span>
                  </FlexRowBetween>
                </FieldSelectBox>
              );
            })}
          </OptionsWrapper>
        </AccordionContent>
      </Accordion>
    </div>
  );
};

export default MultiSelectWithSearch;
