import React, { useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { change, Field, formValueSelector, reduxForm, reset } from "redux-form";
import styled from "styled-components";
import { renderFormV2 } from "utils/form";
import { useWindowSize } from "utils/hooks";

import { useSidebar } from "components/utils/SidebarProvider";

const FieldSelectBox = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  border: ${(props) =>
    props.checked
      ? "2px solid var(--primary-color3)"
      : "2px solid var(--primary-color7)"};

  :hover {
    background: var(--hover-color);
  }
`;

const OptionsFilter = styled.input`
  background-color: #1f1f2c;
  border-color: #1f1f2c;
  margin: 1.5rem 0;
  height: 36px;
  border-radius: 20px !important;
`;

const AccordionContent = styled.div`
  padding: 0 2rem 1rem;
  padding-bottom: 30px;
`;

const FilterButton = styled.button`
  & {
    background-color: transparent;
    color: var(--primary-color2);
    border: none;
    border-radius: 0;
    width: 100%;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: unset;
  }

  :focus,
  :hover {
    background: var(--hover-color);
    border: none;
  }
`;

const FilterIcon = styled.i`
  font-size: 14px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 1rem;
`;

const ItemCountText = styled.span`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
`;

const ItemCountTextSecondary = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-color9);
  letter-spacing: 0.75px;
`;

const ItemCountPercentage = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--primary-color9);
`;

export const formName = "sidebar-search";

const Option = ({ item, state, selector, handleCheckbox, handleNumberField }) => {
  if (item.content.type === "select") {
    const [optionsFilter, setOptionsFilter] = useState("");
    const regex = new RegExp(`^.*${optionsFilter}.*$`, "i");
    const total = item.total || null;
    const filteredValues = item?.content?.values?.filter((val) => {
      const value = typeof val === "string" ? val : val?.name;
      return regex.test(value);
    });
    if (!filteredValues || filteredValues?.length === 0) return null;
    return (
      <div className="multiselect widget widget-category default border-bt">
        <Accordion
          title={item.title}
          show={selector(state, `${item.name}`) ? true : item.show}
        >
          <AccordionContent>
            <OptionsFilter
              type="text"
              placeholder="Search"
              onChange={(e) => setOptionsFilter(e.target.value)}
            />
            {filteredValues?.map((val) => {
              let label;
              let count;
              let countPercentage;
              if (typeof val === "object") {
                label = val.name;
                count = val.count;
                val = val._id || val.name;
              }
              if (total > 0) countPercentage = ((count / total) * 100).toFixed(2);
              return (
                <FieldSelectBox
                  key={val}
                  onClick={() => handleCheckbox(item, val)}
                  checked={selector(state, `${item.name}.value.${val}`)}
                >
                  <FlexRow>
                    <span className="pst-re">
                      <Field
                        type="checkbox"
                        containername="hidden"
                        name={`${item.name}.value.${val}`}
                        component={renderFormV2}
                      />
                      <span
                        className={`${
                          selector(state, `${item.name}.value.${val}`) ? "checked " : ""
                        }btn-checkbox`}
                      />
                    </span>
                    <label>
                      <span>{label || val}</span>
                    </label>
                  </FlexRow>
                  {count && (
                    <FlexRow>
                      <div>
                        <ItemCountText>{count}</ItemCountText>
                        <ItemCountTextSecondary>/{total || "~"}</ItemCountTextSecondary>
                        <ItemCountPercentage>
                          {" "}
                          ({countPercentage || "~"}%)
                        </ItemCountPercentage>
                      </div>
                    </FlexRow>
                  )}
                </FieldSelectBox>
              );
            })}
          </AccordionContent>
        </Accordion>
      </div>
    );
  } else if (item.content.type === "number") {
    return (
      <div className="widget widget-category border-bt">
        <Accordion title={item.title} show={item.show}>
          <AccordionContent>
            <div className="flex priceFieldWrapper mt-4 mb-5">
              <Field
                className="priceField"
                type="number"
                nocontainer
                placeholder="Min"
                name={`${item.name}.gte`}
                component={renderFormV2}
                onChange={(e, newValue) => handleNumberField(item, newValue, "gte")}
              />
              <span className="priceTo">to</span>
              <Field
                className="priceField"
                type="number"
                nocontainer
                placeholder="Max"
                name={`${item.name}.lte`}
                component={renderFormV2}
                onChange={(e, newValue) => handleNumberField(item, newValue, "lte")}
              />
            </div>
          </AccordionContent>
        </Accordion>
      </div>
    );
  } else {
    return null;
  }
};

const SideBar = () => {
  const screenWidth = useWindowSize().width;
  const { showFilter, setShowFilter, sidebarData } = useSidebar();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const selector = formValueSelector(formName);
  const [searchParams, setSearchParams] = useSearchParams();
  const { link } = useParams();
  const params = useMemo(() => {
    const newParams = {};
    searchParams.forEach(
      (value, key) => {
        try {
          newParams[key] = JSON.parse(value);
        } catch {}
      },
      [searchParams]
    );
    return newParams;
  });

  useEffect(() => {
    Object.keys(params).length > 0
      ? Object.keys(params).forEach((subcategory) => {
          if (subcategory && Object.keys(params[subcategory]).length > 0) {
            for (const [key, values] of Object.entries(params[subcategory])) {
              if (Array.isArray(values)) {
                values.map((attribute) => {
                  const field = `${subcategory}.${key}.value.${attribute}`;
                  dispatch(change(formName, field, true));
                });
              } else if (typeof values === "object" && Object.keys(values).length > 0) {
                Object.keys(values).forEach((numberKey) => {
                  const field = `${subcategory}.${key}.${numberKey}`;
                  dispatch(change(formName, field, values[numberKey]));
                });
              } else if (typeof values === "number") {
                const field = `${subcategory}.${key}`;
                dispatch(change(formName, field, values));
              }
            }
          }
        })
      : dispatch(reset(formName));
  }, [link, searchParams]);

  const onSelect = (field) => {
    const val = selector(state, field);
    const changeVal = val ? undefined : true;
    dispatch(change(formName, field, changeVal));
  };

  const setJSONParams = (mainKey, values) => {
    searchParams.delete("p");
    if (values) {
      searchParams.set(mainKey, JSON.stringify(values));
    } else {
      searchParams.delete(mainKey);
    }
  };

  const handleNumberField = (field, value, subField) => {
    const fieldName = field.name;
    const mainKey = fieldName.split(".")[0];
    const subKey = fieldName.split(".")[1];
    const keyExists = searchParams.get(mainKey);
    if (keyExists) {
      let parsedParams = {};
      try {
        parsedParams = JSON.parse(keyExists);
      } catch (e) {
        console.log("Error parsing url params: ", e);
      }
      if (!subKey) {
        if (parsedParams[subField] && !value) {
          //Remove empty subfield
          delete parsedParams[subField];
          if (Object.keys(parsedParams).length === 0) {
            setJSONParams(mainKey, null);
          } else {
            setJSONParams(mainKey, parsedParams);
          }
        } else {
          // create new subfield
          parsedParams[subField] = value;
          setJSONParams(mainKey, parsedParams);
        }
        setSearchParams(searchParams);
      } else {
        if (parsedParams[subKey]) {
          if (!value) {
            //Remove empty subfield
            delete parsedParams[subKey][subField];
            if (Object.keys(parsedParams[subKey]).length === 0) {
              delete parsedParams[subKey];
            }

            if (Object.keys(parsedParams).length === 0) {
              setJSONParams(mainKey, null);
            } else {
              setJSONParams(mainKey, parsedParams);
            }
          } else {
            // create new subfield
            parsedParams[subKey][subField] = value;
            setJSONParams(mainKey, parsedParams);
          }
        } else {
          // create new subkey and subfield
          parsedParams[subKey] = {};
          parsedParams[subKey][subField] = value;
          setJSONParams(mainKey, parsedParams);
        }
        setSearchParams(searchParams);
      }
    }

    if (!keyExists) {
      if (!subKey) {
        //Has no subkeys (collection.tags, fields.dna, etc.) and therefore must be an array of objects (sale_price = [{gte:1},{lte:2}])
        const newMainKeyValues = {};
        newMainKeyValues[subField] = value;
        setJSONParams(mainKey, newMainKeyValues);
        setSearchParams(searchParams);
      } else {
        //push as an object, like how attributes works
        const newMainKeyValues = {};
        newMainKeyValues[subKey] = {};
        newMainKeyValues[subKey][subField] = value;
        setJSONParams(mainKey, newMainKeyValues);
        setSearchParams(searchParams);
      }
    }
  };

  const handleCheckbox = (field, value) => {
    const fieldName = field.name;
    const fieldSelector = `${fieldName}.value.${value}`;
    const mainKey = fieldName.split(".")[0];
    const subKey = fieldName.split(".")[1];
    onSelect(fieldSelector);

    const keyExists = searchParams.get(mainKey);
    if (keyExists) {
      //The main key already exists
      let parsedKeys = JSON.parse(keyExists);
      let subKeyValues = parsedKeys[subKey] || []; // If the subKey doesn't exist, make an empty array

      if (subKeyValues.includes(value)) {
        subKeyValues = subKeyValues.filter((item) => item !== value); // if the value exists in the array, remove it
      } else {
        subKeyValues.push(value); // Otherwise, Add the value to the subkey array
      }

      if (Object.keys(subKeyValues).length === 0) {
        delete parsedKeys[subKey]; // Delete the subkey if it's array is empty
      } else {
        parsedKeys[subKey] = subKeyValues;
      }

      if (Object.keys(parsedKeys).length === 0) {
        setJSONParams(mainKey, null);
      } else {
        setJSONParams(mainKey, parsedKeys);
      }
    } else {
      //key does not exist. Make it.
      const newMainKey = {};
      newMainKey[subKey] = [value];
      setJSONParams(mainKey, newMainKey);
    }
    setSearchParams(searchParams);
  };

  const handleFilter = () => {
    setShowFilter(false);
  };

  if (screenWidth > 767 && !showFilter) return null;
  return (
    <div
      id="side-bar"
      className={`col-box-17 side-bar style-3 item ${showFilter ? `active` : ``}`}
    >
      <FilterButton key="sidebar-filter" onClick={() => handleFilter()}>
        <h4 className="title-widget">Filter</h4>
        <FilterIcon className="fas fa-filter" />
      </FilterButton>

      <div className="divider"></div>
      <div className="wrap-category">
        {sidebarData.map((item, index) => (
          <Option
            state={state}
            selector={selector}
            handleCheckbox={handleCheckbox}
            handleNumberField={handleNumberField}
            item={item}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default reduxForm({ form: formName })(SideBar);
