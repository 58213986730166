import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Title = styled.h4`
  font-size: 26px;
  font-weight: 800;
  line-height: 30px;
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: var(--primary-color9);
  width: 100%;
`;

const GameInfoContainer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  border-radius: var(--border-radius-md);
  background: var(--primary-color);
  border: 2px solid var(--primary-color7);
  transition: all 0.3s;
  height: 280px;
  gap: 2rem;

  :hover,
  :focus {
    background: transparent;
    border: 2px solid var(--primary-color3);
    box-shadow: var(--primary-color3) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    .heading {
      color: var(--primary-color3);
    }
  }

  .content {
    font-weight: 600;
    line-height: 20px;
    color: var(--primary-color9);
  }
`;

const CardIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GameInfoCard = ({ gameInfo }) => {
  const { title, description, link, colorbg, icon } = gameInfo;
  return (
    <GameInfoContainer>
      <CardIcon className={colorbg}>
        <img src={icon} alt="" />
      </CardIcon>
      <Title className="text-truncate">
        <Link to={link}>{title}</Link>
      </Title>
      <Description>{description}</Description>
    </GameInfoContainer>
  );
};

export default GameInfoCard;
