import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Field, reduxForm } from "redux-form";
import { createListing, getMyLaunchpads } from "utils/api";
import { renderFormV2 } from "utils/form";
import { ciStringCompare, ipfsConvert } from "utils/formats";
import { tryAgain } from "utils/performance";
import { sleep } from "utils/time";
import { getERC721Approval, sellOnMarket } from "utils/web3/evm.js";

import LoadingButton from "components/button/LoadingButton";
import RequireAmountButton from "components/button/RequireAmountButton";
import Fees from "components/layouts/collections/Fees";
import ToastPopup from "components/utils/ToastPopup";
import Tooltip from "components/utils/Tooltip";

const formName = "sell-modal";

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.starts) {
      errors.starts = "Required";
    }
    if (!values.expires) {
      errors.expires = "Required";
    }
    if (values.starts >= values.expires) {
      errors.starts = "Start date cannot be after end date";
    }
    if (!values.price) {
      errors.price = "Required";
    }
    if (!values.min_bid) {
      errors.min_bid = "Required";
    }
    if (!values.min_bid_increment) {
      errors.min_bid_increment = "Required";
    }
  }
  return errors;
};

const SellModal = ({ item: nft, onHide, handleSubmit }) => {
  if (!nft) {
    return false;
  }

  const navigate = useNavigate();
  const form = useSelector((state) => state.form[formName]);
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [nftCollection, setCollection] = useState(false);

  useEffect(() => {
    if (user._id) {
      setCollection(nft.collection);
    }
  }, [nft, user]);

  const submit = async (values) => {
    try {
      let promise = false;
      setLoading(true);
      switch (tab) {
        default:
        case 0:
          const approved = await getERC721Approval(
            nft.collection.address,
            nft.token_id
          ).catch(() => "0x0");
          promise = sellOnMarket(
            settings.market.address, // marketContract,
            settings.market.index, // marketId,
            nft.collection.address, // tokenContract,
            nft.token_id, // tokenId,
            settings.payment, // paymentContract,
            values.price, // price,
            user.account_address, // from
            ciStringCompare(approved, settings.market.address)
          );
          break;
        case 1:
          // promise = makeNftAuction(nft, nftCollection, values);
          break;
        case 2:
          ToastPopup("Mintpad successfully updated.", "success");
          break;
      }
      if (promise) {
        let info = await promise;

        if (!info?.hash) {
          ToastPopup(
            "An error occurred while trying to log the transaction, the listing may take some time to show up.",
            "error"
          );
          await sleep(60000);
          onHide();
        } else {
          await sleep(6000);
        }

        const apiResponse = await tryAgain(
          () =>
            createListing(info.hash).then((res) => {
              if (!res.data?._id) {
                return Promise.reject();
              } else {
                return Promise.resolve(res);
              }
            }),
          5,
          10_000
        );

        // TODO: remove item from inventory, or set disabled in kiosk

        if (apiResponse.data?._id) {
          navigate(`/item-details/${apiResponse.data._id}`);
        } else {
          ToastPopup(
            "An error occurred while trying to list this NFT, the listing may take some time to show up.",
            "error"
          );
        }
      }
    } catch (e) {
      console.log("An error occurred while trying to sell this NFT: ", e);
      ToastPopup(
        "An error occurred while trying to sell this NFT, please try again later.",
        "error"
      );
      setLoading(false);
    }
  };

  // let hasAuctions = nftCollection.ob_enabled ? !!nftCollection.features?.auction : true;

  return (
    <Modal show={nft} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body pd-40">
        <div className="list-item">
          <div className="flat-tabs tab-list-item space-y-20 ">
            <h3>Sell {nft?.name}?</h3>
            <div className="flex justify-content-center">
              <img
                src={ipfsConvert(nft?.thumb_url || nft?.image)}
                style={{ width: "50%" }}
              />
            </div>
            {/* <CopyToClipboard
              style={{
                cursor: "pointer",
              }}
              text={nft.id}
              onCopy={() => handleCopy()}
            >
              <div>
                <i className="fal mr-3 fa-copy"></i>
                {ellipsifyString(nft.id, 16)}
              </div>
            </CopyToClipboard> */}
            <form onSubmit={handleSubmit(submit)}>
              <Tabs onSelect={setTab}>
                <TabList className="react-tabs__tab-list two-tabs">
                  <Tab>
                    <span className="icon-fl-tag" />
                    Fixed Price
                  </Tab>
                  <Tab disabled>
                    <span className="icon-fl-clock" />
                    Timed Auction
                  </Tab>
                </TabList>
                {/* {notOwnerError && <div className="form-error">{notOwnerError}</div>} */}
                {tab < 2 && (
                  <>
                    <Fees
                      price={
                        (tab == 0 ? form?.values?.price : form?.values?.min_bid) || 0
                      }
                      collection={nftCollection}
                    />
                    <hr />
                  </>
                )}
                <TabPanel>
                  <Field
                    type="number"
                    name="price"
                    placeholder="Enter price for one NFT"
                    props={{
                      // min: settings.min_bid_increment,
                      step: "any",
                    }}
                    required
                    component={renderFormV2}
                  />
                </TabPanel>
                <TabPanel>
                  <Field
                    type="number"
                    name="min_bid"
                    title="Minimum bid"
                    placeholder="Enter minimum bid"
                    props={{
                      min: settings.min_bid_increment,
                      step: "any",
                    }}
                    required
                    component={renderFormV2}
                  />
                  <Field
                    type="number"
                    name="min_bid_increment"
                    title="Minimum bid Increment"
                    placeholder="Enter minimum bid"
                    props={{
                      step: "any",
                    }}
                    required
                    appendTitle={
                      <Tooltip>
                        <p>
                          e.g. if the current bid is 1 and the bid increment is 0.1, the
                          next bid must be &ge; 1.1 . This is to avoid someone bidding
                          1.0000000000000001
                        </p>
                        <i className="fas fa-info-circle" />
                      </Tooltip>
                    }
                    component={renderFormV2}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <Field
                        type="datetime-local"
                        name="starts"
                        title="Starting date"
                        props={{ min: "2022-01-01 00:00" }}
                        required
                        component={renderFormV2}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        type="datetime-local"
                        name="expires"
                        title="Expiration date"
                        required
                        component={renderFormV2}
                      />
                    </div>
                  </div>
                </TabPanel>
              </Tabs>

              {
                /* TODO: Show error when no collection is found*/
                !nftCollection && (
                  <p className="error">Collection is not registered with Keepsake</p>
                )
              }
              {nftCollection && !nftCollection.active && (
                <p className="error">
                  Collection is not active on Keepsake. The NFT can be listed, but will
                  not show up in searches
                </p>
              )}
              <RequireAmountButton
                text="Create Listing"
                className="fullWidth mt-4"
                disabled={loading || !nftCollection}
              >
                <LoadingButton
                  loading={loading}
                  disabled={loading || !nftCollection}
                  type="submit"
                  className="fullWidth mt-4"
                >
                  Create Listing
                </LoadingButton>
              </RequireAmountButton>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
})(SellModal);
