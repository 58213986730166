import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  searchMyBags,
  searchMyCollections,
  searchMyGames,
  searchMyLaunchpads,
} from "redux/state/search";
import { findIndexByValue } from "utils/formats";

import MyCollectionsPanel from "components/layouts/profile/Panels/MyCollectionsPanel";
import MyGamesPanel from "components/layouts/profile/Panels/MyGamesPanel";
import { useProfileMenu } from "components/utils/context/ProfileMenuContext";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useTitle } from "components/utils/TitleProvider";

const MyProfileProjects = React.memo(function MyProfileProjects() {
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link } = useParams();
  const { setSearchFunction } = useRefreshSearch();
  const { menuIndex, prevMenuIndex, menuData } = useProfileMenu();
  const parentLink = "profile";

  const dataTabs = [
    {
      title: "Collections",
      link: "collections",
      panel: <MyCollectionsPanel title="Collections" />,
      searchFunction: () => searchMyCollections,
    },
    // {
    //   title: "Bags",
    //   link: "bags",
    //   panel: <MyBagsPanel title="Bags" />,
    //   searchFunction: () => searchMyBags,
    // },
    // {
    //   title: "Mintpads",
    //   link: "mintpads",
    //   panel: <MyLaunchpadsPanel title="Mintpads" />,
    //   searchFunction: () => searchMyLaunchpads,
    // },
    {
      title: "Games",
      link: "games",
      panel: <MyGamesPanel title="Games" />,
      searchFunction: () => searchMyGames,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    if (menuIndex !== prevMenuIndex && prevMenuIndex !== null) {
      navigate(`/${parentLink}/${menuData[menuIndex].section}/${dataTabs[0].link}`);
    }
  }, [menuIndex]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    navigate(`/${parentLink}/${menuData[menuIndex].section}/${link}`);
  };

  return (
    <section className="tf-section tf-new-profile">
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </section>
  );
});

export default MyProfileProjects;
