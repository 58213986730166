import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import ProfileBanner from "components/layouts/profile/ProfileBanner";
import ProfileContent from "components/layouts/profile/ProfileContent";
import ProfileSidebar from "components/layouts/profile/ProfileSidebar";
import { ProfileMenuProvider } from "components/utils/ProfileMenuProvider";
import { SearchProvider } from "components/utils/SearchProvider";
import ToastPopup from "components/utils/ToastPopup";

const MyProfile = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user._id) {
      navigate("/", "replace");
      ToastPopup("Please connect your wallet to continue", "error");
    }
  }, [user]);

  return (
    <div className="my-profile">
      <Header />
      <ProfileBanner user={user} />
      <div className="themesflat-container">
        <div className="row justify-content-center">
          <SearchProvider>
            <ProfileMenuProvider>
              <ProfileSidebar />
              <ProfileContent />
            </ProfileMenuProvider>
          </SearchProvider>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyProfile;
