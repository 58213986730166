import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  createGame,
  searchCollections,
  searchGames,
  updateGame,
  uploadGameImage,
} from "utils/api";
import { IS_PROD } from "utils/environments";
import { renderFormV2 } from "utils/form";
import { tryAgain } from "utils/performance";

import LoadingButton from "components/button/LoadingButton";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import MultiSelectAccordion from "components/inputs/MultiSelectAccordion";
import MultiSelectWithSearch from "components/inputs/MultiSelectWithSearch";
import PageHeader from "components/layouts/PageHeader";
import ToastPopup from "components/utils/ToastPopup";
import Tooltip from "components/utils/Tooltip";

const formName = "create-game";

const validate = (values) => {
  const errors = {};
  const MAX_FILE_SIZE = 10 * 1024 * 1024; //10Mb

  if (values) {
    if (!values.logo_img && IS_PROD) {
      errors.logo_img = "Required";
    }
    if (!values.banner_img && IS_PROD) {
      errors.banner_img = "Required";
    }
    if (values.logo_img?.size > MAX_FILE_SIZE) {
      errors.logo_img = "Max file size is 10MB. Please upload a smaller file.";
    }
    if (values.banner_img?.size > MAX_FILE_SIZE) {
      errors.banner_img = "Max file size is 10MB. Please upload a smaller file.";
    }
  }
  return errors;
};

const CreateGame = (props) => {
  const navigate = useNavigate();
  const tags = useSelector((state) => state.settings?.tags);
  const genres = [
    "Action",
    "Adventure",
    "Battle Royale",
    "Collectible",
    "Fighting",
    "Horror",
    "Idle",
    "MMO",
    "Puzzle",
    "P2E (Play-to-Earn)",
    "Racing",
    "RPG (Role-Playing Game)",
    "RTS (Real-Time Strategy)",
    "Strategy",
    "Simulation",
    "Sports/Fantasy Sports",
    "Shooter",
    "Sandbox",
    "Tower Defense",
    "TCGs (Trading Card Games)",
  ];
  const platforms = ["desktop", "mobile", "web"];
  const playInfo = ["singleplayer", "multiplayer", "cooperative"];
  const { handleSubmit, pristine, submitting, error } = props;
  const [loadingGame, setLoadingGame] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [collections, setCollections] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const fetchCollections = async () => {
    const req = await searchCollections({ searchName: searchVal });
    setCollections(req.data.results);
  };

  useEffect(() => {
    let timer = setTimeout(() => fetchCollections(), 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchVal]);

  // useEffect(() => {
  //   fetchCollections();
  // }, []);

  const collectionsData = {
    title: "Link Collections",
    name: "linked_collections",
    show: true,
    values: collections,
    Tooltip: () => (
      <Tooltip>
        <>
          Select all collections that are related to or interact with your game in some
          way. These collections will appear on the Game Details page and will be used to
          calculate game statistics such as total volume sold.
        </>
      </Tooltip>
    ),
  };

  const tagsData = {
    title: "Tags",
    name: "tags",
    show: true,
    values: tags,
  };

  const genresData = {
    title: "Genres",
    name: "genres",
    show: true,
    values: genres,
  };

  const platformsData = {
    title: "Platforms",
    name: "platforms",
    show: true,
    values: platforms,
  };

  const playInfoData = {
    title: "Play Info",
    name: "play_info",
    show: true,
    values: playInfo,
  };

  const submit = async (values) => {
    setLoadingGame(true);
    try {
      const nameTaken = await searchGames({ name: values.name }).then(
        (res) => res.data.results[0]
      );
      if (nameTaken) {
        throw new SubmissionError({
          name: "Name taken",
          _error: "A game with that name already exists",
        });
      }

      const { data: newGame } = await tryAgain(() => createGame(values), 3, 10_000);

      if (newGame) {
        const id = newGame.game._id;
        const token = newGame.accessToken;
        const images = {};
        if (values.banner_img) {
          await uploadGameImage(token, id, values.banner_img, "banner")
            .then((res) => (images.banner_image = res))
            .catch(() => {
              ToastPopup("An error occurred uploading Game Banner.", "error");
            });
        }
        if (values.logo_img) {
          await uploadGameImage(token, id, values.logo_img, "logo")
            .then((res) => (images.logo_image = res))
            .catch(() => {
              ToastPopup("An error occurred uploading Game Logo.", "error");
            });
        }
        if (images) await updateGame(id, images);
        navigate(`/games/${newGame.game._id}`);
      }
      navigate(`/games/${newGame.game._id}`);
      setLoadingGame(false);
    } catch (e) {
      console.log(e);
      setLoadingGame(false);
      throw new SubmissionError({
        name: e.errors?.name || "An error occured",
        _error: e.errors?._error || e.message,
      });
    }
  };
  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <div className="flat-tabs tab-list-item">
                  <form onSubmit={handleSubmit(submit)}>
                    <Field
                      type="file"
                      name="banner_img"
                      title="Banner Image"
                      className="hideInput"
                      labelClassName="banner"
                      imgClassName="inheritHeight"
                      featuredImage={bannerImage}
                      component={renderFormV2}
                      containername="required"
                      accept=".png,.jpg,.jpeg,.gif,.mp4"
                      onChange={(e) => {
                        if (e) {
                          setBannerImage(e);
                        }
                      }}
                    />
                    <Field
                      type="file"
                      name="logo_img"
                      title="Logo Image"
                      className="hideInput"
                      labelClassName="square"
                      imgClassName="inheritHeight"
                      featuredImage={logoImage}
                      component={renderFormV2}
                      containername="required"
                      accept=".png,.jpg,.jpeg,.gif"
                      onChange={(e) => {
                        if (e) {
                          setLogoImage(e);
                        }
                      }}
                    />
                    <MultiSelectWithSearch
                      item={collectionsData}
                      onChangeSearch={setSearchVal}
                      formName={formName}
                      image={true}
                      fullwidth={true}
                    />
                    <Field
                      name="media_url"
                      title="Featured Media URL"
                      type="text"
                      placeholder="This can be a Youtube Video URL, an mp4, or an image."
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="name"
                      title="Game Title"
                      type="text"
                      placeholder="e.g. NFT Fighters"
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="description"
                      type="textarea"
                      placeholder='e.g. "This is my game!"'
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="developer"
                      type="text"
                      placeholder='e.g. "Bamboo Games"'
                      component={renderFormV2}
                      required
                    />
                    <MultiSelectAccordion item={platformsData} formName={formName} />
                    <MultiSelectAccordion item={genresData} formName={formName} />
                    <MultiSelectAccordion item={playInfoData} formName={formName} />
                    <Field
                      name="twitter"
                      type="text"
                      placeholder="https://twitter.com/"
                      component={renderFormV2}
                    />
                    <Field
                      name="discord"
                      type="text"
                      placeholder="https://discord.gg/"
                      component={renderFormV2}
                    />
                    <Field name="website" type="text" component={renderFormV2} />
                    <Field
                      title="Game URL (steam, etc.)"
                      name="game_url"
                      type="text"
                      component={renderFormV2}
                    />
                    <MultiSelectAccordion item={tagsData} formName={formName} />
                    <p className="error">{error?.toString()}</p>
                    <LoadingButton
                      type="submit"
                      loading={loadingGame}
                      disabled={pristine || loadingGame || submitting}
                    >
                      Create Game
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default reduxForm({
  form: formName,
  validate,
})(CreateGame);
