//
import axios from "axios";
import { IS_PROD } from "utils/environments";

import ToastPopup from "components/utils/ToastPopup";

import { removeTrailing } from "./formats";

let instance = false;

export const setBearerToken = (newToken) => window.localStorage.setItem("jwt", newToken);
export const getBearerToken = () => window.localStorage.getItem("jwt") || false;
export const clearBearerToken = () => window.localStorage.removeItem("jwt");

export const baseURL = removeTrailing(process.env.REACT_APP_API);
export const imgserverURL = removeTrailing(process.env.REACT_APP_IMGSERVER);
const localImageServer = baseURL == imgserverURL;
export const imgserverImageURL = localImageServer ? imgserverURL + "/img" : imgserverURL;

const ImageServerInstance = (token) => {
  let completeBearerToken = null;
  const localToken = getBearerToken();
  if (token && !localImageServer) {
    completeBearerToken = `Bearer ${token}`;
  } else if (localToken) {
    completeBearerToken = `Bearer ${localToken}`;
  }

  return axios.create({
    baseURL: imgserverURL,
    headers: {
      common: {
        Authorization: completeBearerToken,
      },
    },
  });
};

export const refreshInstance = () => {
  let completeBearerToken = null;
  const localToken = getBearerToken();
  if (localToken) {
    completeBearerToken = `Bearer ${localToken}`;
  }

  instance = axios.create({
    baseURL: baseURL,
    headers: {
      common: {
        Authorization: completeBearerToken,
      },
    },
  });

  // response interceptor to catch any uncaught async errors and display them as a Toast message
  instance.interceptors.response.use(undefined, (err) => {
    if (IS_PROD) return; //Don't show uncaught errors on production
    if (err.response) {
      const status = err.response.status || null;
      const message =
        typeof err.response.data === "object"
          ? err.response.data.message
          : err.response.data;
      ToastPopup(`${status}: ${message}`, "error");
    } else {
      ToastPopup("An unknown error has occurred", "error");
    }

    return Promise.reject(err);
  });
};
refreshInstance();

const webv1 = "/web/v1";
const nftURI = webv1 + "/nfts";
//const coinURI = webv1 + "/coins";
const subscribersURI = webv1 + "/subscribers";
const imagesURI = webv1 + "/images";
const gamesURI = webv1 + "/games";

// Users
const usersURI = webv1 + "/users";
export const getSignIn = () => instance.get(usersURI + "/sign_up");
export const signIn = (data) => instance.post(usersURI + "/sign_up", data);
export const getCurrentUser = () => instance.get(usersURI + "/current");
export const updateUser = (user) => instance.patch(usersURI + "/updateUser", user);
export const toggleFollowUser = (id) => instance.get(usersURI + `/follow/${id}`);
export const getAllCreators = () => instance.get(usersURI + `/creators`);
export const getCreator = (id) => instance.get(usersURI + `/creator/${id}`);
export const searchCreators = (params) => instance.get(usersURI + "/search", { params });
export const asyncValidateDuplicateUserField = (params) =>
  instance.get(usersURI + "/validate", { params });
export const grantUserLaunchpadPermissions = (params) =>
  instance.post(usersURI + "/launchpadPermissions", { params });
export const toggleHiddenNFT = (id) => instance.get(usersURI + `/hideNFT/${id}`);

// Settings
export const getSettings = () => instance.get(webv1 + "/services/settings");

// Blockchain stuff
export const getsFUEL = () => instance.get(webv1 + "/services/sfuel");

// Listings
const listingsURI = webv1 + "/listings";
export const searchListings = (params) =>
  instance.get(listingsURI + "/search", { params });
export const myListings = (params) => instance.get(listingsURI + "/my", { params });
export const myAuctions = (params) =>
  instance.get(listingsURI + "/my-auctions", { params });
export const myWonAuctions = (params) =>
  instance.get(listingsURI + "/my-won-auctions", { params });
export const myBids = (params) => instance.get(listingsURI + "/my-bids", { params });
export const getRandom = () => instance.get(listingsURI + "/random");
export const createListing = (tx) => instance.get(`${listingsURI}/create/${tx}`);
export const getListing = (id) => instance.get(listingsURI + `/id/${id}`);
export const buyListing = (id, coins) =>
  instance.post(listingsURI + `/buy/${id}`, { coins });
export const updateListing = (id, newPrice) =>
  instance.put(listingsURI + `/id/${id}`, { newPrice });
export const toggleFavoriteNFT = (id) => instance.get(usersURI + `/favoriteNFTs/${id}`);
export const getFavoriteNFTs = (params) =>
  instance.get(usersURI + `/favoriteNFTs`, { params });

// Loaning
const loanListingsURI = webv1 + "/lendings";
export const searchLoanListings = (params) =>
  instance.get(`${loanListingsURI}/search`, { params });
export const getLoanListing = (id) => instance.get(loanListingsURI + `/id/${id}`);
export const updateLoanListing = (id) => instance.put(loanListingsURI + `/id/${id}`);
export const createLoanListing = (tx) => instance.get(`${loanListingsURI}/create/${tx}`);
export const myRentalListings = (params) =>
  instance.get(loanListingsURI + "/my-rental-listings", { params });
export const myLoanListings = (params) =>
  instance.get(loanListingsURI + "/my-loan-listings", { params });

// NFTs
export const searchNFTs = (data) => instance.post(nftURI + "/search", data);
export const getNFT = (id) => instance.get(`${nftURI}/id/${id}`);
export const locateNFT = (address, id) =>
  instance.get(`${nftURI}/address/${address}/id/${id}`);
export const myNFTs = (page) => instance.get(`${nftURI}/my/page/${page}`);
export const createNFT = (data) => instance.post(nftURI, data);
export const announceNFTs = (tx_id) => instance.put(nftURI + `/create/${tx_id}`);
export const getCollectionAttributes = (id) => instance.get(nftURI + `/attributes/${id}`);

// Collections
const collectionsURI = webv1 + "/collections";
export const searchCollections = (params) =>
  instance.get(collectionsURI + "/search", { params });
export const getCollection = (id) => instance.get(collectionsURI + `/id/${id}`);
export const getCollectionFromAddress = (type) =>
  instance.get(collectionsURI + `/address/${type}`);
export const logCollection = (data) =>
  instance.post(collectionsURI + "/halfRecord", data);
export const getCollectionLog = (id) =>
  instance.get(collectionsURI + `/halfRecord/${id}`);
export const createCollection = (data) => instance.post(collectionsURI + `/create`, data);
export const addCollection = (data) => instance.post(collectionsURI + `/add`, data);
export const addOriginByeSupport = (id, data) =>
  instance.post(collectionsURI + `/addOriginByeSupport/${id}`, data);
export const addKioskSupport = (id, data) =>
  instance.post(collectionsURI + `/addKioskSupport/${id}`, data);
export const addKeepsakeSupport = (id, txDigest) =>
  instance.post(collectionsURI + `/addKeepsakeSupport/${id}`, { txDigest });
export const updateCollection = (id, data) =>
  instance.put(collectionsURI + `/id/${id}`, data);
export const updateCollectionVersion = (id) =>
  instance.get(collectionsURI + `/version/${id}`);
export const getMyCollection = (id) =>
  instance.get(collectionsURI + `/myCollection/${id}`);
export const getMyCollections = (params) =>
  instance.get(collectionsURI + `/myCollections`, { params });
export const getAllCollections = (params) =>
  instance.get(collectionsURI + `/allCollections`, { params });
export const getTopCollections = () => instance.get(collectionsURI + `/topCollections`);
export const toggleFavoriteCollection = (id) =>
  instance.get(usersURI + `/favoriteCollections/${id}`);
export const getFavoriteCollections = (page, pageSize) =>
  instance.get(usersURI + `/favoriteCollections`, { params: { pageSize, page } });
export const getMyBags = (params) => instance.get(collectionsURI + `/myBags`, { params });

// Launchpads
const launchpadsURI = webv1 + "/launchpads";
export const searchLaunchpads = (params) =>
  instance.get(launchpadsURI + "/search", { params });
export const getLaunchpad = (id) => instance.get(launchpadsURI + `/id/${id}`);
export const getLaunchpadsForCollection = (id) =>
  instance.get(launchpadsURI + `/collection/${id}`);
export const getMyLaunchpads = (params) =>
  instance.get(launchpadsURI + `/myLaunchpads`, { params });
export const getAllLaunchpads = (params) =>
  instance.get(launchpadsURI + `/allLaunchpads`, { params });
export const createLaunchpad = (data) => instance.post(launchpadsURI + `/create`, data);
export const updateLaunchpad = (id, data) =>
  instance.put(launchpadsURI + `/id/${id}`, data);

// Subscribers
export const emailSubscribe = (email) =>
  instance.post(subscribersURI + "/add", { email });

// Games
export const searchGames = (params) => instance.get(gamesURI + "/search", { params });
export const createGame = (data) => instance.post(gamesURI + `/create`, data);
export const updateGame = (id, data) => instance.put(gamesURI + `/id/${id}`, data);
export const getGame = (id) => instance.get(gamesURI + `/id/${id}`);
export const myGames = (params) => instance.get(gamesURI + `/myGames`, { params });
export const myGame = (id) => instance.get(gamesURI + `/myGame/${id}`);
export const getAllGames = (params) => instance.get(gamesURI + `/allGames`, { params });
export const getAllGameCollections = () => instance.get(gamesURI + `/allCollections`);
export const getCollectionsForGame = (id) =>
  instance.get(gamesURI + `/collections/${id}`);

// Features
const featuresURI = webv1 + "/features";
export const setFeature = (name, id) => instance.get(`${featuresURI}/${name}/set/${id}`);
export const getFeature = (name, limit) =>
  instance.post(`${featuresURI}/${name}`, { params: { limit } });

// Image Server API
export const uploadCollectionImage = async (
  token,
  collection_id,
  file,
  imageType = "featured" /*|| "logo"*/
) => {
  const data = new FormData();
  data.append("file", file, file.name);
  const uploadedImage = await ImageServerInstance(token).post(
    imagesURI + `/upload/keepsake/collections/${collection_id}/${imageType}`,
    data,
    {
      imageType,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }
  );
  const filename = uploadedImage.data.data.filename;
  return imgserverImageURL + `/uploads/keepsake/collections/${collection_id}/${filename}`;
};
export const uploadNFTImage = async (token, collection_id, nft_id, file) => {
  const data = new FormData();
  data.append("file", file, file.name);
  const uploadedImage = await ImageServerInstance(token).post(
    imagesURI + `/upload/keepsake/collections/${collection_id}/nft/${nft_id}`,
    data,
    {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }
  );
  const filename = uploadedImage.data.data.filename;
  return imgserverImageURL + `/uploads/keepsake/collections/${collection_id}/${filename}`;
};

export const uploadUserImage = async (
  token,
  profile_id,
  file,
  imageType = "avatar" /*||"banner"*/
) => {
  const data = new FormData();
  data.append("file", file, file.name);
  const uploadedImage = await ImageServerInstance(token).post(
    imagesURI + `/upload/keepsake/profiles/${profile_id}/${imageType}`,
    data,
    {
      imageType,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }
  );
  const filename = uploadedImage.data.data.filename;
  return imgserverImageURL + `/uploads/keepsake/profiles/${profile_id}/${filename}`;
};

export const uploadGameImage = async (
  token,
  game_id,
  file,
  imageType = "banner" /*||"cover"*/
) => {
  const data = new FormData();
  data.append("file", file, file.name);
  const uploadedImage = await ImageServerInstance(token).post(
    imagesURI + `/upload/keepsake/games/${game_id}/${imageType}`,
    data,
    {
      imageType,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    }
  );
  const filename = uploadedImage.data.data.filename;
  return imgserverImageURL + `/uploads/keepsake/games/${game_id}/${filename}`;
};
