import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchGames } from "redux/state/search";

import GameCard from "components/cards/GameCard";

import GameSkeleton from "./GameSkeleton";

const PopularGames = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.search.games.results);
  const [visible, setVisible] = useState(10);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      searchGames({
        sortParams: { favorites: -1 },
      })
    ).then(() => {
      setTimeout(() => setLoading(false), 300);
    });
  }, []);

  return (
    <section className="tf-section popular-games">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-22 text-left">Popular Games</h2>
              <Link to="/games">
                <div className="border-button">
                  <span className="exp">VIEW ALL</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {loading
                ? Array.from({ length: visible }, (_, index) => {
                    return (
                      <div key={index} className="five-col">
                        <GameSkeleton />
                      </div>
                    );
                  })
                : data.slice(0, visible).map((game) => (
                    <div key={game._id} className="five-col">
                      <GameCard item={game} />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularGames;
