import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { numberShortFormat } from "utils/formats";

import LazyLoadImage from "components/utils/LazyLoadImage";
import VerifiedBadge from "components/utils/VerifiedBadge";

const CollectionRanking = ({ item, rank }) => {
  const settings = useSelector((state) => state.settings);
  const { _id, name, volume, percent_change, logo_image, floor, verified } = item;
  return (
    <div className="center-margin flex top-collections-row">
      <div className="mr-2 flex flex-column collection-rank-container">
        <div className="collection-rank">{rank + 1}</div>
      </div>
      <div className="collection-logo-container">
        {_id ? (
          <Link to={`/collection-details/${_id}`}>
            <LazyLoadImage
              src={logo_image} // TODO: Reimplement /resize/120x120/
              className="collection-logo-round"
              circle="true"
              isIcon
            />
          </Link>
        ) : (
          <LazyLoadImage
            src={logo_image} // TODO: Reimplement /resize/120x120/
            className="collection-logo-round"
            circle="true"
          />
        )}
        <VerifiedBadge verified={verified} />
      </div>
      <div className="flex flex-column text-container">
        <div className="flex fullWidth">
          <div className="collection-text collection-name">
            {name ? <Link to={`/collection-details/${_id}`}>{name}</Link> : "~"}
          </div>
        </div>
        <div className="flex fullWidth">
          <div className="collection-text collection-floor">
            Floor: {floor ? numberShortFormat(floor) : 0} {settings.payment.symbol}
          </div>
        </div>
      </div>
      <div className="flex flex-column collection-volume-container">
        <div className="flex collection-volume">
          {volume ? numberShortFormat(volume) : 0} {settings.payment.symbol}
        </div>
        <div className="flex collection-volume">
          {percent_change !== undefined && percent_change !== 0 ? (
            percent_change < 0 ? (
              <span className="volume-percent-negative">{percent_change}%</span>
            ) : (
              <span className="volume-percent-positive">+{percent_change}%</span>
            )
          ) : (
            <span>~</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionRanking;
