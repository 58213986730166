import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GameSkeleton = () => {
  return (
    <div className="sc-card-collection collection-card">
      <Skeleton height="200px" />
      <div className="card-bottom" style={{ height: "120px" }}>
        <div className="author collection-details">
          <div className="content">
            <p>
              <Skeleton height="100%" width="110px" />
              <Skeleton height="100%" width="110px" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameSkeleton;
