import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseEther } from "ethers";
import { getsFuel } from "redux/state/web3";

import LoadingButton from "./LoadingButton";
import RequireConnectedButton from "./RequireConnectedButton";

const RequireAmountButton = ({ text, amount, disabled, className, children }) => {
  const dispatch = useDispatch();
  const { balance, gasBalance } = useSelector((state) => state.web3);
  const name = useSelector((state) => state.settings?.payment?.name || "");
  const [loading, setLoading] = useState(false);

  const getsFUEL = async () => {
    setLoading(true);
    try {
      await dispatch(getsFuel());
    } finally {
      setLoading(false);
    }
  };

  const inner = () => {
    if (BigInt(gasBalance) < parseEther("0.000001")) {
      return (
        <LoadingButton loading={loading} onClick={getsFUEL} className={className}>
          Get sFUEL
        </LoadingButton>
      );
    } else if (BigInt(balance || 0) < BigInt(amount || 0)) {
      return (
        <button disabled={disabled} className={className}>
          Not enough {name}
        </button>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <RequireConnectedButton className={className} disabled={disabled} text={text}>
      {inner()}
    </RequireConnectedButton>
  );
};

export default RequireAmountButton;
