import React from "react";

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

export const FileInput = ({
  input: { onChange, onBlur, ...inputProps },
  meta: omitMeta,
  featuredImage,
  imgClassName = "",
  labelClassName = "",
  hint,
  ...props
}) => {
  const displayImage = inputProps.value
    ? URL.createObjectURL(inputProps.value)
    : featuredImage;

  return (
    <label className={`uploadFile ${featuredImage ? "noPad " : ""}${labelClassName}`}>
      {featuredImage &&
      ((typeof featuredImage === "object" && featuredImage?.type?.startsWith("video")) ||
        (typeof featuredImage === "string" && featuredImage?.endsWith(".mp4"))) ? (
        <video className={imgClassName} style={{ zIndex: 1 }} autoPlay loop muted>
          <source src={displayImage} type="video/mp4" />
        </video>
      ) : inputProps?.value && inputProps.value?.type === "application/json" ? (
        <></>
      ) : (
        <img src={displayImage} className={imgClassName} />
      )}
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      {!featuredImage && (
        <>
          <div className="filename">{hint || "PNG, JPG, GIF, WEBP or MP4. Max 5mb."}</div>
          <div className="inputfile" />
        </>
      )}
    </label>
  );
};

export default FileInput;
