import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Metaport, useMetaportStore } from "@skalenetwork/metaport";
import { showMetaport } from "redux/state/web3";

import "@skalenetwork/metaport/dist/style.css";

const MetaportModal = () => {
  // setShowMetaportModal={() => dispatch(showMetaport(false))
  const web3 = useSelector((state) => state.web3);
  const mpState = useMetaportStore((state) => state);

  useEffect(() => {
    mpState.setChainName2(process.env.REACT_APP_NETWORK_NAME);
  }, [web3.metaport]);

  if (!mpState.mpc || !web3.metaport) return <div></div>;
  return (
    <div>
      <Metaport config={mpState.mpc.config} />
    </div>
  );
};

export default MetaportModal;
