import { useSelector } from "react-redux";
import AsyncSelect from "react-select";
import { change, Field, FieldArray } from "redux-form";
import { formatDateForPicker } from "utils/formats";

import ToggleButton from "components/button/ToggleButton";
import { FileInput } from "components/inputs/FileUpload";
import Accordion from "components/layouts/Accordion";
import LaunchpadTierAccordion from "components/layouts/launchpad/LaunchpadTierAccordion";

export const requiredValidator = (value) => (value ? undefined : "Required");

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const humanizeName = (name) => {
  if (!name) return "";
  let capitalized = name.charAt(0).toUpperCase() + name.slice(1);
  return capitalized.replace("_", " ");
};

export const hasError = (form, fieldName) => {
  if (
    form &&
    ((form.syncErrors && form.syncErrors[fieldName]) ||
      (form.submitErrors && form.submitErrors[fieldName])) &&
    form.fields &&
    form.fields[fieldName] &&
    form.fields[fieldName].visited
  ) {
    return true;
  }
};

export const formError = (form, fieldName) => {
  if (hasError(form, fieldName)) {
    return (
      <div className="form-error">
        {form.syncErrors?.[fieldName] || form.submitErrors?.[fieldName]}
      </div>
    );
  }
};

export const renderForm = (form, name, options = {}) => {
  if (options.nocontainer) {
    return options.children ? (
      <Field name={name} {...options}>
        {options.children}
      </Field>
    ) : (
      <Field name={name} {...options} />
    );
  }
  return (
    <div
      className={`field-container ${options.containername ? options.containername : ""} ${
        hasError(form, name) ? "field-error" : ""
      } ${options.required ? "required" : ""}`}
    >
      {!options.hidename && (
        <h4 className={`${options.labelclass ? options.labelclass : "title-list-item"}`}>
          {options.title ? options.title : humanizeName(name)}
        </h4>
      )}

      <div className="flex h-100">
        {options.children ? (
          <Field name={name} {...options}>
            {options.children}
          </Field>
        ) : (
          <Field name={name} {...options} />
        )}
        {options.deleteable && (
          <button className="btn small" type="button">
            X
          </button>
        )}
      </div>
      {options.subtext && <p className="subtext">{options.subtext}</p>}
      {formError(form, name)}
    </div>
  );
};

export const renderFormV2 = ({
  placeholder,
  hidename,
  nocontainer,
  subtitle,
  subtitleClass,
  input,
  appendTitle,
  ...field
}) => {
  let inputField = false;
  switch (field.type) {
    case "textarea":
      inputField = (
        <textarea
          placeholder={placeholder}
          required={field.required}
          {...field}
          {...input}
        />
      );
      break;
    case "file":
      inputField = (
        <FileInput input={input} featuredImage={field.featuredImage} {...field} />
      );
      break;
    case "select":
      inputField = (
        <select required={field.required} {...input} {...field}>
          {field.children}
        </select>
      );
      break;
    default:
      inputField = <input placeholder={placeholder} {...input} {...field} />;
      break;
  }

  if (nocontainer) {
    return inputField;
  }

  return (
    <div
      className={`field-container ${field.containername ? field.containername : ""} ${
        field.meta.error ? "field-error" : ""
      } ${field.required ? "required" : ""}`}
    >
      {!hidename && (
        <h4
          className={`${
            field.labelclass ? field.labelclass : !subtitle ? "title-list-item" : ""
          }`}
        >
          {field.title ? field.title : humanizeName(input.name)} {appendTitle}
        </h4>
      )}
      {subtitle && (
        <p className={`${subtitleClass ? subtitleClass : "field-subtitle"}`}>
          {subtitle}
        </p>
      )}
      <div className="flex h-100">{inputField}</div>
      {field.subtext && <p className="subtext">{field.subtext}</p>}
      {field.meta.touched && field.meta.error && (
        <p className="error">{field.meta.error}</p>
      )}
    </div>
  );
};

export const RenderSelectInput = ({ input, options, name, id, ...field }) => {
  return (
    <div
      className={`field-container ${field.containername ? field.containername : ""} ${
        field.meta.error ? "field-error" : ""
      } ${field.required ? "required" : ""}`}
    >
      <h4 className="title-list-item">
        {field.title ? field.title : humanizeName(input.name)}
      </h4>
      <AsyncSelect
        {...input}
        {...field}
        id={id}
        name={name}
        options={options}
        required={field.required}
        value={options.find((o) => o.value === input.value)}
        onChange={({ value }) => input.onChange(value)}
        onBlur={() => input.onBlur()}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--hover-color3)",
            primary25: "var(--hover-color2)",
            neutral0: "var(--primary-color)",
            neutral5: "var(--primary-color5)",
            neutral10: "var(--primary-color5)",
            neutral20: "var(--primary-color5)",
            neutral30: "var(--primary-color5)",
            neutral40: "var(--primary-color6)",
            neutral50: "var(--primary-color6)",
            neutral60: "var(--primary-color6)",
            neutral70: "var(--primary-color6)",
            neutral80: "var(--primary-color6)",
            neutral90: "var(--primary-color6)",
          },
        })}
      />
      {field.meta.touched && field.meta.error && (
        <p className="error">{field.meta.error}</p>
      )}
    </div>
  );
};

const renderOptions = ({ fields, index, title }) => {
  return (
    <ul className="pl-20">
      <div className="flex-space-between">
        <h4 className="title-list-item">{title}</h4>
        <button
          className="icon-button create"
          type="button"
          onClick={() => fields.push("")}
        >
          <i className="fas fa-plus-circle" />
        </button>
      </div>
      {fields.length === 0 && (
        <li key={0}>
          <div className="flex">
            <Field
              name={`fields[${index}].values[0]`}
              type="text"
              component={renderField}
              fullWidth
              validate={[requiredValidator]}
            />
          </div>
        </li>
      )}
      {fields.map((item, index) => {
        return (
          <li key={index}>
            <div className="flex">
              <Field
                name={item}
                type="text"
                component={renderField}
                fullWidth
                validate={[requiredValidator]}
              />
              {index > 0 && (
                <button
                  className="btn small"
                  type="button"
                  onClick={() => fields.remove(index)}
                >
                  -
                </button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export const renderSetTags = ({ className }) => {
  const tags = useSelector((state) => state.settings?.tags);
  return (
    <div className={`${className || ""} field-tags`}>
      {tags.map((item, index) => (
        <span className="field-tag" key={item._id}>
          <label htmlFor={`tags.${item.name}`}>{item.name}</label>
          <Field
            key={item.name}
            id={`tags.${item.name}`}
            name={`tags.${index}`}
            type="checkbox"
            component="input"
            format={(v) => v === item._id} // converts redux state string to boolean
            normalize={(v) => (v ? item._id : false)} // converts checkbox boolean to string
          />
        </span>
      ))}{" "}
    </div>
  );
};

// export const renderSetTagsV2 = ({ formName }) => {
//   const tags = useSelector((state) => state.settings?.tags);
//   const tagsData = {
//     title: "Tags",
//     name: "tags",
//     show: true,
//     values: tags,
//   };
//   return <MultiSelectAccordion item={tagsData} formName={formName} />;
// };

export const renderSearchFields = ({ fields, title }) => (
  <>
    <h2 className="mg-bt-24 mg-t-24">{title}</h2>

    <ul>
      {fields.map((val, index) => {
        return renderSubFields(val, index, fields);
      })}
    </ul>
    <div className="flex justify-content-center">
      <button
        style={{ width: "50%", marginBottom: "50px" }}
        className="fullWidth"
        type="button"
        onClick={() => fields.push({ name: "", type: "select" })}
      >
        Add Metadata Field
      </button>
    </div>
  </>
);

const handleSelectChange = (newValue, name, dispatch, formName) => {
  dispatch(change(formName, `${name}.type`, newValue));
  dispatch(change(formName, `${name}.values`, [""]));
};

const renderSubFields = (item, index, fields) => (
  <li key={index}>
    <div className="field-container">
      <div className="row-form style-3">
        <div className="fullWidth">
          <h4 className="title-list-item">Attribute Name</h4>
          <Field
            name={`${item}.name`}
            type="text"
            validate={[requiredValidator]}
            component={renderField}
          />
        </div>
        <div className="fullWidth">
          <h4 className="title-list-item">Attribute Type</h4>
          <div className="flex">
            <Field
              name={`${item}.type`}
              item={item}
              component={renderSelect}
              title={item}
            >
              <option value="select">Text Attribute</option>
              <option value="number">Number Attribute</option>
            </Field>
            <button
              type="button"
              className="icon-button delete"
              onClick={() => fields.remove(index)}
            >
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
);

/**
 * Launchpad fields
 */

export const renderLaunchpadFields = ({
  fields,
  title,
  required,
  renderLaunchpadSection,
}) => {
  if (required && fields.length === 0) {
    fields.push({});
  }
  return (
    <div className="field-container">
      <h2 className="mg-bt-24 mg-t-24">{title}</h2>
      <ul>
        {fields.map((val, index) => {
          return renderLaunchpadSection(val, index, fields);
        })}
      </ul>
      <div className="flex justify-content-center">
        <button
          style={{ height: "min-content", width: "50%" }}
          className="fullWidth"
          type="button"
          onClick={() => fields.push({})}
        >
          Add Field
        </button>
      </div>
    </div>
  );
};

export const renderRoadmapFields = (item, index, fields) => (
  <li key={index}>
    <div className="field-container">
      <div className="row-form  pl-20">
        <div className="flex-space-between">
          <h3 className="mg-bt-24 mg-t-24">Roadmap Section #{index + 1}</h3>
          {index > 0 && (
            <button
              style={{ height: "min-content" }}
              type="button"
              onClick={() => fields.remove(index)}
            >
              Delete Field
            </button>
          )}
        </div>
        <div className="pl-20">
          <Field
            name={`${item}.title`}
            type="text"
            title="Roadmap Section Title"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />

          <Field
            name={`${item}.date`}
            type="text"
            title="Date"
            placeholder="e.g., 'Q1 2022', 'Summer', '15-10-2022'"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />

          <Field
            name={`${item}.image`}
            type="text"
            title="Image URL"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />
          <div className="inner-row-form fullWidth">
            <FieldArray
              name={`${item}.points`}
              title="Roadmap Points"
              component={renderRoadmapPoints}
            />
          </div>
        </div>
      </div>
    </div>
  </li>
);

const renderRoadmapPoints = ({ fields, title }) => {
  if (fields.length === 0) {
    fields.push("");
  }
  return (
    <div className="field-container required">
      <div className="flex-space-between">
        <h4 className="title-list-item">{title}</h4>
        <button
          className="btn fl-right"
          type="button"
          style={{ fontSize: "20px", fontWeight: "700", height: "52px", width: "52px" }}
          onClick={() => fields.push("")}
        >
          +
        </button>
      </div>
      <ul className="pl-20">
        {fields.map((item, index) => {
          return (
            <li key={index}>
              <div className="flex">
                <Field
                  name={item}
                  type="text"
                  fullWidth
                  component={renderField}
                  validate={[requiredValidator]}
                />
                {index > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      height: "52px",
                      width: "52px",
                    }}
                    onClick={() => fields.remove(index)}
                  >
                    -
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const renderTeamFields = (item, index, fields) => (
  <li key={index}>
    <div className="field-container">
      <div className="row-form  pl-20">
        <div className="flex-space-between">
          <h3 className="mg-bt-24 mg-t-24">Team Member #{index + 1}</h3>
          {index > 0 && (
            <button
              style={{ height: "min-content" }}
              type="button"
              onClick={() => fields.remove(index)}
            >
              Delete Field
            </button>
          )}
        </div>
        <div className="pl-20">
          <Field
            name={`${item}.member`}
            type="text"
            title="Team Member Name"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />
          <Field
            name={`${item}.bio`}
            type="textarea"
            title="Bio"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />
          <Field
            name={`${item}.image`}
            type="text"
            title="Image URL"
            validate={[requiredValidator]}
            containername="required"
            component={renderFormV2}
          />
        </div>
      </div>
    </div>
  </li>
);

export const renderFAQFields = (item, index, fields) => (
  <li key={index}>
    <div className="field-container">
      <div className="row-form  pl-20">
        <div className="flex-space-between">
          <h3 className="mg-bt-24 mg-t-24">FAQ #{index + 1}</h3>

          <button
            style={{ height: "min-content" }}
            type="button"
            onClick={() => fields.remove(index)}
          >
            Delete Field
          </button>
        </div>
        <div className="pl-20">
          <div className="inner-row-form fullWidth">
            <h4 className="sub-list-item">Question</h4>
            <Field
              name={`${item}.question`}
              type="text"
              validate={[requiredValidator]}
              component={renderField}
            />
          </div>
          <div className="inner-row-form fullWidth">
            <h4 className="sub-list-item">Answer</h4>
            <Field
              name={`${item}.answer`}
              type="text"
              validate={[requiredValidator]}
              component={renderField}
            />
          </div>
        </div>
      </div>
    </div>
  </li>
);

const renderSelect = (field) => {
  return (
    <select
      {...field.input}
      onChange={(e) =>
        handleSelectChange(
          e.target.value,
          field.item,
          field.meta.dispatch,
          field.meta.form
        )
      }
    >
      {field.children}
    </select>
  );
};

export const renderField = (field) => (
  <div className={`input-row ${field.fullWidth ? "fullWidth" : ""}`}>
    <input {...field.input} type={field.type} placeholder={field.placeholder} />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

export const renderToggle = (field) => (
  <div className={`input-row ${field.fullWidth ? "fullWidth" : ""}`}>
    <ToggleButton {...field} />
  </div>
);

export const renderTextArea = (field) => (
  <div className={`input-row ${field.fullWidth ? "fullWidth" : ""}`}>
    <textarea {...field.input} type={field.type} placeholder={field.placeholder} />
    {field.meta.touched && field.meta.error && (
      <span className="error">{field.meta.error}</span>
    )}
  </div>
);

const renderSwitchFieldType = (field) => {
  switch (field.type) {
    case "select":
      return (
        <Field
          name={`metadata.${field.name}`}
          type="text"
          validate={[requiredValidator]}
          component={renderField}
        />
      );
    case "number":
      return (
        <Field
          name={`metadata.${field.name}`}
          type="number"
          validate={[requiredValidator]}
          component={renderField}
        />
      );
    default:
      return null;
  }
};

export const renderCreateNFTFields = ({ fields }) => (
  <ul>
    {fields.map((field, index) => {
      return (
        <li key={index}>
          <div className="field-container fullWidth">
            <h4 className="title-list-item">{field.name}</h4>
            {renderSwitchFieldType(field)}
          </div>
        </li>
      );
    })}
  </ul>
);

// For onchain launchpad related queries

export const renderInventories = ({
  launchpad,
  inventories,
  sales,
  setBatchCreateModalShow,
  setBatchTransferModalShow,
  setWhitelistModalShow,
  setBatchWithdrawModalShow,
  fields,
}) => {
  // const parsePrice = (value) => {
  //   const inputRegex = RegExp(`^\d*\.[0-9]*$`);
  //   if (inputRegex.test(value.toString())) {
  //     return value;
  //   } else {
  //     return;
  //   }
  // };

  const parseLimit = (value, regex) => {
    const inputRegex = RegExp(regex);
    if (inputRegex.test(escapeRegExp(value.toString()))) {
      if (value >= 1000000) return "";
      return Number(value);
    } else {
      return;
    }
  };

  // if (fields.length === 0) {
  //   fields.push({
  //     start_date: formatDateForPicker(new Date().getTime()),
  //     end_date: formatDateForPicker(new Date().getTime() + week),
  //   });
  // }

  const collection = launchpad.launchpad_collection;
  return (
    <ul className="fullWidth">
      {fields.map((field, index) => {
        if (sales[index]?.hidden === true) return null;
        return (
          <li key={index} className={sales[index]?.hidden === true && "invisible"}>
            <div className="field-container fullWidth">
              <LaunchpadTierAccordion
                show
                fields={fields}
                index={index}
                launchpad={launchpad}
                inventories={inventories}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="field-container">
                      <Field
                        name={`sales[${index}].title`}
                        title="Title"
                        type="text"
                        required
                        component={renderFormV2}
                        placeholder={`Tier #${index + 1}`}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="field-container">
                      <Field
                        name={`sales[${index}].price`}
                        title="Mint Price"
                        type="number"
                        placeholder="0"
                        format={(value) => value || ""}
                        parse={(value) => value || ""}
                        component={renderFormV2}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="field-container">
                      <Field
                        name={`sales[${index}].limit`}
                        title="Per Wallet"
                        placeholder="&infin;"
                        parse={(value) => (value ? parseLimit(value, `^[0-9]*$`) : "")}
                        format={(value) => (value && value < 1000000 ? value : "")}
                        type="text"
                        component={renderFormV2}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Field
                      type="datetime-local"
                      className="picker"
                      name={`sales[${index}].start_date`}
                      title="Starting date"
                      props={{
                        min: "2022-01-01 00:00",
                      }}
                      required
                      parse={(val) => formatDateForPicker(val)}
                      format={(val) => formatDateForPicker(val)}
                      component={renderFormV2}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      type="datetime-local"
                      className="picker"
                      name={`sales[${index}].end_date`}
                      title="End date"
                      props={{
                        min: "2022-01-01 00:00",
                      }}
                      required
                      parse={(val) => formatDateForPicker(val)}
                      format={(val) => formatDateForPicker(val)}
                      component={renderFormV2}
                    />
                  </div>
                  <div className="flex fullWidth justify-content-between">
                    <div className="col-4 flex justify-content-start">
                      <div className="col-4">
                        <div className="field-container">
                          <h4 className="title-list-item">Whitelisted?</h4>
                          <Field
                            name={`sales[${index}].whitelisted`}
                            defaultChecked={inventories?.[index]?.is_whitelisted}
                            component={renderToggle}
                          />
                        </div>
                      </div>
                    </div>
                    {sales[index] && (
                      <div
                        className="col-6 flex justify-content-end"
                        style={{ gap: "2rem" }}
                      >
                        {inventories?.[index]?.is_whitelisted && (
                          <div>
                            <button
                              type="button"
                              className="input-group-btn"
                              onClick={() =>
                                setWhitelistModalShow({
                                  launchpad,
                                  index,
                                  tier: sales[index],
                                })
                              }
                            >
                              <h4>Whitelist Addresses</h4>
                            </button>
                          </div>
                        )}

                        {launchpad.launchpad_collection.keepsake && (
                          <div>
                            <button
                              type="button"
                              className="input-group-btn"
                              onClick={() =>
                                setBatchCreateModalShow({
                                  launchpad,
                                  collection,
                                  tier: sales[index],
                                })
                              }
                            >
                              <h4>Add NFTs</h4>
                            </button>
                          </div>
                        )}
                        <div>
                          <button
                            type="button"
                            className="input-group-btn"
                            onClick={() =>
                              setBatchTransferModalShow({
                                launchpad,
                                tier: sales[index],
                                index,
                              })
                            }
                          >
                            <h4>Transfer External NFTs</h4>
                          </button>
                        </div>
                        {sales[index] && (
                          <div>
                            <button
                              type="button"
                              className="input-group-btn"
                              onClick={() =>
                                setBatchWithdrawModalShow({
                                  index,
                                  tier: sales[index],
                                  inventory: inventories[index],
                                })
                              }
                            >
                              <h4>Withdraw NFTs</h4>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!sales[index] && sales.length > 0 && (
                  <div className="col-12">
                    <div className="field-container">
                      <Field
                        name={`sales[${index}].object_id`}
                        title="Use Existing tier inventory?"
                        type="select"
                        component={renderFormV2}
                      >
                        <option value="false">Create new inventory</option>
                        {sales.map((sale) => (
                          <option key={sale.venue_id} value={sale.object_id}>
                            {sale.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                )}
                {sales[index] && (
                  <Accordion title="Blockchain Info">
                    <p>Inventory ID:{sales[index]?.object_id}</p>
                    <p>Market ID:{sales[index]?.market_id}</p>
                    <p>Venue ID:{sales[index]?.venue_id}</p>
                  </Accordion>
                )}
                <div className="col-12 mt-3">
                  {!sales[index] && index == fields.length - 1 && (
                    <div className="field-container">
                      <button onClick={() => fields.pop()}>Remove</button>
                    </div>
                  )}
                  {sales[index] && (
                    <div className="field-container">
                      <h4 className="title-list-item">Delete Tier?</h4>
                      <Field
                        name={`sales[${index}].hidden`}
                        component={renderToggle}
                        defaultChecked={sales[index].hidden}
                      />
                      {fields.get(index)?.hidden && (
                        <>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#ff9935",
                              marginTop: "2rem",
                            }}
                          >
                            <span>WARNING: This tier will be permanently removed.</span>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "red",
                              marginTop: "1rem",
                            }}
                          >
                            <span>This action cannot be undone once saved.</span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </LaunchpadTierAccordion>
            </div>
          </li>
        );
      })}
      <div className="flex fullWidth justify-content-center">
        <button
          onClick={() => fields.push("")}
          type="button"
          style={{
            height: "4rem",
            padding: "0.5rem 2rem",
            borderRadius: "1rem",
            fontSize: "20px",
            // backgroundColor: "var(--primary-color11)",
          }}
        >
          Add New Tier
        </button>
      </div>
    </ul>
  );
};

export const handleCheckbox = (
  field,
  value,
  index,
  state,
  selector,
  formName,
  dispatch
) => {
  const fieldName = field.name;
  const fieldSelector = `${fieldName}.${index}`;
  const val = selector(state, fieldSelector);
  const changeVal = val ? null : value;
  dispatch(change(formName, fieldSelector, changeVal));
};
