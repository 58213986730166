import React from "react";
import Skeleton from "react-loading-skeleton";
import { GridRow, NameImageWrapper, RowImage } from "utils/styled";

import "react-loading-skeleton/dist/skeleton.css";

const ListNFTSkeleton = ({ numberOfSkeletonFields }) => {
  return (
    <GridRow>
      <NameImageWrapper>
        <RowImage>
          <Skeleton height="100%" containerClassName="image-skeleton" />
        </RowImage>
        <Skeleton width="150px" height="16px" />
      </NameImageWrapper>
      {Array.from({ length: numberOfSkeletonFields }, (_, index) => (
        <Skeleton key={index} width="50%" height="16px" />
      ))}
      {/* <Skeleton width="100%" height="42px" borderRadius="12px" /> */}
    </GridRow>
  );
};

export default ListNFTSkeleton;
