import React, { useEffect, useState } from "react";
// import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { createLaunchpad, getLaunchpadsForCollection } from "utils/api";
import { statusToEffect } from "utils/formats";

// import { sleep } from "utils/time";
import CollectionWishlistButton from "components/button/CollectionWishlistButton";
// import EllipsisDropdown from "components/layouts/EllipsisDropdown";
import LazyLoadImage from "components/utils/LazyLoadImage";
// import ToastPopup from "components/utils/ToastPopup";
import VerifiedBadge from "components/utils/VerifiedBadge";

const CollectionCard = ({ item: collection, owned, link, onClick }) => {
  // const [launchpad, setLaunchpad] = useState(false);
  // const [launchpadLoading, setLaunchpadLoading] = useState(false);
  // const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { _id, name, creator, review_status } = collection;
  const to = `${link || "/collection-details"}/${_id}`;
  const linkProps = onClick
    ? {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
        to,
      }
    : { to };
  // const isCreator = useMemo(() => {
  //   return user && creator ? creator._id === user._id : false;
  // }, [user, creator]);

  // useEffect(() => {
  //   if (user.permissions?.launchpad_creator) {
  //     getLaunchpadsForCollection(_id).then((res) => {
  //       setLaunchpad(res.data[0]);
  //     });
  //   }
  // }, []);

  // const handleEditCollection = () => {
  //   navigate(`/edit-collection/${_id}`);
  // };

  // const handleViewCollection = () => {
  //   navigate(`/collection-details/${_id}`);
  // };

  // const handleCreateLaunchpad = async () => {
  //   if (!launchpad?.object_id) {
  //     setLaunchpadLoading(true);
  //     try {
  //       const tx = await setupLaunchpad(collection, [], [], []);
  //       await sleep();
  //       let res = await createLaunchpad({
  //         collection: _id,
  //         tx: tx.effects.transactionDigest,
  //       });
  //       setLaunchpadLoading(false);
  //       navigate(`/edit-mintpad/${res.data.launchpad._id}`);
  //     } catch (error) {
  //       setLaunchpadLoading(false);
  //     }
  //   } else {
  //     navigate(`/edit-mintpad/${launchpad._id}`);
  //   }
  // };

  // const optionsDropdownList = [
  //   {
  //     label: "View Collection",
  //     onClick: handleViewCollection,
  //     icon: <i className="fas fa-eye" />,
  //   },
  // ];

  // if (owned && !!user.permissions?.launchpad_creator && !launchpad?.object_id)
  //   optionsDropdownList.push({
  //     label: "Create Mintpad",
  //     onClick: handleCreateLaunchpad,
  //     icon: <i className="fad fa-rocket-launch" />,
  //   });

  // if (isCreator && collection.keepsake) {
  //   optionsDropdownList.push({
  //     label: "Edit Collection",
  //     onClick: handleEditCollection,
  //     icon: <i className="fas fa-pencil" />,
  //   });
  // }

  return (
    <div className="sc-card-collection collection-card">
      <CollectionWishlistButton className="wishlist" collection={collection} />
      <Link {...linkProps}>
        <LazyLoadImage
          height="200px"
          className="collection-img-featured"
          src={collection.logo_image}
          key={`featured-${_id}`}
        />

        <VerifiedBadge verified={collection?.verified} />
      </Link>
      <div className="card-bottom fullWidth pd-15 justify-content-center gap-1">
        <h4 className="center">
          <Link {...linkProps}>{name ?? "Unnamed Collection"}</Link>
        </h4>
        {user.role_id == 3 && owned && (
          <div className="ps-abs-br pb-3 pr-3">
            <span className={`sc-status ${statusToEffect(review_status)} capitalize`}>
              {review_status}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
