export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const TOKEN_TYPES = {
  ERC20: "ERC20",
  ERC223: "ERC223",
  ERC721: "ERC721",
  ERC1155: "ERC1155",
};

export const BLOCKCHAINS = {
  ethereum: 1,
  binance: 56,
  testEthereum: 1337,
  testBinance: 97,
  skaleTestnet: 974399131,
};

export const SUPPORTED_BLOCKCHAINS = [parseInt(process.env.REACT_APP_NETWORK_ID)];

export const BLOCKCHAIN_INFO = {
  [BLOCKCHAINS.skaleTestnet]: {
    name: "[S]Calypso Hub",
    type: "metamask",
    chainParameters: {
      chainId: "974399131",
      chainName: "[S]Calypso Hub",
      nativeCurrency: {
        name: "Skale FUEL",
        symbol: "sFUEL",
        decimals: 18,
      },
      rpcUrls: ["https://testnet.skalenodes.com/v1/giant-half-dual-testnet"],
      blockExplorerUrls: [
        "https://giant-half-dual-testnet.explorer.testnet.skalenodes.com/",
      ],
      iconUrls: [],
    },
  },
  [BLOCKCHAINS.testEthereum]: {
    name: "Ethereum Testnet",
    stargates: { main: "0xbD418c32cb410d5Da4d38661E9E16bB7b8EcC1CC" },
    fee: "14000000000000",
    exchangeRate: 3500,
    type: "metamask",
    chainParameters: {
      chainId: "0x539",
      chainName: "Ethereum Testnet",
      nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["http://159.203.228.102:9545"],
      blockExplorerUrls: [],
      iconUrls: [],
    },
  },
};

export const getAvailableNetworks = () =>
  SUPPORTED_BLOCKCHAINS.map((id) => BLOCKCHAIN_INFO[id]);
