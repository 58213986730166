import { useMemo } from "react";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as form } from "redux-form";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import creators from "./state/creators";
import initialValues from "./state/initialValues";
import loading from "./state/loading";
import ProfileReducer from "./state/Profile/ProfileReducer";
import search from "./state/search";
import settings from "./state/settings";
import user from "./state/user";
import web3 from "./state/web3";

const middlewares = [thunk];

const persistConfig = {
  key: "primary",
  whitelist: ["Profile"],
  storage,
};

const reducers = () =>
  combineReducers({
    creators,
    form,
    initialValues,
    search,
    settings,
    web3,
    user,
    loading,
    Profile: ProfileReducer,
  });

const rootReducer = (state, action) => {
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer());
let store;

const makeStore = (preloadedState = undefined) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: middlewares,
  });
};

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });

    // Reset the current store
    store = undefined;
  }

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

store = initializeStore();

export default store;

export const persistor = persistStore(store);

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
