import LoadingButton from "components/button/LoadingButton";

const RefreshButton = ({ refreshHandler, loading }) => {
  return (
    <LoadingButton loading={loading} className="refresh-button" onClick={refreshHandler}>
      <i className="fas fa-redo-alt" />
    </LoadingButton>
  );
};

export default RefreshButton;
