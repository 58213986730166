import React, { useEffect, useState } from "react";
import { searchGames } from "utils/api";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import GamesInfoCards from "components/layouts/games/GamesInfoCards";
import PopularGames from "components/layouts/games/PopularGames";
import GamesSlider from "components/slider/GamesSlider";

import GamesLeaderboard from "./GamesLeaderboard";

const Games = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!data.length) {
      searchGames({
        featured: true,
        page: 1,
        perPage: 10,
        sortParams: { start_date: -1 },
      }).then((res) => {
        setData(res.data.results);
      });
    }
  }, []);

  return (
    <div className="games">
      <Header />
      <GamesSlider games={data} />
      <PopularGames />
      <GamesLeaderboard title="Games Leaderboard" />
      {/* <GamesInfoCards /> */}
      <Footer />
    </div>
  );
};

export default Games;
