import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import LeaderboardRows from "components/layouts/LeaderboardRows";
import PageHeader from "components/layouts/PageHeader";

const Leaderboard = () => {
  return (
    <div className="home-6">
      <Header />
      <PageHeader />
      <LeaderboardRows title="Collection Leaderboard" />
      <Footer />
    </div>
  );
};

export default Leaderboard;
