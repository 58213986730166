import { useState, useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";

const Sorting = ({ setSortParams, list }) => {
  if (!list) return null;
  const [optionIndex, setOptionIndex] = useState(0);

  const { field, label, direction, icon } = useMemo(
    () => list[optionIndex],
    [optionIndex]
  );

  useEffect(() => {
    let newSortParams = { sortParams: {} };
    if (typeof field === "string") {
      newSortParams = { sortParams: { [field]: direction } };
    } else if (typeof field === "object") {
      field.map((subfield) => {
        newSortParams.sortParams = { ...newSortParams.sortParams, [subfield]: direction };
      });
    }
    setSortParams(newSortParams);
  }, [field, direction]);

  const sortSelectHandler = (index) => {
    setOptionIndex(Number(index));
  };

  return (
    <Dropdown onSelect={(index) => sortSelectHandler(index)}>
      <Dropdown.Toggle id="dropdown-basic" className="btn-sort-by dropdown">
        <span className="capitalize sorting-flex">
          {icon}
          {label}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu flip={false}>
        {list.map((item, i) => {
          return (
            <Dropdown.Item
              active={i === optionIndex ? true : false}
              key={i}
              eventKey={i}
              className="sorting-flex"
            >
              {item.icon}
              {item.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Sorting;
