// import * as Web3Calls from "utils/web3/evm";
import { Explorer } from "utils/environments";
import Web3 from "web3";

import { SUPPORTED_BLOCKCHAINS } from "./tokenInfo";

const { utils } = Web3;
export const { toBN } = utils;

let web3ProviderInit;
if (typeof window.ethereum !== "undefined") {
  web3ProviderInit = window.ethereum;
} else {
  web3ProviderInit = new Web3.providers.HttpProvider(process.env.REACT_APP_NETWORK);
}

const web3Provider = web3ProviderInit;

export const web3 = new Web3(web3Provider);
export const connected = false;

// const IERC223 = TruffleContract(IERC223Artifact);
/*
  web3Provider.request({ method: "eth_requestAccounts" }).then(addresses => {
    return web3.eth.getChainId().then(chainId => {
      connected = true;
      return { address: addresses[0], chainId};
    })
  });
*/

// utils
/**
 * Check if the provided address exists
 * @param {string} realAddress
 * @returns {boolean} returns true if address exists
 */
export const isAddress = (realAddress) => web3.utils.isAddress(realAddress);

export const formatAddress = (address) =>
  isAddress(address) ? web3.utils.toChecksumAddress(address) : address;

export const parseNetworkId = (chainId) => {
  return chainId.toString().includes("0x")
    ? parseInt(chainId.replace("0x", ""), 16)
    : parseInt(chainId.toString());
};

export const isValidNetwork = (chainId) => {
  let formattedId = parseNetworkId(chainId);
  return SUPPORTED_BLOCKCHAINS.indexOf(formattedId) >= 0;
};

/**
 * Check if the specified transaction is completed
 * https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html?highlight=getTransactionReceipt#gettransactionreceipt
 * @param {string} hash
 * @returns A transaction receipt object, or null if no receipt was found:
 */
export const isCompletedTx = (hash) => web3.eth.getTransactionReceipt(hash);

// way to get currently selected address
export const selectedAddress = () =>
  web3Provider.selectedAddress || web3Provider.address || web3Provider.accounts[0];

/**
 * Changes the network the user is currently using.
 * @param {*} chain
 * @returns {boolean}
 */
export const addChain = async () => {
  const chain = {
    chainId: parseInt(process.env.REACT_APP_NETWORK_ID),
    chainName: `SKALE ${process.env.REACT_APP_NETWORK_NICKNAME}`,
    nativeCurrency: { name: "sFUEL", symbol: "sFUEL", decimals: 18 },
    rpcUrls: [process.env.REACT_APP_NETWORK],
    blockExplorerUrls: [Explorer],
  };
  try {
    await web3Provider.request({
      method: "wallet_addEthereumChain",
      params: [{ ...chain, chainId: Web3.utils.toHex(chain.chainId) }],
    });
    return true;
  } catch {
    return false;
  }
};

// authentication
/**
 * Prompt the user to sign a login message
 * @returns {Promise} String
 */
export const accountAuth = (secret, timestamp) =>
  web3.eth.personal.sign(`${secret}::login::${timestamp}`, selectedAddress(), "");

/**
 * Checks if the user has already signed the "Proof of Ownership" message
 * @param {object} proof
 * @param {string} account
 * @returns {Promise} String
 */
export const isAuthed = (proof, account) =>
  web3.eth.personal
    .ecRecover(proof)
    .then((signer) =>
      signer.toLowerCase() == account
        ? account.toLowerCase()
        : selectedAddress().toLowerCase()
    );

export const displayFormatWithSuffix = (displayAmount) => {
  const suffixes = ["", "K", "M", "B", "T"];
  let i = 0;
  let finalVal = displayAmount;
  while (finalVal >= 1000) {
    finalVal /= 1000;
    i++;
  }
  return `${parseFloat(parseFloat(finalVal).toFixed(2))}${suffixes[i]}`;
};

/**
 * Turns a BN into a float amount using the number of decimals in a token
 * @param {BigInt} amount
 * @param {number} decimals
 * @returns {float}
 */
export const getDisplayVal = (amount, decimals, formatWithSuffix) => {
  let realAmount = amount;
  if (!decimals) {
    return amount ? amount.toString() : 0;
  }
  if (typeof amount === "object") {
    realAmount = amount.toString();
  }
  var displayAmount;
  if (realAmount.length > decimals) {
    var end = realAmount.slice(realAmount.length - decimals);
    var beginning = realAmount.slice(0, realAmount.length - decimals);
    displayAmount = parseFloat(beginning + "." + end);
  } else {
    var prepend = decimals - realAmount.length;
    var prefix = "0.";
    for (let index = 0; index < prepend; index++) {
      prefix = prefix.concat("0");
    }
    displayAmount = parseFloat(prefix + realAmount.toString());
  }
  if (formatWithSuffix) {
    displayFormatWithSuffix(displayAmount);
  }
  return displayAmount;
};

export const toBaseUnit = (value, decimals, safe = false) => {
  if (!(typeof value === "string" || value instanceof String)) {
    throw new Error("Pass strings to prevent floating point precision issues.");
  }
  const ten = 10n;
  const base = ten ** BigInt(decimals);

  // Is it negative?
  const negative = value.substring(0, 1) === "-";
  if (negative) {
    value = value.substring(1);
  }

  if (value === ".") {
    throw new Error(
      `Invalid value ${value} cannot be converted to` +
        ` base unit with ${decimals} decimals.`
    );
  }

  // Split it into a whole and fractional part
  const comps = value.split(".");
  if (comps.length > 2) {
    throw new Error("Too many decimal points");
  }

  let whole = comps[0];
  let fraction = comps[1];

  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > decimals) {
    if (safe) {
      throw new Error("Too many decimal places");
    }
    fraction = fraction.substring(0, decimals);
  }

  while (fraction.length < decimals) {
    fraction += "0";
  }

  whole = BigInt(whole);
  fraction = BigInt(fraction);
  let wei = whole * base + fraction;

  if (negative) {
    wei = -wei;
  }

  return BigInt(wei.toString(10), 10);
};

export const round = (int, decimals) => Math.round(int * 10 ** decimals) / 10 ** decimals;

export const BNGTECompare = (one, two) => {
  return BigInt(one).gte(BigInt(two));
};

// export const roundEtherBalance = (balance) => roundFromWei(balance, "ether", 2);

export const conversionRatio = (number) =>
  number < 1
    ? [1, Math.round((1 / number) * 100) / 100]
    : [Math.round(number * 100) / 100, 1];

// const transactionFinsished = (hash, callback) => {
//   Web3Calls.getTransaction(hash).then((info) => {
//     if (info?.blockNumber !== null) {
//       callback(hash);
//     }
//   });
// };

// export const transactionFinishedLoop = (hash, callback) => {
//   let loop;
//   const callback2 = () => {
//     clearInterval(loop);
//     callback(hash);
//   };
//   loop = setInterval(transactionFinsished, 8000, hash, callback2);
// };
