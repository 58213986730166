import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";

import LaunchpadInfoCountdownCubes from "./details/LaunchpadInfoCountdownCubes";

const AccordionTitle = styled.span`
  color: var(--primary-color2);
  font-size: 28px;
`;

const LaunchpadTierAccordion = ({
  show,
  fields,
  index,
  inventories,
  children,
}) => {
  const [open, setOpen] = useState(show);
  const isWhitelisted = fields.get(index)?.whitelisted;
  const whitelistCount = fields.get(index)?.whitelisted_count;
  const title = fields.get(index)?.title || `Tier #${index + 1}`;
  const inventory = inventories?.[index] || { current: 0, total: 0 };
  const mintLimit = fields.get(index)?.limit || "UNLIMITED";
  const mintPrice = fields.get(index)?.price ? `${fields.get(index)?.price} ` : "FREE";
  const startDate = fields.get(index)?.start_date;
  const endDate = fields.get(index)?.end_date;
  const isSoldOut = inventory?.current == 0;

  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="fullWidth item-details-accordian"
      >
        <div className="flex justify-content-between align-items-center">
          <div className="flex align-items-center" style={{ gap: "1rem" }}>
            <AccordionTitle>{title}</AccordionTitle>
            <div className="mint-option-pill">
              <span className="mint-option-pill-text">
                {isWhitelisted ? "Whitelist" : "Public"}
              </span>
            </div>
          </div>
          <LaunchpadInfoCountdownCubes
            key={startDate + endDate}
            startDate={startDate}
            endDate={endDate}
            isSoldOut={isSoldOut}
          />
        </div>
        <div className="flex flex-column fullWidth" style={{ gap: "3rem" }}>
          <div className="flex justify-content-between"></div>
          <div className="flex justify-content-between" style={{ height: "2rem" }}>
            <span style={{ fontSize: "18px", fontWeight: 600 }}>
              Per Wallet: {mintLimit}
              <b> • </b> Price: {mintPrice}
              <b> • </b> Items: {inventory.current || 0} / {inventory.total || "~"}
              <b> • </b> Whitelisted Addresses: {whitelistCount || 0}
            </span>

            {open ? (
              <i className="fas fa-caret-up" />
            ) : (
              <i className="fas fa-caret-down" />
            )}
          </div>
        </div>
      </button>
      <Collapse in={open}>
        <div>
          <div className="item-details-accordian-details">{children}</div>
        </div>
      </Collapse>
    </div>
  );
};

export default LaunchpadTierAccordion;
