import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { getBasePath } from "utils/formats";

import PrivateRoute from "components/layouts/PrivateRoute";
import { useTitle } from "components/utils/TitleProvider";

// import Activity from "./Activity";
// import Creator from "./Creator";
// import Create from "./Create";
// import CreateItem from "./nfts/CreateItem";
// import CreateCollection from "./collections/CreateCollection";
import AddCollection from "./collections/AddCollection";
import CollectionDetails from "./collections/CollectionDetails";
import SyncCollection from "./collections/SyncCollection";
// import CreateGameForm from "./games/CreateGame/CreateGameForm";
import CreateGame from "./games/CreateGame";
import EditGame from "./games/EditGame";
import GameDetails from "./games/GameDetails";
import Games from "./games/Games";
// import RentalDetails from "./nfts/RentalDetails";
import ItemDetails from "./nfts/ItemDetails";
import ListItem from "./nfts/ListItem";
import NFTDetails from "./nfts/NFTDetails";
import Explore from "./Explore";
import Home from "./Home";
// import Launchpad from "./launchpads/Launchpad";
// import LaunchpadDetails from "./launchpads/LaunchpadDetails";
// import CreateLaunchpad from "./launchpads/CreateLaunchpad";
// import EditLaunchpad from "./launchpads/EditLaunchpad";
// import EditLaunchpadListings from "./launchpads/EditLaunchpad/EditLaunchpadListings";
// import Rewards from "./Rewards";
// import BatchCreateItem from "./nfts/BatchCreateItem";
// import EditLaunchpadForm from "./launchpads/EditLaunchpad/EditLaunchpadForm";
// import CreateCollectionForm from "./collections/CreateCollection/CreateCollectionForm";
// import CreateItemForm from "./nfts/CreateItem/CreateItemForm";
// import BatchWhitelist from "./launchpads/BatchWhitelist";
// import EditLaunchpadListingsForm from "./launchpads/EditLaunchpad/EditLaunchpadListingsForm";
import Leaderboard from "./Leaderboard";
// import FAQ from "./FAQ";
// import Contact from "./Contact";
import MyProfile from "./MyProfile";
import NoResult from "./NoResult";
import Review from "./Review";
// import EditCollection from "./collections/EditCollection";
// import ReviewCollections from "./collections/ReviewCollections";
import Settings from "./Settings";
// import ItemTransfer from "./nfts/ItemTransfer";
// import P2PSwap from "./P2PSwap";
// import EditBagsForm from "./bags/EditBagsForm";

// Alias old routes to redirect to new routes e.g. launchpad redirects to mintpad
const AliasRedirect = ({ to }) => {
  const { id } = useParams();
  return <Navigate to={`${to}/${id}`} replace />;
};

const routes = [
  { path: "/", component: <Home />, title: "Home" },
  { path: "/explore", component: <Explore />, title: "Explore" },
  {
    path: "/explore/:link",
    component: <Explore />,
    title: "Explore",
  },
  // {
  //   path: "/collection-details",
  //   component: <CollectionDetails />,
  //   title: "Collection Details",
  // },
  {
    path: "/collection-details/:id",
    component: <CollectionDetails />,
    title: "Collection Details",
  },
  {
    path: "/collection-details/:id/:link",
    component: <CollectionDetails />,
    title: "Collection Details",
  },
  {
    path: "/item-details",
    component: <Navigate to="/explore" replace />,
    title: "Explore",
  },
  { path: "/item-details/:id", component: <ItemDetails />, title: "Item Details" },
  // {
  //   path: "/rental-details/:id",
  //   component: <RentalDetails />,
  //   title: "Rental Details",
  // },
  // { path: "/mintpad", component: <Launchpad />, title: "Mintpad" },
  // { path: "/mintpad/:id", component: <LaunchpadDetails />, title: "Mintpad Details" },
  // { path: "/launchpad", component: <Navigate to="/mintpad" replace /> },
  // { path: "/launchpad/:id", component: <AliasRedirect to="/mintpad" /> },
  // {
  //   path: "/create-mintpad",
  //   component: <CreateLaunchpad />,
  //   title: "Create Mintpad",
  // },
  // {
  //   path: "/edit-mintpad/:id",
  //   component: <EditLaunchpadForm />,
  //   title: "Edit Mintpad",
  // },
  // {
  //   path: "/whitelist-mintpad/:id",
  //   component: <BatchWhitelist />,
  //   title: "Whitelist Mintpad",
  // },
  // {
  //   path: "/edit-mintpad-listings/:id",
  //   component: <EditLaunchpadListingsForm />,
  //   title: "Edit Mintpad Listings",
  // },
  // { path: "/activity", component: <Activity />, title: "Activity" },
  // { path: "/creators/:id", component: <Creator />, title: "Creator" },
  // { path: "/creators/:id/:link", component: <Creator />, title: "Creator" },
  { path: "/nft-details/:id", component: <NFTDetails />, title: "NFT Details" },
  {
    path: "/review",
    private: true,
    component: <Review />,
    title: "Review",
  },
  {
    path: "/review/:link",
    private: true,
    component: <Review />,
    title: "Review",
  },
  // {
  //   path: "/edit-collection/:id",
  //   private: true,
  //   component: <EditCollection />,
  //   title: "Edit Collection",
  // },
  { path: "/settings", component: <Settings />, title: "Settings" },
  {
    path: "/profile/:section/:link",
    private: true,
    component: <MyProfile />,
    title: "My Profile",
  },
  {
    path: "/profile/:section",
    private: true,
    component: <MyProfile />,
    title: "My Profile",
  },
  { path: "/profile", private: true, component: <MyProfile />, title: "My Profile" },
  //{ path: "/rewards", private: true, component: <Rewards />, title: "Rewards" },
  { path: "/games", component: <Games />, title: "Games" },
  { path: "/games/:id", component: <GameDetails />, title: "Game" },
  { path: "/games/:id/:link", component: <GameDetails />, title: "Game" },
  {
    path: "/create-game",
    private: true,
    component: <CreateGame />,
    title: "Create Game",
  },
  { path: "/edit-game/:id", private: true, component: <EditGame />, title: "Edit Game" },
  { path: "/no-result", component: <NoResult />, title: "404" },
  // { path: "/faq", component: <FAQ />, title: "FAQ" },
  // { path: "/contact", component: <Contact />, title: "Contact Us" },
  { path: "*", component: <Navigate to="/no-result" replace /> },
  // { path: "/create", private: true, component: <Create />, title: "Create" },
  { path: "/list-nft", private: true, component: <ListItem />, title: "List NFT" },
  // {
  //   path: "/create-nft",
  //   private: true,
  //   component: <CreateItemForm />,
  //   title: "Create NFT Wizard",
  // },
  // {
  //   path: "/batch-create-nft",
  //   private: true,
  //   component: <BatchCreateItem />,
  //   title: "Create NFTs",
  // },
  // {
  //   path: "/create-collection",
  //   private: true,
  //   component: <CreateCollectionForm />,
  //   title: "Create Collection",
  // },
  {
    path: "/add-collection",
    private: true,
    component: <AddCollection />,
    title: "List Collection",
  },
  {
    path: "/sync-collection/:id",
    private: true,
    component: <SyncCollection />,
    title: "List Collection",
  },
  {
    path: "/leaderboard",
    component: <Leaderboard />,
    title: "Leaderboard",
  },
  // {
  //   path: "/transfer",
  //   component: <ItemTransfer />,
  //   title: "Transfer Items",
  // },
  // {
  //   path: "/swap",
  //   component: <P2PSwap />,
  //   title: "P2P Swap",
  // },
  // {
  //   path: "/edit-bags/:id",
  //   component: <EditBagsForm />,
  //   title: "Edit Bags",
  // },
];

const Routing = () => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const basePath = getBasePath(location.pathname);
  const { setTitle } = useTitle();
  useEffect(() => {
    const currentRoute = routes.find((route) => route.path == location.pathname);
    if (currentRoute?.title) setTitle(currentRoute.title);
  }, [basePath]);

  return (
    <Routes>
      {routes.map((data, index) =>
        data.private ? (
          <Route
            path={data.path}
            key={"private" + index}
            element={
              <PrivateRoute
                user={user}
                element={data.component}
                key={"private" + index}
              />
            }
          ></Route>
        ) : (
          <Route
            index={data.index}
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={data.component}
            key={index}
          />
        )
      )}
    </Routes>
  );
};

export default Routing;
