import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getGame } from "utils/api";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import CreatorTabs from "components/layouts/creator/CreatorTabs";
import GameBanner from "components/layouts/games/GameBanner";
import GameTabs from "components/layouts/games/GameTabs";
import ProfileBanner from "components/layouts/profile/ProfileBanner";
import ProfileUpper from "components/layouts/profile/ProfileUpper";
import LoadingSpinner from "components/utils/LoadingSpinner";
import { useTitle } from "components/utils/TitleProvider";
import ToastPopup from "components/utils/ToastPopup";

const GameDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { setTitle } = useTitle();
  const [game, setGame] = useState(null);

  useEffect(() => {
    if (params.id) {
      getGame(params.id)
        .then((res) => {
          setGame(res.data);
          setTitle(res.data.name + " - Game");
        })
        .catch(() => {
          ToastPopup("Unable to find game", "error");
          navigate("/");
        });
    }
  }, []);

  if (!game) {
    return <LoadingSpinner size="xxlarge" absolute />;
  }

  return (
    <div className="my-profile">
      <Header />
      <GameBanner game={game} />
      <section className="tf-section tf-game-details">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-12 my-items">
              {/* <ProfileUpper user={game} /> */}
              <GameTabs game={game} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GameDetails;
