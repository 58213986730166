import React, { useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import dayjs from "dayjs";
import styled from "styled-components";
import { renderDuration } from "utils/formats.js";

import WishlistButton from "components/button/WishlistButton";
import LazyLoadImage from "components/utils/LazyLoadImage";

const InfoLabel = styled.span`
  color: var(--primary-color9);
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 700;
`;

const InfoValue = styled.span`
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
`;

const RentalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InfoWrapper = styled.div`
  padding: 1rem 2rem;
`;

const RentalInfo = ({ label, value }) => (
  <RentalInfoContainer>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value}</InfoValue>
  </RentalInfoContainer>
);

const RentalCard = ({ item, setModalShow, alwaysShowButton }) => {
  const [finished, setFinished] = useState(false);
  const [started, setStarted] = useState(false);
  const [rented, setRented] = useState(false);
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);

  const { _id, nft, collection, seller } = item;
  let isOwner = useMemo(() => {
    return user._id === seller;
  }, [user?._id]);
  const buyText = isOwner ? "Retract Listing" : "Rent";
  const disabled =
    (finished && (!isOwner || !item.active)) || (!isOwner && (!started || rented));

  useEffect(() => {
    const now = Date.now();
    const start = new Date(item.start_date);
    // const end = new Date(item.end_date);
    setStarted(now > start);
    if (item.loan_expiration) {
      const rentedOut = new Date(item.loan_expiration);
      if (rentedOut > now) {
        setRented(rentedOut);
      }
    }
  }, []);

  const finishRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return <div>Renting Finished</div>;
    }
    return (
      <div className="featured-countdown ends-in">
        Ends in: {`${days}:${hours}:${minutes}:${seconds}`}
      </div>
    );
  };

  const startRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return (
        <Countdown
          date={item.end_date}
          renderer={finishRenderer}
          onComplete={() => setFinished(true)}
        />
      );
    }
    return <div>Starts in: {`${days}:${hours}:${minutes}:${seconds}`}</div>;
  };

  const RentalTooltip = () => {
    return (
      <ReactTooltip
        id="rental-tooltip"
        place="top"
        type="dark"
        effect="solid"
        delayShow={150}
        className="rental-tooltip flex flex-column gap-1"
      >
        <RentalInfo
          label="Rent Expires"
          value={rented ? dayjs(rented).format("MMM D hh:mmA") : "Not Rented"}
        />
        <RentalInfo
          label="Min Rent Duration"
          value={renderDuration(item.duration?.min)}
        />
        <RentalInfo
          label="Max Rent Duration"
          value={renderDuration(item.duration?.max)}
        />
      </ReactTooltip>
    );
  };

  return (
    <>
      <RentalTooltip />
      <div className="sc-card-product rental-card">
        <div className={`card-media${alwaysShowButton ? ` active` : ``}`}>
          <Link to={`/rental-details/${_id}`}>
            <LazyLoadImage src={nft?.image} className="image-skeleton" />
          </Link>

          <WishlistButton nft={nft} />
          <div className="featured-countdown">
            <Countdown
              className="start"
              date={item.start_date}
              renderer={startRenderer}
            />
          </div>
        </div>
        <InfoWrapper className="card-details">
          <div className="flex justify-content-between card-title">
            <div className="flex flex-column fullWidth">
              <Link to={`/rental-details/${_id}`}>
                <h3>{nft?.name}</h3>
              </Link>
              <Link to={`/collection-details/${collection?._id}`}>
                <h5>{collection?.name}</h5>
              </Link>
            </div>
            {/* <div className="tags">{tags ?? ""}</div> */}
            <a data-tip data-for="rental-tooltip">
              <i className="fad fa-question-circle infocircle" />
            </a>
          </div>
          <div className="rental-info">
            <RentalInfo
              label="Rent Duration"
              value={`
              ${renderDuration(item.duration?.min)} - ${renderDuration(
                item.duration?.max
              )}
            `}
            />
            <RentalInfo
              label="Rental Ends"
              value={dayjs(item.end_date).format("MMM D h:mma")}
            />
            <RentalInfo
              label="Price Per Day"
              value={`${item.ask_per_day} ${settings.payment.symbol}`}
            />
          </div>
          {/* <div className="meta-info">
          <FlexColumn>
            <InfoLabel>Rental Ends</InfoLabel>
            <InfoValue>{dayjs(item.end_date).format("MMM D h:mma")}</InfoValue>
          </FlexColumn>
          <div className="price">
            <span>Price Per Day</span>
            <h3>{item.ask_per_day} </h3>
          </div>
        </div> */}
          {setModalShow && (
            <div className="button-place-bid">
              <button
                onClick={() => setModalShow(item)}
                disabled={disabled}
                data-toggle="modal"
                data-target="#popup_bid"
                className="sc-button style-place-bid  fl-button pri-3 fullWidth"
              >
                <span>{buyText}</span>
              </button>
            </div>
          )}
        </InfoWrapper>
      </div>
    </>
  );
};

export default RentalCard;
