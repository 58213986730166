import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { statusToEffect } from "utils/formats";

import CollectionWishlistButton from "components/button/CollectionWishlistButton";
import EllipsisDropdown from "components/layouts/EllipsisDropdown";
import LazyLoadImage from "components/utils/LazyLoadImage";
import VerifiedBadge from "components/utils/VerifiedBadge";

const ReviewCollectionCard = ({ item: collection, link, onClick }) => {
  const navigate = useNavigate();
  const { _id, name, nft_count, review_status } = collection;
  const to = `${link || "/collection-details"}/${_id}`;
  const linkProps = onClick
    ? {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
        to,
      }
    : { to };

  const handleViewCollection = () => {
    navigate(`/collection-details/${_id}`);
  };

  const optionsDropdownList = [
    {
      label: "View Collection",
      onClick: handleViewCollection,
      icon: <i className="fas fa-eye" />,
    },
  ];

  return (
    <div className="sc-card-collection collection-card">
      <CollectionWishlistButton className="wishlist" collection={collection} />
      <Link {...linkProps}>
        <LazyLoadImage
          height="160px"
          className="collection-img-featured"
          src={collection.featured_image}
          key={`featured-${_id}`}
        />

        <div className="ps-abs-mdl" style={{ height: "72px", width: "72px" }}>
          <div className="collection-img-logo">
            <LazyLoadImage
              isIcon
              src={collection.logo_image}
              className="fullWidth h-100"
              key={`logo-${_id}`}
            />
          </div>
          <VerifiedBadge verified={collection.verified} />
        </div>
      </Link>
      <div className="card-bottom fullWidth pd-15 justify-content-center gap-1">
        <h4 className="text-truncate">
          <Link {...linkProps}>{name ?? "Unnamed Collection"}</Link>
        </h4>
        <EllipsisDropdown list={optionsDropdownList} item={collection} />
        <div className="ps-abs-bl pb-3 pl-3">
          <p>{nft_count} NFTs</p>
        </div>
        <div className="ps-abs-br pb-3 pr-3">
          <span className={`sc-status ${statusToEffect(review_status)} capitalize`}>
            {review_status}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReviewCollectionCard;
