import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
// import SuiIcon from "assets/images/icon/Sui_Droplet_Logo_Blue_Small.png";
import styled from "styled-components";
import { getCollection, updateListing } from "utils/api";
import { ipfsConvert, listingDisplayPrice, listingPrice } from "utils/formats";
import { FlexColumn, FlexRow } from "utils/styled";
import { sleep } from "utils/time";
import { cancelListing } from "utils/web3/evm";

import LoadingButton from "components/button/LoadingButton";
import RequireConnectedButton from "components/button/RequireConnectedButton";
import { useRefreshSearch } from "components/utils/SearchProvider";
import ToastPopup from "components/utils/ToastPopup";

const SuiInput = styled.input`
  width: 100%;
  padding-left: 55px !important;
  border-radius: var(--border-radius-md) !important;
`;

const InputWrapper = styled.div`
  height: min-content;
  width: 100%;
  position: relative;
`;

const InputIcon = styled.img`
  position: absolute;
  left: 15px;
  bottom: 0;
  height: 100%;
  width: 25px;
  object-fit: contain;
`;

const PriceContainer = styled.div`
  padding: 1.5rem;
  border-radius: var(--border-radius-md);
  background-color: var(--primary-color7);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const PriceLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`;

const PriceValue = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #8a8aa0;
`;

const PricesColumn = styled(FlexColumn)`
  justify-content: space-evenly;
`;

const UnlistModal = ({ item, onHide, customFetch }) => {
  if (!item) {
    return false;
  }
  const max = listingPrice(item);
  const floor = item.collection.floor;
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  let payment = useSelector((state) => state.settings.payment);
  const [inputPrice, setInputPrice] = useState(max);
  const searchProvider = useRefreshSearch();
  const refreshSearch = searchProvider?.refreshSearch;
  const [nftCollection, setCollection] = useState(false);
  const disabled = loading || !inputPrice || inputPrice === max;

  const unlistListing = async (item) => {
    setLoading(true);
    // marketContract, listingId, from
    const res = await cancelListing(
      item.market.marketAddress,
      item.market.marketIndex,
      user.account_address
    ).catch((e) => {
      console.log(e);
      setLoading(false);
      return;
    });
    await sleep();
    if (res?.hash) {
      await updateListing(res.hash);
      customFetch ? customFetch() : refreshSearch();
    }
    setLoading(false);
    onHide();
  };

  const editListing = async (item, newPrice) => {
    setLoading(true);

    const res = await updateListing(item._id, newPrice).catch((e) => {
      setLoading(false);
      ToastPopup("Failed to update listing price, please try again.", "error");
      return;
    });
    if (res?.data?.status === "success" && res?.data?.updated !== false) {
      ToastPopup("Listing price successfully lowered!.", "success");
      customFetch ? customFetch() : refreshSearch();
      onHide();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCollection();
  }, [item._id]);

  const fetchCollection = async () => {
    if (item.collection?._id) {
      setCollection(item.collection);
    } else {
      let collection = await getCollection(item.nft_collection);
      collection = collection.data?.collection;
      setCollection(collection);
    }
  };

  const parseListingPrice = (value) => {
    if (!value) {
      setInputPrice("");
      return;
    }

    if (value >= max) {
      setInputPrice(max);
    } else {
      setInputPrice(value);
    }
  };

  const finishRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return "Auction Finished";
    }
    return <p>Auction Ends in: {`${days}:${hours}:${minutes}:${seconds}`}</p>;
  };

  const startRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return <Countdown date={item?.auction?.end_date} renderer={finishRenderer} />;
    }
    return <p>Auction Starts in: {`${days}:${hours}:${minutes}:${seconds}`}</p>;
  };

  return (
    <Modal size="sm" show={item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Edit {item.nft.name}</h3>
        <div className="flex justify-content-between">
          <img src={ipfsConvert(item.nft.image)} style={{ width: "150px" }} />
          <PricesColumn>
            <PriceContainer>
              <PriceLabel>Listing Price</PriceLabel>
              <PriceValue>
                {max} {payment.symbol}
              </PriceValue>
            </PriceContainer>
            <PriceContainer>
              <PriceLabel>Collection Floor Price</PriceLabel>
              <PriceValue>
                {floor} {payment.symbol}
              </PriceValue>
            </PriceContainer>
          </PricesColumn>
        </div>
        <h5 className="mb-3">Set New Price</h5>
        <PriceValue>
          If you want to change the price, you will be prompted to cancel your existing
          listing first.
        </PriceValue>

        <InputWrapper>
          {/* <InputIcon src={SuiIcon} /> */}
          <SuiInput
            name="price"
            type="text"
            value={inputPrice}
            pattern="^[0-9]*[.]?[0-9]*$"
            placeholder={`${listingDisplayPrice(item)} ${payment.symbol}`}
            onChange={(event) => {
              event.currentTarget.validity.valid && parseListingPrice(event.target.value);
            }}
          />
        </InputWrapper>
        {item.sale_type === "auction" && (
          <div>
            <>
              <Countdown date={item.auction.start_date} renderer={startRenderer}>
                <></>
              </Countdown>
            </>
          </div>
        )}

        <FlexRow className="gap-1">
          <RequireConnectedButton text="Delist">
            <LoadingButton
              loading={loading}
              disabled={loading}
              className="btn btn-outline-danger"
              onClick={() => unlistListing(item)}
            >
              Remove Listing
            </LoadingButton>
          </RequireConnectedButton>
          <LoadingButton
            loading={loading}
            disabled={true}
            className="btn btn-primary"
            onClick={() => editListing(item, inputPrice)}
          >
            Edit Price
          </LoadingButton>
        </FlexRow>
      </div>
    </Modal>
  );
};

export default UnlistModal;
