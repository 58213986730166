const VerifiedBadge = ({ verified, inline }) => {
  if (!verified) return null;
  return (
    <div className={`verified-badge ${inline ? `inline-badge` : ""}`}>
      <i
        className="fad fa-badge-check"
        style={{
          "--fa-primary-color": "#ffffff",
          "--fa-secondary-color": "#5f13f6",
          "--fa-secondary-opacity": "1",
        }}
      />
    </div>
  );
};

export default VerifiedBadge;
