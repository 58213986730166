import ReactTooltip from "react-tooltip";

const Tooltip = ({ id, children, ...props }) => (
  <>
    <ReactTooltip
      id={id}
      place="top"
      type="dark"
      effect="solid"
      delayShow={150}
      className="info-tooltip"
      {...props}
    >
      <span>{children[0] || children}</span>
    </ReactTooltip>
    <a data-tip data-for={id}>
      {(children.length > 1 && children?.filter((a, i) => i !== 0)) || (
        <i className="fad fa-question-circle infocircle" />
      )}
    </a>
  </>
);

export default Tooltip;
