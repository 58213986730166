import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initFormVals } from "redux/state/initialValues";
import { Field, reduxForm } from "redux-form";
import { createLoanListing, getCollectionFromAddress } from "utils/api";
import { renderFormV2 } from "utils/form";
import { formatDateForPicker, ipfsConvert, roundTime } from "utils/formats";
import { sleep } from "utils/time";

import LoadingButton from "components/button/LoadingButton";

const formName = "loan-modal";

const validate = (values /*, dispatch */) => {
  const errors = {};
  if (values) {
    if (values.start_date >= values.end_date) {
      errors.start_date = "Start date cannot be after end date";
    }
  }
  return errors;
};

const LoanModal = ({ item: nft, kiosk, onHide, handleSubmit, buyLoading }) => {
  if (!nft) {
    return null;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form[formName]);
  const [formLoading, setFormLoading] = useState(false);
  const [collection, setCollection] = useState(false);
  const supported = collection?.features?.lending || collection.kiosk_enabled;

  useEffect(() => {
    if (nft) {
      fetchCollection();
    }
  }, [nft]);

  const fetchCollection = async () => {
    try {
      let collection = await getCollectionFromAddress(nft.type);
      collection = collection.data?.collection;
      setCollection(collection);
    } catch {}
  };

  // TODO: Add gas fee

  useEffect(() => {
    dispatch(
      initFormVals(formName, {
        min_duration: 1,
        start_date: formatDateForPicker(roundTime(new Date())),
      })
    );
  }, []);

  useEffect(() => {
    if (form?.values) {
      let { start_date, end_date, max_duration } = form.values;
      if (start_date && end_date) {
        start_date = new Date(start_date);
        end_date = new Date(end_date);
        let hours = Math.abs(end_date - start_date) / 3600000;
        if (!max_duration || hours < max_duration) {
          dispatch(
            initFormVals(formName, { ...form.values, ...{ max_duration: hours } })
          );
        }
      }
    }
  }, [form?.values]);

  const submit = async (values) => {
    setFormLoading(true);
    try {
      let res = false;
      // res = await lendNFT(nft, collection, values);
      // if (res?.status === "success") {
      //   await sleep();
      //   const created = await createLoanListing(res.effects.transactionDigest);
      //   if (created) {
      //     navigate(`/rental-details/${created.data.listings[0]._id}`);
      //   }
      // }
    } catch (e) {
      setFormLoading(false);
      console.log(e);
    }
  };

  // if (!item) {
  //   return false;
  // }

  return (
    <Modal show={nft} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <form onSubmit={handleSubmit(submit)}>
        <div className="modal-body space-y-20 pd-40">
          <h3>Loan this NFT?</h3>
          <h3 className="text-center">{nft?.name}</h3>
          <img
            src={
              ipfsConvert(nft?.display?.data?.thumb_url) ||
              ipfsConvert(nft?.display?.data?.image_url)
            }
          />
          <div className="field-container">
            <Field
              type="number"
              name="price"
              title="Price Per Day"
              // props={{
              //   min: "0.001",
              //   step: "any",
              // }}
              required
              component={renderFormV2}
            />
            <div className="row">
              <div className="col-md-6">
                <Field
                  type="datetime-local"
                  name="start_date"
                  title="Starting date"
                  props={{ min: "2022-01-01 00:00", step: 3600 }}
                  parse={(val) => formatDateForPicker(roundTime(val, "hours"))}
                  required
                  component={renderFormV2}
                />
              </div>
              <div className="col-md-6">
                <Field
                  type="datetime-local"
                  name="end_date"
                  title="End date"
                  props={{ min: "2022-01-01 00:00", step: 3600 }}
                  parse={(val) => formatDateForPicker(roundTime(val, "hours"))}
                  required
                  component={renderFormV2}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  type="number"
                  name="min_duration"
                  title="Minimum Rent Duration (Hours)"
                  props={{
                    min: 1,
                    step: "1",
                  }}
                  required
                  component={renderFormV2}
                />
              </div>
              <div className="col-md-6">
                <Field
                  type="number"
                  name="max_duration"
                  title="Maximum Rent Duration (Hours)"
                  props={{
                    min: 1,
                    step: "1",
                  }}
                  required
                  component={renderFormV2}
                />
              </div>
            </div>
          </div>

          <LoadingButton
            loading={buyLoading}
            disabled={formLoading || buyLoading || !supported}
            onClick={() => handleSubmit(nft)}
            className="fullWidth"
          >
            {!supported ? "Collection does not support renting" : "Create Rental Listing"}
          </LoadingButton>
        </div>
      </form>
    </Modal>
  );
};

export default connect((state) => ({
  initialValues: state.initialValues[formName], // pull initial values from account reducer
}))(reduxForm({ form: formName, enableReinitialize: true, validate })(LoanModal));
