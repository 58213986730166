import { IS_PROD, IS_TESTNET } from "utils/environments";

const mainnetDomains = {
  calypso: "https://calypso.keepsake.gg",
  nebula: "https://nebula.keepsake.gg",
  sui: "https://sui.keepsake.gg",
};

const testnetDomains = {
  calypso: "https://skale.keepsake.gg",
  nebula: "https://nskale.keepsake.gg",
};

const devDomains = {
  calypso: `localhost:${process.env.REACT_APP_PORT}`,
  nebula: `localhost:${process.env.REACT_APP_PORT}`,
  sui: "https://sui.keepsake.gg",
};

let domains = devDomains;

if (IS_PROD) {
  domains = mainnetDomains;
}
if (IS_TESTNET) {
  domains = testnetDomains;
}

export default domains;
