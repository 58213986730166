import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const NFTSkeleton = () => {
  return (
    <div className="sc-card-product">
      <div className="card-media">
        <Skeleton height="100%" containerClassName="image-skeleton" />
      </div>
      <div className="card-details">
        <div className="meta-info">
          <div className="text-container">
            <p>
              <Skeleton containerClassName="fullWidth" />
              <Skeleton containerClassName="fullWidth" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTSkeleton;
