import styled from "styled-components";

export const RefreshTimerText = styled.span`
  color: var(--primary-color9);
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

export const PrimaryInfoContainer = styled.div`
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius-md);
  border: 2px solid #353840;
  background: transparent !important;
  text-align: start;
  margin-bottom: 1rem;
`;

export const SmallButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  white-space: nowrap;
`;
export const Views = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color2);
  background-color: transparent;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;
`;

export const AuctionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;
`;

export const InfoHeading = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: var(--primary-color4);
`;

export const ContentRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 1rem;
  gap: 1rem;

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const ItemName = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 575px) {
    font-size: 36px;
  }
`;

export const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 2.5rem;
`;

export const DetailsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const DetailsName = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const DetailsValue = styled.div`
  font-size: 14px;
  color: var(--primary-color9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
