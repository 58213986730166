import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { useOutsideClick } from "utils/hooks";

const DropdownButton = styled.button`
  background-color: var(--primary-color3);
  min-height: 46px;
  min-width: 46px;
  padding: 0;
  border-radius: var(--border-radius-md);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  text-transform: capitalize;
  z-index: 1;
  cursor: pointer;
  :hover,
  :focus {
    border: none;
    background: #fff;
    color: #000;
  }
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  @media ((max-width: 1366px) and (min-width: 768px)) or (max-width: 500px) {
    min-height: 44px;
    border-radius: var(--border-radius-md);
    font-size: 16px;
    padding: 0 1rem;
  }
`;

const DropdownPopup = styled.div`
  position: absolute;
  z-index: 99;
  right: 1rem;
  padding: 1rem;
  bottom: 70px;
  overflow: hidden;
  border-radius: var(--border-radius-md);
  background-color: var(--primary-color);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
  box-shadow: rgba(0, 0, 0, 0.48) 0px 8px 32px;
  border: solid 1px var(--bg-section);
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
  font-size: 16px;
  width: 230px;
  margin-top: 1rem;

  a {
    color: var(--primary-color2);
    border-radius: var(--border-radius-md);
    padding: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    :hover {
      color: var(--primary-color2);
      background-color: var(--hover-color);
    }
  }
`;

const EllipsisDropdown = ({ list, item, ...props }) => {
  if (!list || list.length === 0) return null;

  const [menu, toggleMenu] = useState(false);
  const handleHideMenu = () => {
    toggleMenu(false);
  };

  const ref = useOutsideClick(handleHideMenu);

  const handleDropdownClick = (e) => {
    if (menu) e.currentTarget.blur();
    toggleMenu(!menu);
  };

  return (
    <DropdownButton onClick={handleDropdownClick} ref={ref} {...props}>
      <div>
        <i className="far fa-ellipsis-h" />
      </div>
      {menu && (
        <DropdownPopup>
          {list.map((option, i) => {
            const { label, onClick, icon } = option;
            return (
              <Dropdown.Item
                key={i}
                eventKey={i}
                className="sorting-flex"
                onClick={() => onClick(item)}
                onBlur={() => handleHideMenu()}
              >
                {icon} <span>{label}</span>
              </Dropdown.Item>
            );
          })}
        </DropdownPopup>
      )}
    </DropdownButton>
  );
};

export default EllipsisDropdown;
