import React, { useContext, useState } from "react";

const BannerContext = React.createContext();

export function useBanner() {
  return useContext(BannerContext);
}

export function BannerProvider({ children }) {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <BannerContext.Provider value={{ showBanner, setShowBanner }}>
      {children}
    </BannerContext.Provider>
  );
}
