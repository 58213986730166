import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useWagmiAccount } from "@skalenetwork/metaport";
import { getMyCollection, locateNFT, searchNFTs } from "utils/api";
import { sleep } from "utils/time";
import { getERC721Supply } from "utils/web3/evm";

import LoadingButton from "components/button/LoadingButton";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import PageHeader from "components/layouts/PageHeader";
import LoadingSpinner from "components/utils/LoadingSpinner";

const SyncCollection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { address, status } = useWagmiAccount();

  const [supply, setSupply] = useState("???");
  const [collection, setCollection] = useState(null);
  const [now, setNow] = useState(0);
  const [loadingCollection, setLoadingCollection] = useState(false);

  const { id } = params;
  if (!id) navigate("/explore");

  const fetchCollection = async () => {
    setLoadingCollection(true);
    const res = await getMyCollection(id);
    const collectionData = res.data;
    setCollection(collectionData);

    let totalSupply = 0;
    try {
      totalSupply = Number(await getERC721Supply(res.data.address));
      setSupply(totalSupply);
    } catch {}
    let lastNumber = 0;

    // loop through pages here
    let page = 1;
    let lastPage = 0;
    setNow(1);
    while (lastPage === 0 || page <= lastPage) {
      let nfts = await searchNFTs({
        nft_collection: id,
        sortParams: { token_id: 1 },
        page,
      });
      lastPage = nfts.data.pages;

      const missing = [];
      nfts.data.results.forEach((nft) => {
        let { token_id } = nft;
        while (token_id > lastNumber) {
          missing.push(lastNumber);
          lastNumber += 1;
        }
        lastNumber += 1;
      });
      for (let index = 0; index < missing.length; index++) {
        const elementIndex = missing[index];
        try {
          await locateNFT(collectionData.address, elementIndex);
          await sleep(300);
        } catch {}
      }
      setNow(lastNumber);
      page += 1;
    }

    // handling NFTs being burned, and indexes exceeding totalSupply
    let keepGoing = 25;
    while (keepGoing > 0) {
      try {
        let nft = await locateNFT(collectionData.address, lastNumber);
        if (nft) {
          keepGoing = 25;
        } else {
          keepGoing--;
        }
        await sleep(300);
      } catch {
        keepGoing--;
      }
      lastNumber += 1;
      setNow(lastNumber);
    }
    setLoadingCollection(false);
  };

  useEffect(() => {
    if (user.role_id == 3) {
      fetchCollection();
    } else {
      navigate("/");
    }
  }, []);

  if (!collection) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg="6" xs="12">
                <h3 className="center mb-4">
                  {loadingCollection ? "Syncing" : "Synced"} NFTs records for{" "}
                  {collection.name}
                </h3>
                {now > 0 && typeof supply == "number" && (
                  <ProgressBar
                    className="md"
                    now={(now / supply) * 100}
                    label={`${(now / supply) * 100}%`}
                  />
                )}
                <Link to={`/collection-details/${id}`} className="nav-link">
                  <LoadingButton
                    className="m-auto d-block"
                    disabled={loadingCollection}
                    loading={loadingCollection}
                  >
                    View Collection details
                  </LoadingButton>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SyncCollection;
